import React, { useEffect, useRef, useState } from "react";
import { Card, CardGroup, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
  booking_confirmation,
  customer_support,
  secure_payments,
} from "assets";
import MovieGridCard from "website/components/Home/MovieGridCard";
import HeroSection from "website/components/Home/HeroSection";
import { RiMovie2Line } from "react-icons/ri";
import * as authActions from "Redux/Actions/authActions";
import { useLocation, useNavigate } from "react-router";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import TestImageComponent from "components/TestImageComponent";

const Home = ({ showForm, setShowForm }) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [moviesData, setMoviesData] = useState([]);
  const [moviesDataLoading, setMoviesDataLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [currentFilm, setCurrentFilm] = useState(null)

  const location = useLocation();
  const movies = useRef(null);

  const filters = [
    { id: 1, filter: "All Movies" },
    { id: 2, filter: "Coming Soon" },
    { id: 3, filter: "Latest" },
  ]

  const clientId = process.env.REACT_APP_CLIENT_ID;

  const fetchAllMovies = async (e) => {
    setMoviesDataLoading(true);
    const response = await authActions.fetchAllPrograms(clientId);
    if (response.status === 200) {
      setMoviesData(response.data)
    } else {
      setMoviesData([]);
    }
    setMoviesDataLoading(false);
  };

  const fetchComingSoonMovies = async () => {
    setMoviesDataLoading(true);
    const response = await authActions.fetchComingSoonMovies(clientId);
    if (response.status === 200) {
      setMoviesData(response.data)
    } else {
      setMoviesData([]);
    }
    setMoviesDataLoading(false);
  }

  const fetchLatestMovies = async () => {
    setMoviesDataLoading(true);
    const response = await authActions.fetchLatestMovies(clientId);
    if (response.status === 200) {
      setMoviesData(response.data)
    } else {
      setMoviesData([]);
    }
    setMoviesDataLoading(false);
  }

  useEffect(() => {
    if (clientId) {
      if (currentFilter === 1) {
        fetchAllMovies()
      } else if (currentFilter === 2) {
        fetchComingSoonMovies()
      } else if (currentFilter === 3) {
        fetchLatestMovies()
      }
    }
  }, [clientId, currentFilter])

  useEffect(() => {
    if (location.hash === "#buy-tickets") {
      movies.current.scrollIntoView();
    }
  }, [location])

  return (
    <>
      {/* <TestImageComponent /> */}
      <HeroSection showForm={showForm} setShowForm={setShowForm} />
      <div className="bg-slate">
        <div className="wrapper">
          <div className="py-5">
            <div style={{ borderBottom: "1px solid white", paddingBottom: 10 }} ref={movies} className={`${large ? "d-flex justify-content-between align-items-center" : ""} mb-4 h-100`}>
              <div className={`d-flex align-items-center text-lightgrey ${large ? "justify-content-center" : "mb-4"}`}>
                <RiMovie2Line size={30} className="me-2" style={{
                  color: "#fece50"
                  // color: "white"
                }} />
                <h2 className="text-capitalize m-0">
                  Spotlight this Month
                </h2>
              </div>
              <div className="d-flex align-items-center h-100">
                {filters.map((filter) => {
                  return (
                    <div className="cursor-pointer position-relative h-100" onClick={() => setCurrentFilter(filter.id)}>
                      <div className={`text-lightgrey h-100 ${small ? "mx-3" : "mx-2"}`} style={{ fontSize: 14 }}>{filter.filter}</div>
                      {currentFilter === filter.id &&
                        <div
                          className={`bottom-border-custom`}
                        />}
                    </div>
                  )
                })}
              </div>
            </div>
            <CardGroup>
              <Row className="w-100 m-0" xs={2} md={3} lg={3}>
                {
                  moviesDataLoading ?
                    <div
                      className="d-flex justify-content-center align-items-center text-white w-100"
                      style={{ minHeight: "70vh" }}
                    >
                      <Spinner size="sm" />
                    </div> : <>
                      {moviesData.length > 0 ?
                        moviesData?.map((item, i) => (
                          <Col md={4} lg={4} className="d-flex flex-column mb-2">
                            <MovieGridCard
                              id={item.id}
                              title={item?.title}
                              image={item?.poster_image}
                              genres={item?.program_data?.genres}
                              rating={item?.rating}
                              showRating={item?.show_rating}
                              certification={item?.certification_data?.title}
                              showCertification={item?.show_certification}
                              trailerLink={item?.trailer_link}
                              setOpenModal={setOpenModal}
                              setCurrentFilm={setCurrentFilm}
                            />
                          </Col>
                        ))
                        :
                        <div className="d-flex justify-content-center align-items-center text-white w-100" style={{ minHeight: "50vh" }}>
                          There are no movies available in this category!
                        </div>
                      }
                    </>
                }
              </Row>
            </CardGroup>
          </div>
        </div>
      </div>
      {/* <div className="bg-dark">
        <div className="d-flex justify-content-center wrapper">
          <p className="text-white text-center my-5" style={{ width: "85%" }}>
            Now pay using your ONLINE ENABLED Debit/ATM/Prepaid Credit & Credit
            Card Powered by ONLY Visa Electron/Visa & Master Cards.
          </p>
        </div>
      </div> */}
      <FeatureCarousel />
      <Modal
        contentClassName="bg-transparent border-0"
        show={openModal}
        onHide={() => setOpenModal(false)}
        animation={true}
        centered
        size="lg"
      >
        <Modal.Header className="border-0" closeButton closeVariant="white"></Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center p-0">
          {currentFilm ?
            <iframe
              width="90%"
              height="450"
              src={currentFilm}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            :
            <div className="text-white mt-4 mb-5 pt-4 pb-5 d-flex justify-content-center">
              Trailer not available!
            </div>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;

const FeatureCarousel = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const featuresData = [
    { image: booking_confirmation, title: 'Booking Confirmation' },
    { image: customer_support, title: 'Customer Support' },
    { image: secure_payments, title: 'Secure Payments' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex(currentIndex === featuresData.length - 1 ? 0 : currentIndex + 1);
  };

  const prevSlide = () => {
    setCurrentIndex(currentIndex === 0 ? featuresData.length - 1 : currentIndex - 1);
  };

  if (medium || large) {
    return (
      <div className="bg-slate">
        <div className="wrapper">
          <CardGroup className="justify-content-center py-5">
            <Row className="justify-content-center" xs={1} sm={3} md={3}>
              {/* {featuresData?.map((item) => (
                <Col lg={3} className="">
                  <FeatureCard image={item?.image} title={item?.title} />
                </Col>
              ))} */}
              <Col
                sm={4}
                md={4}
                className="d-flex justify-content-center p-xl-4"
              >
                <FeatureCard
                  image={booking_confirmation}
                  title="Booking Confirmation"
                />
              </Col>
              <Col
                sm={4}
                md={4}
                className="d-flex justify-content-center p-xl-4"
              >
                <FeatureCard
                  image={customer_support}
                  title="Customer Support"
                />
              </Col>
              <Col
                sm={4}
                md={4}
                className="d-flex justify-content-center p-xl-4"
              >
                <FeatureCard image={secure_payments} title="Secure Payments" />
              </Col>
            </Row>
          </CardGroup>
        </div>
      </div>
    )
  } else {
    return (
      <div className="bg-slate">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={8} lg={6}>
              <div className="custom-carousel-container">
                <div className="custom-carousel-wrapper" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                  {featuresData.map((item, index) => (
                    <div key={index} className="custom-carousel-item">
                      <FeatureCard image={item.image} title={item.title} />
                    </div>
                  ))}
                </div>
                <button className="custom-carousel-control prev" onClick={prevSlide}>
                  <FaChevronLeft />
                </button>
                <button className="custom-carousel-control next" onClick={nextSlide}>
                  <FaChevronRight />
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

const FeatureCard = ({ title, image, className, style }) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <Card
      className={`bg-dark d-flex flex-column justify-content-center align-items-center mb-4 ${className}`}
      style={{
        width: xlarge ? 260 : large ? 220 : medium ? 200 : small ? 160 : 220,
        height: xlarge ? 260 : large ? 220 : medium ? 200 : small ? 160 : 220,
        borderRadius: 16,
        boxShadow: "2px 2px 5px #f5382c",
        ...style,
      }}
    >
      <div className="m-4 my-4 text-center">
        <img
          src={image}
          alt={title}
          className="img-fluid mb-2 mb-md-3 mb-lg-4"
          style={{ width: "60%" }}
        />
        <h5 className="text-white">{title}</h5>
      </div>
    </Card>
  );
};
