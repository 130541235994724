import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { TiArrowUpThick } from "react-icons/ti";
import { FaTiktok } from "react-icons/fa6";
import { mcine } from "assets";
import { Link } from "react-router-dom";
import { SlArrowUp } from "react-icons/sl";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import log from "loglevel";

// const ScrollComponent = ({}) => {
//   const [showComponent, setShowComponent] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollY = window.scrollY;
//       const scrollThreshold = 200 * window.innerHeight;

//       if (scrollY > scrollThreshold) {
//         setShowComponent(true);
//       } else {
//         setShowComponent(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
// };

const social_media_icons = {
  facebook: <FaFacebook size={24} className="text-lightgrey" />,
  instagram: <RiInstagramFill size={24} className="text-lightgrey" /> ,
  twitter: <FaXTwitter size={24} className="text-lightgrey" />,
  tiktok: <FaTiktok size={24} className="text-lightgrey" />,
}

const SiteFooter = () => {
  const [showComponent, setShowComponent] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const today = new Date();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  useEffect(()=>{
    const getSocialLinks = async () => {
      try {
        const response = await axios.post('/user/rest/settings/social_links/', {clientId: process.env.REACT_APP_CLIENT_ID});
        log.log(response.data);
        if (Array.isArray(response.data)) {
          setSocialLinks(response.data);
        }
      } catch (err) {
        log.error(err);
      }
    }
    getSocialLinks();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 1 * window.innerHeight;

      if (scrollY > scrollThreshold) {
        setShowComponent(true);
      } else {
        setShowComponent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <div className="footer-background bg-extradark pt-5">
    <footer className="footer-background pt-5">
      <div className="container" style={{ maxWidth: 1140 }}>
        <div className="d-flex justify-content-center">
          <Row className={`${large ? "" : "text-center"} pt-3 pt-md-5 pb-3 wrapper px-0 px-md-0 px-lg-0 w-100`}>
            <Col md={10} lg={3} className="p-0">
              <div className="mb-5 mb-lg-4">
                <img
                  src={mcine}
                  alt="MCine"
                  className="img-fluid footer-logo"
                  style={{ width: 80 }}
                />
                <h6 className="my-4 mb-0 text-white">MCine Trianon</h6>
                <p className="footer-text mb-3 mb-4 text-lightgrey d-flex flex-column">
                  <span>La City - Trianon Shopping Park,</span>
                  <span>Quatre Bornes</span>
                </p>
                <p className="footer-text my-2 mb-2 text-white">
                  <strong>
                    Call us: (+230) 4641900
                  </strong>
                </p>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <p className="footer-text my-3 mb-4 text-lightgrey d-flex flex-column">
                    <strong>
                      More..
                    </strong>
                  </p>
                </Link>
              </div>
            </Col>
            <Col md={10} lg={3} className="p-0">
              <div className="mb-5">
                <h6 className="text-bold mb-4 text-white">
                  Legal
                </h6>
                <Link to="/terms-of-use" style={{ textDecoration: "none" }}>
                  <p className="footer-text mb-3 text-lightgrey">Terms of Use</p>
                </Link>
                <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
                  <p className="footer-text mb-3 text-lightgrey">
                    Privacy Policy
                  </p>
                </Link>
                <Link to="/security-policy" style={{ textDecoration: "none" }}>
                  <p className="footer-text mb-3 text-lightgrey">Security</p>
                </Link>
                <Link to="booking-policy" style={{ textDecoration: "none" }}>
                  <p className="footer-text mb-3 text-lightgrey">Booking Policy</p>
                </Link>
              </div>
            </Col>
            {/* <Col md={10} lg={3} className="p-0">
            <div className="mb-5">
              <p className="footer text text-uppercase text-bold mb-4 text-white">
                Links
              </p>
              <Link to="/movies" style={{ textDecoration: "none" }}>
                <p className="footer-text mb-3 text-lightgrey">Movies</p>
              </Link>
              <Link to="/about" style={{ textDecoration: "none" }}>
                <p className="footer-text mb-3 text-lightgrey">About</p>
              </Link>
              <Link to="/our-cinemas" style={{ textDecoration: "none" }}>
                <p className="footer-text mb-3 text-lightgrey">Our Cinemas</p>
              </Link>
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <p className="footer-text mb-3 text-lightgrey">Contact</p>
              </Link>
            </div>
          </Col> */}
            <Col md={10} lg={3} className="p-0">
              <div className="mb-5">
                <h6 className="text-bold mb-4 text-white">
                  Account
                </h6>
                <Link to="/account/profile" style={{ textDecoration: "none" }}>
                  <p className="footer-text mb-3 text-lightgrey">My Account</p>
                </Link>

                {
                  Array.isArray(socialLinks) && socialLinks?.length > 0 ? <>
                    <h6 className="text-bold mb-4 text-white">
                      Social Links
                    </h6>
                    <div className="">
                    {socialLinks.map(({ name, url }, index) => {
                      const isLastIndex = index === socialLinks.length - 1;
                      return (
                        <a
                          className={isLastIndex ? "" : "me-3"}
                          key={name}
                          href={url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <span>{social_media_icons[name]}</span>
                        </a>
                      );
                    })}
                    </div>
                  </> : <></>
                }
              </div>
            </Col>
            {/* <Col md={10} lg={2} className="p-0">
            <div className="mb-4">
              <p className="footer text text-uppercase text-bold mb-4 text-white">
                Follow Us On
              </p>
              <div className="">
                {social_media_icons.map((icon) => {
                  return (
                    <span>{icon.icon}</span>
                  )
                })}
              </div>
            </div>
          </Col> */}
            <Col md={10} lg={3} className="p-0">
              <div className="mb-4">
                <h6 className="text-bold mb-4 text-white">
                  Payment Details
                </h6>
                {/* <p className="footer-text mb-3 text-lightgrey">
                  Now pay using your ONLINE ENABLED Debit/ATM/Prepaid Credit & Credit Card Powered by ONLY Visa Electron/Visa & Master Cards.
                </p> */}
              </div>
              <Link to="/myt-money-help" style={{ textDecoration: "none" }}>
                <p className="footer-text mb-3 text-lightgrey">my.t money Help</p>
              </Link>
            </Col>
          </Row>
        </div>
        <hr style={{ border: '1px solid #ddd', margin: 0 }} />
        <div>
          <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center wrapper px-0"
          // style={{ borderTop: "1px solid rgba(255, 255, 255, 0.23)" }}
          >
            <p className="text-center text-md-start footer-text text-white my-2 my-lg-4">
              All Rights Reserved MCine {moment(today).format('YYYY')} | Designed by Prosoft Logic Systems LTD
            </p>
            <div
              className="d-flex footer-text text-white"
              style={{
                textDecoration: "none",
                scrollBehavior: "smooth",
                cursor: "pointer"
              }}
              onClick={() => window.scrollTo(0, 0)}
            >
              {/* <SlArrowUp size={15} /> */}
              <strong>
                Back to Top <TiArrowUpThick />
              </strong>
            </div>
          </div>
          <div
            className={`wrapper ${showComponent
              ? "d-flex flex-column flex-lg-row justify-content-end align-items-end"
              : "d-none"
              }`}
          >
            <div
              className="d-flex footer-text text-white my-2 my-lg-4"
              style={{
                textDecoration: "none",
                scrollBehavior: "smooth",
                position: window.scrollY > 200 ? "fixed" : "static",
                bottom: window.scrollY > 200 ? "60px" : "auto",
                right: window.scrollY > 200 ? "50px" : "auto",
                padding: "12px",
                // backgroundColor: "hsla(0, 0%, 0%, 0.9)",
                backgroundColor: "#1B1E29",
                borderRadius: "5px",
                // border: "1px solid rgb(180, 180, 180)",
                cursor: "pointer"
              }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <SlArrowUp size={15} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
