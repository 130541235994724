import React, { useState } from 'react'
import LoginWithPassword from './LoginWithPassword';
import LoginWithOtp from './LoginWithOtp';
import FacebookGoogleLogin from './FacebookGoogleLogin';
import ReactGA from 'react-ga4';

const LoginForm = ({ setShowLoginModal, checkoutMode, setAllow, setShowForm }) => {
    const [loginUsingOtp, setLoginUsingOtp] = useState(false);

    return (
        <>
            {!loginUsingOtp ?
                <LoginWithPassword setShowLoginModal={setShowLoginModal} checkoutMode={checkoutMode} setAllow={setAllow} />
                :
                <LoginWithOtp setShowLoginModal={setShowLoginModal} checkoutMode={checkoutMode} setAllow={setAllow} />
            }
            <div className="d-flex justify-content-between">
                <div
                    className="mt-2 cursor-pointer"
                    onClick={() => {
                        if (loginUsingOtp) {
                            setLoginUsingOtp(false);
                            ReactGA.event({
                                category: "login_flow",
                                action: "go_login_to_otpLogin",
                                label: "Going from Login to OTP Login", // optional
                            });
                        } else {
                            setLoginUsingOtp(true);
                            ReactGA.event({
                                category: "login_flow",
                                action: "go_otpLogin_to_login",
                                label: "Going from OTP Login to Login", // optional
                            });
                        }
                    }}
                >
                    <small className="text-lightgrey text-white">
                        {loginUsingOtp ? "Login using Password!" : "Login using OTP!"}
                    </small>
                </div>
                <div
                    className="mt-2 cursor-pointer"
                    onClick={() => {
                        // setShowLoginModal(false);
                        // navigate("/forgot-password")
                        setShowForm("forgotpassword");
                    }}
                >
                    <small className="text-lightgrey text-white">
                        Forgot Password?
                    </small>
                </div>
            </div>
            {(!process.env.REACT_APP_GOOGLE_CLIENT_ID && !process.env.REACT_APP_FACEBOOK_APP_ID) ?
                <></> :
                <FacebookGoogleLogin setShowLoginModal={setShowLoginModal} />
            }
            <div className="d-flex justify-content-center align-items-center pt-3">
                <p className="text-lightgrey me-2">Don't have an account?</p>
                <div
                    onClick={() => {setShowForm("signup");
                        
                        ReactGA.event({
                            category: "login_flow",
                            action: "go_login_to_signup",
                            label: "Going from Login to Sign Up", // optional
                        });
                    }}
                    className="cursor-pointer text-bold"
                >
                    <p className="text-white">Sign Up</p>
                </div>
            </div>
        </>
    )
}

export default LoginForm