import React from "react";
import { useMediaQuery } from "react-responsive";

const PageTitle = ({ title, className, titleClass, style }) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <div>
      <div
        className={`d-flex justify-content-center align-items-center ${className} mt-5`}
      // style={{ height: medium ? 180 : small ? 120 : 100, ...style }}
      >
        <h1 className={`text-white ${titleClass}`}>{title}</h1>
      </div>
    </div>
  );
};

export default PageTitle;
