/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Col, Row, Card, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { our_cinemas } from "assets";
import { Helmet } from "react-helmet";

const OurCinemas = () => {

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const our_cinemas_info =
    <>
      <p>
        MCine, located around the Mauritian island, opened its doors in
        2014 equipped with technologies and innovations to dazzle
        Mauritian movie lovers. Bringing the best Hollywood and
        Bollywood blockbusters to cinema fans, MCine indeed assures you
        an unbeatable movie experience.
      </p>
      <p>
        All halls have wide screens which assure HD images and offer an
        optimal projection of 3D movies.
      </p>
      <p>
        MCine is well equipped with the Belge Barco Digital Projector
        DLP (Digital Light Processing) and Dolby Digital 7.1 for high
        quality image and sound. Another plus point of MCine is that 3D
        glasses are freely lent to the movie-goers.
      </p>
      <p>
        MCine is indeed a real delight for cinema fans and the halls are
        often buzzing with movie-lovers from different spheres of life.
      </p>
      <p>
        MCine is the brand new cinema complex in Mauritius with
        technologies to dazzle you. Composed of 12 cinemas, M Cine
        offers the best blockbusters in Hollywood and Bollywood in
        Mauritius. M Cine ensures an incomparable cinematic experience
        in Mauritius. No need to envy the splendor of Malaysian cinemas
        or the magnificence of South African complexes, Mauritius has
        its "MCINE"
      </p>
    </>

  return (
    <>
      <Helmet>
        <title>MCine | Our Cinemas</title>
      </Helmet>
      <div>
        <div className="bg-slate pb-5">
          <div className="wrapper">
            <ul className="breadcrumb subtitle mx-1 pb-4">
              <li><a href="/">Home</a></li>
              <li>Our Cinemas</li>
            </ul>
            <Card
              className="p-4"
              // style={{
              //   backgroundColor: "#13151f",
              //   boxShadow: "0px 0px 20px 5px #000000",
              //   borderRadius: "0px"
              // }}
              style={{
                backgroundColor: "#1c1e29",
                borderRadius: "0px"
              }}
            >
              {large ?
                <Row>
                  <Col sm={5}>
                    <Image className="w-100" src={our_cinemas} alt="MCine theatre" />
                  </Col>
                  <Col sm={7}>
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="me-2"
                        style={{
                          width: 90,
                          height: 2,
                          backgroundColor: "red",
                        }}>
                      </div>
                      <h5 className="text-center text-primary m-0">
                        Everything Comes to Life
                      </h5>
                    </div>
                    <div style={{ color: "#9a9a9a" }}>
                      {our_cinemas_info}
                    </div>
                  </Col>
                </Row>
                :
                <>
                  <div className="d-flex align-items-center mb-3">
                    {small &&
                      <div
                        className="me-2"
                        style={{
                          width: 90,
                          height: 2,
                          backgroundColor: "red",
                        }} />
                    }
                    <p className="text-primary m-0">
                      Everything Comes to Life
                    </p>
                  </div>
                  <div style={{ color: "#9a9a9a" }}>
                    {our_cinemas_info}
                  </div></>
              }
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  parentContainer: {
    padding: "40px 100px",
  },
  mobileParentContainer: {
    padding: "20px 10px",
  },
}


export default OurCinemas;
