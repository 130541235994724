import React from "react";

const WebPageNotFound = () => {
  return (
    <div className="d-flex bg-dark" style={{ minHeight: "50vh" }}>
      <div className="wrapper d-flex flex-column justify-content-center align-items-center flex-grow-1">
        <h1 className="text-white mb-3">404</h1>
        <h4 className="text-white mb-3">Page Not Found</h4>
        <a href="/" className="text-blue">
          Back to home
        </a>
      </div>
    </div>
  );
};

export default WebPageNotFound;
