import React, { useState, useRef, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import * as authActions from "Redux/Actions/authActions";
import { useSelector } from "react-redux";
import FoodOrderCard from "./FoodOrderCard";
import { useMediaQuery } from "react-responsive";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from "moment";
import NothingHere from "./NothingHere";


const FoodOrders = () => {
  const user = useSelector((state) => state.auth.user);
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const [foodOrders, setFoodOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOrders = async () => {
    const response = await authActions.getFoodOrder(user.id)
    if (response) {
      setFoodOrders(response.results)
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user.id) {
      fetchOrders()
    }
  }, []);

  return (
    <div className="bg-slate pb-5">
      <div>
        <Card
          className={`${medium ? "py-4 px-3" : "px-1"}`}
          style={{
            backgroundColor: "#1c1e29",
            borderRadius: "0px",
          }}
        >
          {medium && <h4 className="text-white ms-2">Food Orders</h4>}
          {loading ?
            <div className="d-flex justify-content-center align-items-center text-white" style={{ minHeight: "30vh" }}>
              <Spinner />
            </div>
            :
            <div>
              {foodOrders.length > 0 ?
                <div id="orders-tab-user-container">
                  <Tabs
                    defaultActiveKey="uncollected"
                    id="orders-tab-user"
                    className="my-3 ms-2"
                    variant="pills"
                  >
                    <Tab eventKey="all" title="All">
                      {foodOrders?.length === 0 && <NothingHere />}
                      {foodOrders.map?.((foodOrder) => {
                        return (
                          <FoodOrderCard key={foodOrder.id} foodOrder={foodOrder} />
                        )
                      })}
                    </Tab>
                    <Tab eventKey="uncollected" title="Uncollected">
                      {foodOrders?.filter?.((a) => a?.status === "BOOKED").length === 0 && <NothingHere />}
                      {
                        foodOrders
                          ?.filter?.((a) => a?.status === "BOOKED")
                          .map((foodOrder) => <FoodOrderCard key={foodOrder.id} foodOrder={foodOrder} />)
                      }
                    </Tab>
                    <Tab eventKey="collected" title="Collected">
                      {foodOrders?.filter?.((a) => a?.status === "COLLECTED").length === 0 && <NothingHere />}
                      {
                        foodOrders
                          ?.filter?.((a) => a?.status === "COLLECTED")
                          .map((foodOrder) => <FoodOrderCard key={foodOrder.id} foodOrder={foodOrder} />)
                      }
                    </Tab>
                    <Tab eventKey="pending" title="Pending">
                      {foodOrders?.filter?.(a => (a?.status === "PENDING" || a?.status === "CANCELLED")).length === 0 && <NothingHere />}
                      {
                        foodOrders
                          ?.filter?.(a => (a?.status === "PENDING" || a?.status === "CANCELLED"))
                          ?.map((foodOrder) => <FoodOrderCard key={foodOrder.id} foodOrder={foodOrder} />)
                      }
                    </Tab>
                    <Tab eventKey="failed" title="Failed">
                      {foodOrders?.filter?.(a => a?.status === "FAILED").length === 0 && <NothingHere />}
                      {
                        foodOrders
                          ?.filter?.(a => a?.status === "FAILED")
                          ?.map((foodOrder) => <FoodOrderCard key={foodOrder.id} foodOrder={foodOrder} />)
                      }
                    </Tab>
                  </Tabs>
                </div>
                :
                <div className="d-flex justify-content-center align-items-center text-white" style={{ minHeight: "30vh" }}>
                  No Food Orders Found!
                </div>
              }
            </div>
          }
        </Card>
      </div>
    </div>
  );
};

const styles = {
  label: {
    width: 150,
  },
};

export default FoodOrders;
