import { Image, Button } from "react-bootstrap";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { hasGrantedAnyScopeGoogle } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { facebookButtonIcon, googleButtonIcon } from "assets";
import axios from "axios";
import { useDispatch } from "react-redux";
import * as actions from "Redux/AuthReducer/authSlices";
import { useState } from "react";
import log from "loglevel";

const FacebookGoogleLogin = ({ setShowLoginModal, type = null }) => {
  const dispatch = useDispatch();
  const [textColor, setTextColor] = useState(false);
  const responseFacebook = async (response) => {
    // log.log(response);

    const { email, name } = response;
    try {
      const resp1 = await axios.post("/user/social_login/", {
        username: email,
        first_name: name,
        // sign_up_type: "FACEBOOK",
      });
      if (resp1.status === 200) {
        // log.log(resp1.data);
        dispatch(actions.saveLoginInfo(resp1.data));
        setShowLoginModal(false);
      }
    } catch (err) {
      // log.log({ err });
      // actions.notify(err?.response?.data?.msg, "error");
    }
    //   {
    //     "name": "Bhushan Patil",
    //     "email": "patilbhushanr3838@gmail.com",
    //     "picture": {
    //         "data": {
    //             "height": 50,
    //             "is_silhouette": false,
    //             "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=1387296245426476&height=50&width=50&ext=1683372217&hash=AeRQiEh7hCjrfREjKIY",
    //             "width": 50
    //         }
    //     },
    //     "id": "1387296245426476",
    //     "accessToken": "EAAHeBHWHlBMBABgjlT96KLxjgDcXFexeadi4pW2NpLuZBDFDL1D24oaJBCJIYsBHSaobf7N0fCVopTmS83DjfKmEZAWx4c0WD6vYy3kiylSCZCI7CmkuRRNuJjJet4gZBpEmvpZAYbJkH7PXDkXDbQ92hRxHC9uXIZCZBeKQIztDZCaZCQfxIPaW4PY8zsiAtZBNtZCosc72ZCSwcGLtenRulXZBM",
    //     "userID": "1387296245426476",
    //     "expiresIn": 5784,
    //     "signedRequest": "M8bAUqhpujLNDSxDo3Rn7KdS7npBbKuUqyl0vFZMl7M.eyJ1c2VyX2lkIjoiMTM4NzI5NjI0NTQyNjQ3NiIsImNvZGUiOiJBUUFPT016NTRqQjFGdl9fdGZiRTkwX1RZekR3QThvRkp3cGdDVmFYU09nSkZDeldON1ZmYU1vcWJGdG55ZXA3bmRiRW9KTFZCSFZtZXpPbUVsMVRhUEdTekt1ZnZjWm0wZUNHSzR5MDM5NEdIc3pPdHFJd29mZ3dsSEtSVlVQVkdqYmplX0VrNUtKMFVGVGlzVmw2bGNraUZLTmRwU3NMNEhIQ3F4U2FmUFVfdDFQLTA3MEt0LXRudmI5WXFFd3c5VThMR0xHc2xUYUhaYjhJR3NMQXZrQlRDZUN0RE5SSVZzY2Nxd3FNSm9xZVFieThjRnd6ZXJ1NndhWFJaWWR0RzRhb0lzam1FY3Q4Y3Njdnp1dXpSal9aNGNxOG9hVUI1TWR4VkgxcG5acThBaF85RnJrQ0hSa0Uyc2NlMjZYbDFhYlU1QlYyNnhwVWRjelZabUZ4SURzUCIsImFsZ29yaXRobSI6IkhNQUMtU0hBMjU2IiwiaXNzdWVkX2F0IjoxNjgwNzgwMjE2fQ",
    //     "graphDomain": "facebook",
    //     "data_access_expiration_time": 1688556216
    // }
  };


  /** Auth options passed to AppleID.auth.init() */
  const appleAuthOptions = {
    /** Client ID - eg: 'com.example.com' */
    clientId: "com.example.web",
    /** Requested scopes, seperated by spaces - eg: 'email name' */
    scope: "email name",
    /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
    redirectURI: "https://example.com",
    /** State string that is returned with the apple response */
    state: "state",
    /** Nonce */
    nonce: "nonce",
    /** Uses popup auth instead of redirection */
    // usePopup: ${authOptions.usePopup},
  }

  const onGoogleLoginSuccess = async (codeResponse) => {
    // log.log(codeResponse);
    const { access_token } = codeResponse;

    try {
      // Fetch user information from Google
      const response = await fetch("https://www.googleapis.com/oauth2/v1/userinfo/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const { email, name } = data;

        try {
          // Send the user information to your server for social login
          const resp1 = await fetch(`${process.env.REACT_APP_BASE_URL}/user/social_login/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: email,
              first_name: name,
              // sign_up_type: "GOOGLE",
            }),
          });

          if (resp1.ok) {
            const respData = await resp1.json();
            dispatch(actions.saveLoginInfo(respData));
            setShowLoginModal(false);
          }
        } catch (err) {
          // Handle error for the social login post request
          console.error("Social login error:", err);
          // actions.notify(err.message, "error");
        }
      } else {
        console.error("Failed to fetch user info:", response.statusText);
      }
    } catch (err) {
      // Handle error for the Google API request
      console.error("Google API request error:", err);
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
  });

  if (type === "mobile") {
    return (
      <>
        <div className="d-flex align-items-center my-4" style={{ height: 30 }}>
          <div
            className="w-100"
            style={{ height: 1, backgroundColor: "#FFFFFF" }}
          ></div>
          <div className="text-white mx-2 fs-10px">OR</div>
          <div
            className="w-100"
            style={{ height: 1, backgroundColor: "#FFFFFF" }}
          ></div>
        </div>
        <div className="row justify-content-center ">
          <Button
            onClick={async () => await googleLogin()}
            className="col-5 bg-white border-0"
            onMouseEnter={() => setTextColor(true)}
            style={{ color: textColor ? "black !important" : "black" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <div className="me-2">
                <Image src={googleButtonIcon} />
              </div>
              <div>GOOGLE</div>
            </div>
          </Button>
          {
            process.env.REACT_APP_FACEBOOK_APP_ID && <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              autoLoad={false}
              fields="name,email,picture"
              onClick={() => {
                // log.log("clicked");
                // log.log(process.env.REACT_APP_FACEBOOK_APP_ID);
              }}
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  className=" col-5  border-0 text-white mx-2 py-3"
                  style={{ backgroundColor: "#1877F2" }}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="me-2">
                      <Image src={facebookButtonIcon} />
                    </div>
                    <div>FACEBOOK</div>
                  </div>
                </Button>
              )}
            />
          }
          <div className="col-5 mx-2"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center my-4" style={{ height: 30 }}>
        <div
          className="w-100"
          style={{ height: 1, backgroundColor: "#FFFFFF" }}
        ></div>
        <div className="text-white mx-2 fs-10px">OR</div>
        <div
          className="w-100"
          style={{ height: 1, backgroundColor: "#FFFFFF" }}
        ></div>
      </div>
      <div className="d-flex justify-content-center mb-4">
        <Button
          onClick={async () => await googleLogin()}
          className="w-100 bg-white border-0"
          style={{ color: textColor ? "black !important" : "black" }}
          // style={{color: "black !important"}}
          onMouseEnter={() => setTextColor(true)}
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="me-2">
              <Image src={googleButtonIcon} />
            </div>
            <div>GOOGLE</div>
          </div>
        </Button>
        {process.env.REACT_APP_FACEBOOK_APP_ID && <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          onClick={() => {
            // log.log("clicked");
            // log.log(process.env.REACT_APP_FACEBOOK_APP_ID);
          }}
          callback={responseFacebook}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              className="w-100 border-0 text-white mx-2 py-3"
              style={{ backgroundColor: "#1877F2" }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-2">
                  <Image src={facebookButtonIcon} />
                </div>
                <div>FACEBOOK</div>
              </div>
            </Button>
          )}
        />}
      </div>
    </>
  );
};

export default FacebookGoogleLogin;
