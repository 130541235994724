import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import * as authActions from "Redux/Actions/authActions";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { notify } from "Utilities/Notifications/Notify";
import moment from "moment";
import axios from "axios";
import { BOOKED_SEAT_COLOR, CONNECTION_MESSAGE, LOCKED_SEAT_COLOR, MAX_RETRIES_CONNECTION_MESSAGE, UNIQUE_MAX_CONNECTION_MESSAGE } from "./utils";
import TicketMode from "./TicketMode";
import FoodAndCheckoutMode from "./FoodAndCheckoutMode";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import log from "loglevel";
import ReactGA from 'react-ga4';


const SelectSeats = () => {
    let interval = useRef(null);
    const navigate = useNavigate();

    const small = useMediaQuery({ query: "(min-width: 576px)" });
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    const { movieId, showId } = useParams();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const user = useSelector((state) => state.auth.user);
    const [ticketMode, setTicketMode] = useState(true);
    const [timeLeft, setTimeLeft] = useState(0);
    const [paymentTimeLeft, setPaymentTimeLeft] = useState(0);
    const [showPaymentTimeLeft, setShowPaymentTimeLeft] = useState(true);
    const [noLoginUserId, setNoLoginUserId] = useState("");

    const [seatNumberModal, setSeatNumberModal] = useState(true);
    const [seatLockingInProgress, setSeatLockingInProgress] = useState(false);

    const [movieData, setMovieData] = useState(null);
    const [showData, setShowData] = useState(null);
    const [hallSeats, setHallSeats] = useState([]);
    const [hallSeatsLoading, setHallSeatsLoading] = useState(false);
    const [seatTypes, setSeatTypes] = useState([]);
    const [bookedSeats, setBookedSeats] = useState([]);
    const [ticketVariety, setTicketVariety] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [maxSeatSelectionAllowed, setMaxSeatSelectionAllowed] = useState(10);
    const [totalSeatsSelected, setTotalSeatsSelected] = useState(1);
    const [ticketCoupon, setTicketCoupon] = useState(0);
    const [ticketCouponId, setTicketCouponId] = useState(null);

    const [thisTheatre, setThisTheatre] = useState(null);
    const [categories, setCategories] = useState([]);
    const [foods, setFoods] = useState([]);
    const [convenienceFee, setConvenienceFee] = useState(null);
    const [currentCategory, setCurrentCategory] = useState("");
    const [orderedFood, setOrderedFood] = useState([]);
    const [orderedFoodPrice, setOrderedFoodPrice] = useState(0);
    const [maxAllowedFoodQuantity, setMaxAllowedFoodQuantity] = useState(10);
    const [totalFoodQuantity, setTotalFoodQuantity] = useState(0);
    const [foodCoupon, setFoodCoupon] = useState(0);
    const [foodCouponId, setFoodCouponId] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);

    const [seatCouponTitle, setSeatCouponTitle] = useState(null);
    const [foodCouponTitle, setFoodCouponTitle] = useState(null);

    const [allowCheckout, setAllowCheckout] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showForm, setShowForm] = useState("login");
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [phoneCode, setPhoneCode] = useState(null);

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [order, setOrder] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [paymentInfoLoading, setPaymentInfoLoading] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [mytPaymentInfo, setMytPaymentInfo] = useState(null);
    const [iframeContent, setIframeContent] = useState("");

    const [myTHeaders, setMyTHeaders] = useState(null);

    const [taxes, setTaxes] = useState([]);
    const [clientChannelSettings, setClientChannelSettings] = useState(null);

    const [totalTicketTaxes, setTotalTicketTaxes] = useState([]);
    const [totalFoodTaxes, setTotalFoodTaxes] = useState([]);

    const seatContainerRef = useRef(null);
    const socket = useRef(null);
    const [socketConnected, setSocketConnected] = useState(false);
    const [socketConnectionMessage, setConnectionMessage] = useState(CONNECTION_MESSAGE);
    const allowSocketRetry = useRef(true);

    const fetchMovie = useCallback(async () => {
        const response = await authActions.fetchClientProgram(movieId);
        if (response.status === 200) {
            setMovieData(response.data);
        }
    }, [movieId]);

    useEffect(() => {
        if (movieId) {
            fetchMovie();
        }
    }, [movieId, fetchMovie]);

    const getClientChannelSettings = useCallback(async (theatre_id) => {
        const client_channel_settings = await authActions.fetchClientChannelSettings(process.env.REACT_APP_CLIENT_ID, theatre_id, "WEB");
        if (client_channel_settings) {
            setClientChannelSettings(client_channel_settings);
            let checkout_timeout = client_channel_settings?.checkout_timeout;
            let payment_timeout = client_channel_settings?.payment_timeout;
            let max_food_allowed = Number(client_channel_settings?.max_food_allowed);
            let max_seat_allowed = Number(client_channel_settings?.max_seat_allowed);
            log.log({ max_food_allowed, max_seat_allowed })
            if (!isNaN(max_seat_allowed)) setMaxSeatSelectionAllowed(max_seat_allowed);
            if (!isNaN(max_food_allowed)) setMaxAllowedFoodQuantity(max_food_allowed);

        }

    }, []);

    const fetchShowData = useCallback(async () => {
        const response = await authActions.fetchShow(showId);
        if (response.status === 200) {
            setShowData(response.data);
            getClientChannelSettings(response.data?.show_data?.theatre_data?.id);
        } else {
            if (response?.response?.status === 404 && response?.response?.data?.show_expired) {
                notify("This show is expired!", "info");
                navigate(`/show/${movieId}`);
            }
        }

    }, [showId, getClientChannelSettings]);

    // const fetchShowSeatsData = useCallback(async () => {
    //   const response = await authActions.fetchShowSeats(showId);
    //   if (response.status === 200) {
    //     // setHallSeats(response.data)
    //   }
    // }, [showId]);

    const fetchShowSeatTypesData = useCallback(async () => {
        const response = await authActions.fetchShowSeatTypes(showId);
        if (response.status === 200) {
            setSeatTypes([
                { color: BOOKED_SEAT_COLOR, title: "Unavailable" },
                { color: LOCKED_SEAT_COLOR, title: "Locked" },
                ...response.data,
            ]);
        }
    }, [showId]);

    const fetchAllCategories = useCallback(async () => {
        const response = await authActions.fetchCategories(showData?.theatre);
        if (response.status === 200) {
            setCategories(response.data.results);
        }
    }, [showData?.theatre]);

    const fetchFood = useCallback(async () => {
        if (!showData?.theatre) return;
        const response = await authActions.fetchFoodOfCategory(
            showData?.theatre,
            currentCategory
        );
        if (response.status === 200) {
            setFoods(response.data);
        }
    }, [showData?.theatre, currentCategory]);

    const fetchConvenienceFee = useCallback(async () => {
        const response = await authActions.fetchConvenienceFee();
        console.log({fetchConvenienceFee: response})
        setConvenienceFee(response);
    }, []);

    const fetchTaxes = useCallback(async () => {
        const response = await authActions.getTaxes(showData?.theatre);
        if (response) {
            setTaxes(response);
        }
    }, [showData?.theatre]);

    const unlockSeats = useCallback(async (with_reload = false) => {
        const data = {
            show: showId,
            seats: bookedSeats.map((seat) => {
                return seat.id;
            }),
        };
        const response = await authActions.unlockSeats(data);
        if (response) {
            setTicketMode(true);
            setBookedSeats([]);
        } else {
            // setHallSeats([]);
            // fetchShowSeatsData();
        }

        if (with_reload) window.location.reload();
    }, [bookedSeats, showId])
    const unlockSeatsOnError = async(showId, bookedSeats, with_reload = false) => {
        const data = {
            show: showId,
            seats: bookedSeats.map((seat) => {
                return seat.id;
            }),
        };
        const response = await authActions.unlockSeats(data);
        if (response) {
            setTicketMode(true);
            setBookedSeats([]);
        } else {
            // setHallSeats([]);
            // fetchShowSeatsData();
        }

        if (with_reload) window.location.reload();
    }
    useEffect(() => {
        interval.current = setTimeout(() => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
            }
            if (!ticketMode && timeLeft === 0) {
                notify("Timeout!", "error");
                setTicketMode(true);
                setBookedSeats([]);
                unlockSeats(true);
            }
        }, 1000);
        return () => clearTimeout(interval.current);
    }, [timeLeft, ticketMode, unlockSeats]);

    const checkMyTHeaders = useCallback(async () => {
        try {
            const response = await axios.get(
                `${window.location.origin}/get-request-headers`
            ); // Adjust the endpoint as necessary
            let headers = response.data;
            log.log({ requestHeaders: headers });
            setMyTHeaders(headers);
        } catch (e) {
            log.log(e);
        }
    }, []);

    useEffect(() => {
        if (showId) {
            fetchShowData();
            // fetchShowSeatsData();
            fetchShowSeatTypesData();
        }
    }, [showId, fetchShowData, fetchShowSeatTypesData]);

    useEffect(() => {
        if (movieData && showData) {
            setThisTheatre(
                movieData?.theatres?.filter((th) => th.id === showData?.theatre)[0]
                    ?.title
            );
            fetchTaxes();
        }
    }, [movieData, showData, fetchTaxes]);

    useEffect(() => {
        if (bookedSeats.length > 0) {
            let total_price = 0;
            bookedSeats.map(
                (bookedSeat) => (total_price += parseFloat(bookedSeat.price))
            );
            setTotalPrice(total_price);
            let ticket_variety = [];
            bookedSeats.forEach((bookedSeat) => {
                let filtered_Seats = bookedSeats.filter(
                    (item) => item.seatType === bookedSeat.seatType
                );
                if (
                    ticket_variety.filter(
                        (variety) => variety.type === bookedSeat.seatType_data.title
                    ).length > 0
                ) {
                } else {
                    log.log({ filtered_Seats });
                    ticket_variety.push({
                        type: bookedSeat.seatType_data.title,
                        quantity: filtered_Seats.length,
                        color: bookedSeat.seatType_data.color,
                        displayLabels: filtered_Seats
                            .map((fs) => fs.display_label)
                            .join(", "),
                    });
                }
            });
            log.log(ticket_variety);
            setTicketVariety(ticket_variety);
        } else {
            setTotalPrice(0);
            setTicketVariety([]);
        }
    }, [bookedSeats]);

    useEffect(() => {
        let food_price = 0;
        orderedFood.forEach((food) => {
            food_price += food.quantity * food.price;
        });
        setOrderedFoodPrice(food_price);
    }, [orderedFood]);

    useEffect(() => {
        if (!ticketMode) {
            fetchAllCategories();
            fetchFood();
            checkMyTHeaders();
            fetchConvenienceFee();
        }
    }, [ticketMode, showData, fetchAllCategories, fetchFood, checkMyTHeaders, fetchConvenienceFee]);

    useEffect(() => {
        fetchFood();
    }, [currentCategory, showData, fetchFood]);
    const getMatchingSeats = (Seats, messageMatrix) => {
        return Seats.filter(seat =>
            messageMatrix.some(row =>
                row.some(newSeat =>
                    newSeat.id === seat.id &&
                    (newSeat.status === "AVAILABLE" || 
                    (newSeat.status === "LOCKED" && newSeat.udid !== "" && newSeat.udid !== null))
                )
            )
        );
    };
    const lockSeats = useCallback(async () => {
        const data = {
            show: showId,
            seats: bookedSeats.map((seat) => {
                return seat.id;
            }),
        };
        setSeatLockingInProgress(true);
        const response = await authActions.lockSeats(data);
        if (response) {
            setTicketMode(false);
        } else {
            setBookedSeats([]);
            // setHallSeats([]);
            // fetchShowSeatsData();
        }
        setSeatLockingInProgress(false);
    }, [showId, bookedSeats])

    const checkSeatCoupon = useCallback(async (e) => {
        e.preventDefault();
        let formData = {
            theatre_id: showData?.theatre,
            channel_code: "WEB",
            on_value: totalPrice,
            no_of_tickets: totalSeatsSelected,
            coupon: seatCouponTitle,
        };

        const response = await authActions.checkSeatCouponValidity(formData);
        if (response?.data) {
            setTicketCoupon(response?.data.value);
            setTicketCouponId(response?.data.uuid);
        } else {
            setTicketCoupon(0);
            setTicketCouponId(null);
            //notify(`Coupon not found`, "error");
        }
    }, [seatCouponTitle, showData?.theatre, totalPrice, totalSeatsSelected])

    const checkFoodCoupon = useCallback(async (e) => {
        e.preventDefault();
        let formData = {
            theatre_id: showData?.theatre,
            channel_code: "WEB",
            on_value: orderedFoodPrice,
            coupon: foodCouponTitle,
        };

        const response = await authActions.checkFoodCouponValidity(formData);
        if (response?.data) {
            setFoodCoupon(response?.data.value);
            setFoodCouponId(response?.data.uuid);
        } else {
            setFoodCoupon(0);
            setFoodCouponId(null);
            //notify(`Coupon not found`, "error");
        }
    }, [showData?.theatre, orderedFoodPrice, foodCouponTitle]);

    const fetchMipsPaymentDetails = useCallback(async (payment_id) => {
        setPaymentInfoLoading(true);
        try {
            log.log(order.id);
            const info = await authActions.getPaymentInfo(
                order.id,
                payment_id,
                showData?.theatre
            );
            log.log({ info });
            setPaymentInfo(info);
            if ("iframe" in info) {
                if ("answer" in info.iframe) {
                    if (info.iframe.answer.operation_status === "success") {
                        const iframeContent = info.iframe.answer.payment_zone_data;
                        log.log("Setting iframe content to:", iframeContent);
                        setIframeContent(iframeContent);
                    }
                }
            }
        } catch (e) {
            log.error(e);
            setPaymentInfo(`${e}`);
            notify(`${e}`, "error");
        }
        setPaymentInfoLoading(false);
    }, [order?.id, showData?.theatre]);

    const fetchMytPaymentDetails = useCallback(async (payment_id) => {
        setPaymentInfoLoading(true);
        try {
            const info = await authActions.getMytPaymentInfo(
                payment_id,
                showData?.theatre
            );
            log.log({ info });
            if (info?.myt_data) {
                setMytPaymentInfo(info);
            } else {
                notify("Something went wrong");
                setMytPaymentInfo(null);
            }
        } catch (e) {
            log.error(e);
            setMytPaymentInfo(`${e}`);
            notify(`${e}`, "error");
        }
        setPaymentInfoLoading(false);
    }, [showData?.theatre]);

    const handleCheckout = useCallback(async () => {
        log.log({ isAuthenticated, email, phone, phoneCode })
        if (!isAuthenticated && !(email && phone && phoneCode && noLoginUserId)) {
            log.log("There's still time!");
            setShowLoginModal(true);
            setAllowCheckout(false);
            
            ReactGA.event({
                category: "booking_flow",
                action: "confirm_not_authenticated",
                label: "CONFIRM NOT AUTHENTICATED", // optional
            });
        } else if (!allowCheckout) {
            notify("You need to login first!", "error");
            // setAllowCheckout(false);
            setShowLoginModal(true);
            ReactGA.event({
                category: "booking_flow",
                action: "confirm_not_allowed_checkout",
                label: "CONFIRM NOT ALLOW CHECKOUT", // optional
            });
        } else {
            log.log("Let's do it!");
            setShowLoginModal(false);
            setIsSubmitting(true);
            const data_seats = bookedSeats.map((bs) => bs.id);
            const data_foods = orderedFood.map((of) => {
                return {
                    id: of.food,
                    ordered_quantity: of.quantity,
                };
            });
            const data = {
                show: showId,
                theatre_id: showData?.theatre,
                channel_code: "WEB",
                // "payment_method": "CASH",
                payment_method: "ONLINE",
                seats: data_seats,
                food_items: data_foods,
                coupon: ticketCouponId,
                food_coupon: foodCouponId,
                send_to_email: email,
                send_to_mobile: phone,
                send_to_mobile_code: phoneCode,
                user: user?.id || noLoginUserId,
                created_by: user?.id || noLoginUserId,
            };
            const response = await authActions.createOrder(data);
            log.log({ response });
            if (response) {
                setOrder(response.order);
                setIsSubmitting(false);
                setPaymentId(response.payment["id"]);
                setPaymentTimeLeft(3 * 60);
                setShowPaymentModal(true);
                allowSocketRetry.current = false;
                // fetchPaymentDetails(response.payment["id"])
                // if ("redirect_url" in response) {
                //   navigate(response['redirect_url']);
                // }
            }
            ReactGA.event({
                category: "booking_flow",
                action: "confirm_checking_out",
                label: "CONFIRM CHECKING OUT", // optional
            });
            setIsSubmitting(false);
        }
    }, [showId, showData?.theatre, bookedSeats, email, foodCouponId, isAuthenticated, noLoginUserId, orderedFood, phone, ticketCouponId, user?.id, allowCheckout, phoneCode]);
    const bookedSeatsRef = useRef(bookedSeats);

    useEffect(() => {
        bookedSeatsRef.current = bookedSeats;
    }, [bookedSeats]);

    const ticketModeRef = useRef(ticketMode);

    useEffect(() => {
        ticketModeRef.current = ticketMode;
    }, [ticketMode]);

    const seatLockingInProgressRef = useRef(seatLockingInProgress);

    useEffect(() => {
        seatLockingInProgressRef.current = seatLockingInProgressRef;
    }, [seatLockingInProgressRef]);
    
    useEffect(() => {
        if (allowCheckout) {
            handleCheckout();
        }
    }, [allowCheckout, handleCheckout]);

    useEffect(()=>{
        log.log({allowCheckout})
    }, [allowCheckout]);

    useEffect(() => {
        if (hallSeats?.length > 0) {
            if (seatContainerRef.current) {
                const container = seatContainerRef.current;
                const contentWidth = container.scrollWidth;
                const containerWidth = container.clientWidth;
                container.scrollLeft = (contentWidth - containerWidth) / 2;
            }
        }
    }, [hallSeats]);

    useEffect(() => {
        let ticketTaxes = 0;
        let foodTaxes = 0;
        if (totalPrice > 0) {
            let ticket_taxes_array = taxes.filter((tax) => tax.type === "TICKET");
            for (let tax of ticket_taxes_array) {
                let priceToCalTaxOn = totalPrice.toFixed(2) - ticketCoupon.toFixed(2);
                let _tax = parseFloat((priceToCalTaxOn * (tax.value / 100)).toFixed(2));
                ticketTaxes += _tax;
            }
        }
        setTotalTicketTaxes(ticketTaxes);
        if (orderedFoodPrice > 0) {
            taxes
                .filter((tax) => tax.type === "FOOD")
                .forEach((tax) => {
                    foodTaxes += parseFloat(
                        (
                            ((orderedFoodPrice.toFixed(2) - foodCoupon.toFixed(2)) *
                                tax.value) /
                            100
                        ).toFixed(2)
                    );
                });
        }
        setTotalFoodTaxes(foodTaxes);
        log.log(totalPrice);
        // let ceil_value = Math.ceil(
        //     totalPrice.toFixed(2) -
        //     ticketCoupon.toFixed(2) +
        //     orderedFoodPrice.toFixed(2) -
        //     foodCoupon.toFixed(2) +
        //     ticketTaxes +
        //     foodTaxes
        // ).toFixed(2);
        // let total_value = (
        //     totalPrice.toFixed(2) -
        //     ticketCoupon.toFixed(2) +
        //     orderedFoodPrice.toFixed(2) -
        //     foodCoupon.toFixed(2) +
        //     ticketTaxes +
        //     foodTaxes
        // ).toFixed(2);
        // if (ceil_value - total_value >= 0) {
        //     setRoundValue((ceil_value - total_value).toFixed(2));
        // }
    }, [totalPrice, ticketCoupon, orderedFoodPrice, foodCoupon, taxes]);

    useEffect(() => {
        if (user) {
            setEmail(user.email);
            setPhone(user.mobile);
            setPhoneCode(user.mobile_code);
            if (isAuthenticated === false && user) {
                handleCheckout();
            }
        }
    }, [user, handleCheckout, isAuthenticated]);

    const fetchShowSeatsDataViaWS = useCallback(async () => {
        const response = await authActions.fetchTheatreURLFromShowId(showId);
        let local_url = "";
        if (response.status === 200) {
            const result = response.data;
            log.log(result);
            local_url = `${process.env.REACT_APP_BASE_URL
                .replace("https://", "wss://")
                .replace("http://", "ws://")}/ws/seat/WEB/${result.theatre_id}/${showId}/`;
        }

        let reconnectInterval = 5000; // Initial reconnect interval in milliseconds
        const maxReconnectInterval = 30000; // Maximum reconnect interval
        const maxRetries = 5; // Maximum number of reconnection attempts
        let socketRetryCount = {
            current: 0
        }

        const connectWebSocket = () => {
            // log.log("Websocket before connecting ", socket.current, socket.current?.readyState);
            setConnectionMessage(CONNECTION_MESSAGE);

            if (socket.current?.readyState === 1) {
                // log.log("Websocket already connected");
                return;
            }

            log.log("Connecting to Websocket...")
            socket.current = new WebSocket(local_url);

            socket.current.onopen = () => {
                // log.log("Connected to the WebSocket server", socket.current);
                reconnectInterval = 5000;
                setTimeout(() => {
                    if (socket?.current?.readyState === 1) {
                        setSocketConnected(true);
                    }
                }, 2000);
                // socketRetryCount.current = 0;
            };

            socket.current.onmessage = (event) => {
                // log.log(`Websocket => received message:`, event, socket.current?.readyState);
                const response = event.data;
                const jsonResponse = JSON.parse(response);
                log.log(jsonResponse);

                if ("message" in jsonResponse) {
                    log.log("Websocket => ", jsonResponse.message)
                    if (jsonResponse.message?.includes?.("Connection error")) {
                        // log.log("Websocket connection error!");
                        setSocketConnected(false);
                        socket.current.close();
                    } else {
                        const matchingSeats = getMatchingSeats(bookedSeatsRef.current, jsonResponse.message);
                        if (matchingSeats.length != 0 && !ticketModeRef.current && !seatLockingInProgressRef.current) {
                            const untouchedSeats = bookedSeatsRef.current.filter(seat => !matchingSeats.some(matchingSeat => matchingSeat.id === seat.id));
                            notify("Some of the seats you selected were overriden by Administrator! Please select different seats.");
                            unlockSeatsOnError(showId, untouchedSeats, true);
                            setTicketMode(true);
                        }
                        // if (bookedSeats)
                        setHallSeats(jsonResponse.message);
                    }
                }
                if ("changed_seats" in jsonResponse) {
                    const changed_seats = jsonResponse.changed_seats;
                    log.log(changed_seats);
                    setHallSeats((prevHallSeats) => {
                        let tempSeats = [...prevHallSeats];
                        for (let i = 0; i < changed_seats.length; i++) {
                            if (tempSeats?.[changed_seats?.[i]?.row_index]?.[changed_seats?.[i]?.column_index] && changed_seats?.[i])
                                tempSeats[changed_seats[i].row_index][changed_seats[i].column_index] = changed_seats[i];
                        }
                        return tempSeats;
                    });
                }
            };

            socket.current.onerror = (error) => {
                // log.error("WebSocket error:", error, socket.current?.readyState);
                socket.current.close();
            };

            socket.current.onclose = (event) => {
                log.log("Disconnected from the WebSocket server", event, socket.current?.readyState);
                if (!allowSocketRetry.current) {
                    return;
                }
                setSocketConnected(false);
                if (event.wasClean) {
                    // log.log(`WebSocket Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
                    reconnect();
                } else {
                    // console.error('WebSocket Connection closed abruptly, Attempting to reconnect...');
                    reconnect();
                }
            };
        };

        const reconnect = () => {

            if (socket?.current?.readyState === 1) {
                log.log("Skipped reconnecting because it is already connected");
                return;
            }

            if (socketRetryCount.current >= maxRetries) {
                setConnectionMessage(MAX_RETRIES_CONNECTION_MESSAGE);
                console.error('Max retries reached. Giving up on reconnecting.');
                return;
            }

            log.log(`Reconnecting to WebSocket server in ${reconnectInterval / 1000} seconds for the ${socketRetryCount.current + 1} time...`, socket.current?.readyState);
            setTimeout(() => {
                connectWebSocket();
                reconnectInterval = Math.min(reconnectInterval * 2, maxReconnectInterval); // Exponential backoff
                socketRetryCount.current = socketRetryCount.current + 1;
            }, reconnectInterval);
        };

        connectWebSocket();

        return () => {
            if (socket.current) {
                socket.current.close();
            }
        };
    }, [showId]);

    useEffect(() => {
        const a = async () => {
            fetchShowSeatsDataViaWS();
        };
        a();
    }, [fetchShowSeatsDataViaWS]);


    useEffect(()=> {
        return () => {
            if (socket.current) {
                socket.current.close();
                socket.current = null;
            }
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>{movieData?.title ? `MCine | Movies > ${movieData?.title} > Shows > ${moment(showData?.start_time, "HH:mm:ss").format("hh:mm a")}` : 'MCine | Movies'}</title>
            </Helmet>
            <div
                className="bg-slate user-select-none pb-1"
                style={{ minHeight: "85vh" }}
            >
                <div className="wrapper">
                    <ul className="breadcrumb subtitle mx-1 pb-4">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/movies">Movies</a>
                        </li>
                        <li>
                            <a href={`/movies/${movieData?.id}`}>{movieData?.title}</a>
                        </li>
                        <li>
                            <a href={`/show/${movieData?.id}`}>Shows</a>
                        </li>
                        <li>{moment(showData?.start_time, "HH:mm:ss").format("hh:mm a")}</li>
                    </ul>
                </div>

                {(!socketConnected) ?
                    <>
                        <Modal
                            dialogClassName="d-flex align-items-center h-100"
                            contentClassName="bg-slate border-0"
                            show={true}
                        >
                            <Modal.Header
                                closeButton={false}
                                className="d-flex border-0 d-flex justify-content-center align-items-center"
                            >
                                <Modal.Title className="text-white">
                                    {
                                        socketConnectionMessage?.includes(UNIQUE_MAX_CONNECTION_MESSAGE) ? "Couldn't Connect" : "Connecting to the server ..."
                                    }
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="px-3 d-flex gap-3 flex-column justify-content-center align-items-center">
                                {
                                    socketConnectionMessage?.includes(UNIQUE_MAX_CONNECTION_MESSAGE) ? <></> : <Spinner className="text-white" />
                                }
                                <div className="d-flex justify-content-between w-100">
                                    <p className="text-center text-white">{socketConnectionMessage}</p>
                                </div>
                                {
                                    socketConnectionMessage?.includes(UNIQUE_MAX_CONNECTION_MESSAGE) ?
                                        <Button style={{ backgroundColor: "red", color: "white", borderColor: "red" }} variant="primary" size="sm" onClick={() => window.location.reload()}>
                                            Retry
                                        </Button> : <></>
                                }
                            </Modal.Body>
                        </Modal>
                    </>
                    : ticketMode ? (
                        <TicketMode
                            movieData={movieData}
                            showData={showData}
                            thisTheatre={thisTheatre}
                            small={small}
                            medium={medium}
                            large={large}
                            totalSeatsSelected={totalSeatsSelected}
                            bookedSeats={bookedSeats}
                            setBookedSeats={setBookedSeats}
                            setTotalSeatsSelected={setTotalSeatsSelected}
                            maxSeatSelectionAllowed={maxSeatSelectionAllowed}
                            seatContainerRef={seatContainerRef}
                            hallSeats={hallSeats}
                            seatTypes={seatTypes}
                            ticketVariety={ticketVariety}
                            totalPrice={totalPrice}
                            seatLockingInProgress={seatLockingInProgress}
                            lockSeats={lockSeats}
                            setTimeLeft={setTimeLeft}
                            seatNumberModal={seatNumberModal}
                            setSeatNumberModal={setSeatNumberModal}
                        />
                    ) : (
                        <FoodAndCheckoutMode
                            showPaymentTimeLeft={showPaymentTimeLeft}
                            timeLeft={timeLeft}
                            movieData={movieData}
                            showData={showData}
                            thisTheatre={thisTheatre}
                            large={large}
                            medium={medium}
                            small={small}
                            unlockSeats={unlockSeats}
                            currentCategory={currentCategory}
                            setCurrentCategory={setCurrentCategory}
                            categories={categories}
                            errorMessage={errorMessage}
                            foods={foods}
                            orderedFood={orderedFood}
                            setOrderedFood={setOrderedFood}
                            maxAllowedFoodQuantity={maxAllowedFoodQuantity}
                            totalFoodQuantity={totalFoodQuantity}
                            setErrorMessage={setErrorMessage}
                            setTotalFoodQuantity={setTotalFoodQuantity}
                            ticketVariety={ticketVariety}
                            bookedSeats={bookedSeats}
                            totalPrice={totalPrice}
                            ticketCoupon={ticketCoupon}
                            taxes={taxes}
                            seatCouponTitle={seatCouponTitle}
                            setSeatCouponTitle={setSeatCouponTitle}
                            setTicketCoupon={setTicketCoupon}
                            setTicketCouponId={setTicketCouponId}
                            checkSeatCoupon={checkSeatCoupon}
                            orderedFoodPrice={orderedFoodPrice}
                            foodCoupon={foodCoupon}
                            foodCouponTitle={foodCouponTitle}
                            setFoodCouponTitle={setFoodCouponTitle}
                            setFoodCoupon={setFoodCoupon}
                            setFoodCouponId={setFoodCouponId}
                            checkFoodCoupon={checkFoodCoupon}
                            totalTicketTaxes={totalTicketTaxes}
                            totalFoodTaxes={totalFoodTaxes}
                            isSubmitting={isSubmitting}
                            setAllowCheckout={setAllowCheckout}
                            showLoginModal={showLoginModal}
                            setShowLoginModal={setShowLoginModal}
                            showForm={showForm}
                            setShowForm={setShowForm}
                            email={email}
                            setEmail={setEmail}
                            phone={phone}
                            phoneCode={phoneCode}
                            setPhone={setPhone}
                            setPhoneCode={setPhoneCode}
                            setNoLoginUserId={setNoLoginUserId}
                            showPaymentModal={showPaymentModal}
                            myTHeaders={myTHeaders}
                            showId={showId}
                            order={order}
                            setShowPaymentModal={setShowPaymentModal}
                            paymentTimeLeft={paymentTimeLeft}
                            setPaymentTimeLeft={setPaymentTimeLeft}
                            setShowPaymentTimeLeft={setShowPaymentTimeLeft}
                            paymentId={paymentId}
                            paymentInfoLoading={paymentInfoLoading}
                            mytPaymentInfo={mytPaymentInfo}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            fetchMipsPaymentDetails={fetchMipsPaymentDetails}
                            fetchMytPaymentDetails={fetchMytPaymentDetails}
                            iframeContent={iframeContent}
                            convenienceFee={convenienceFee}
                        />
                    )}
            </div>
        </>
    );
};

export default SelectSeats;
