import React from 'react';
import './HorizontalScrollableText.css'; // Import CSS file for styling

const HorizontalScrollableText = ({ hoverText, className, style, children }) => {

    return (
        <div className="scroll-container" title={hoverText}>
            <div className={`scroll-content ${className}`} style={{ ...style }}>
                {children}
            </div>
        </div>
    );
}

export default HorizontalScrollableText;