import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import MovieCarousel from "./MovieCarousel";
import MovingNavbar from "website/components/Navbar/MovingNavbar";
import * as authActions from "Redux/Actions/authActions";

const HeroSection = ({ showForm, setShowForm }) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [cards, setCards] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentFilm, setCurrentFilm] = useState(0);

  const clientId = process.env.REACT_APP_CLIENT_ID;

  const fetchTrending = async (e) => {
    const response = await authActions.fetchTrendingPrograms(clientId);
    if (response.status === 200) {
      setCards(response.data)
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchTrending()
    }
  }, [clientId])

  return (
    <Container fluid className="hero-background p-0" style={{ minHeight: "100vh" }}>
      <Container
        style={large ? styles.parentContainer : styles.mobileParentContainer}
      >
        <MovingNavbar showForm={showForm} setShowForm={setShowForm} />
      </Container>
      <MovieCarousel
        cards={cards}
        setOpenModal={setOpenModal}
        setCurrentFilm={setCurrentFilm}
      />
      <Modal
        // contentClassName="bg-transparent border-0"
        contentClassName={`border-0 ${cards[currentFilm]?.trailer_link ? "bg-transparent" : "bg-black"}`}
        show={openModal}
        onHide={() => setOpenModal(false)}
        animation={true}
        centered
        size="lg"
      >
        <Modal.Header
          className="border-0"
          closeButton
          closeVariant="white"
        ></Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center p-0">
          {cards[currentFilm]?.trailer_link ?
            <iframe
              width="90%"
              height="450"
              src={cards[currentFilm].trailer_link}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            :
            <div className="text-white mt-4 mb-5 pt-4 pb-5 d-flex justify-content-center">
              Trailer not available!
            </div>
          }
        </Modal.Body>
      </Modal>
    </Container>
  );
};

const styles = {
  parentContainer: {
    padding: "40px 100px",
  },
  mobileParentContainer: {
    padding: "20px 10px",
  },
};

export default HeroSection;
