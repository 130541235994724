import log from 'loglevel';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import * as authSlices from "Redux/AuthReducer/authSlices";
import { asyncStatuses } from 'Redux/enums';
import { onInputTextShouldBeLowerCase } from 'utils';

const formDataToObject = (formData) => {
    const object = {};
    formData.forEach((value, key) => {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        } else {
            object[key] = value;
        }
    });
    return object;
};

const LoginWithPassword = ({ checkoutMode, setAllow }) => {
    const form = useRef(null);
    const dispatch = useDispatch();

    const status = useSelector(store => store.auth?.status);

    const handleLogin = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        log.log(formDataToObject(formData));
        dispatch(authSlices.userLogin(formData));
    };

    useEffect(()=>{
        if (checkoutMode && status === asyncStatuses.SUCCESS) {
            setAllow(true)
        }
    }, [status]);

    return (
        <Form
            className="text-start w-100"
            ref={form}
            onSubmit={(e) => handleLogin(e)}
        >
            <Form.Group className="mb-3" controlId="loginForm.email">
                <Form.Label className="text-white">Email address</Form.Label>
                <Form.Control
                    type="email"
                    name="username"
                    placeholder="name@example.com"
                    className="bg-extradark py-2 border-grey text-lightgrey"
                    onInput={onInputTextShouldBeLowerCase}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="loginForm.password">
                <Form.Label className="text-white">Password</Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="bg-extradark py-2 border-grey text-lightgrey"
                />
            </Form.Group>
            <Button
                className="bg-primary border-primary w-100 custom-button mt-2 py-2"
                type="submit"
            >
                {status === asyncStatuses.LOADING ? (
                    <span>
                        <Spinner size="sm" className="me-2" />
                        LOGGING IN ...
                    </span>
                ) : (
                    <span>LOGIN</span>
                )}
            </Button>
        </Form>
    )
}

export default LoginWithPassword