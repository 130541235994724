import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Image,
  Form,
  Button,
} from "react-bootstrap";
import { default_profile_picture } from "assets";
import * as authActions from "Redux/Actions/authActions";
import * as authSlices from "Redux/AuthReducer/authSlices";
import { notify } from "Utilities/Notifications/Notify";
import { useDispatch, useSelector } from "react-redux";
import { IoCheckmarkDoneCircleSharp, IoWarning } from "react-icons/io5";
import log from "loglevel";
import { Helmet } from "react-helmet";
import { onInputTextShouldBeLowerCase } from "utils";

const Profile = () => {
  const dispatch = useDispatch();
  const [photoFile, setPhotoFile] = useState(null)
  const [profilePhoto, setProfilePhoto] = useState(null);
  const form = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const handleProfilePictureUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profile_pic", file);
    const response = await authSlices.userProfilePicture(user.id, formData);
    if (response) {
      log.log(response)
      dispatch(authSlices.updateProfilePicture(response.profile_pic));
      // setProfilePhoto(file);
      // if (file) {
      //   const reader = new FileReader();
      //   reader.onload = () => {
      //     setProfilePhoto(reader.result);
      //   };
      //   reader.readAsDataURL(file);
      // }
      notify("Profile Picture updated successfully!", "success");
    }
  };

  const handleProfilePictureClick = () => {
    document.getElementById("profile-picture-input").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSending(true);
    const formData = new FormData(form.current);
    formData.append("id", user.id);
    formData.append("profile_pic", photoFile);
    // formData.append("username", user.username);

    const response = await authSlices.userUpdate(formData);
    log.log(response);
    if (response) {
      dispatch(response);
      e.target.reset();
      setIsSending(false);
      notify("Profile has been updated!", "success");
    } else {
      notify("There was some problem updating the profile!", "error");
    }
  };

  useEffect(() => {
    if (user) {
      setProfilePhoto(user.profile_pic);
    }
  }, [user])

  return (
    <>
    <Helmet>
      <title>MCine | My Account</title>
    </Helmet>
    <div>
      <div className="bg-slate pb-5">
        {/* <div className="wrapper"> */}
        {/* <ul className="breadcrumb subtitle mx-1 pb-4">
            <li>
              <a href="/">Account</a>
            </li>
            <li>Profile</li>
          </ul> */}
        <div>
          <Card
            className="py-4 px-3"
            style={{
              backgroundColor: "#1c1e29",
              borderRadius: "0px",
            }}
          >
            <div className="d-flex flex-column align-items-center mb-3">
              <div
                className="py-4 mt-3 text-lightgrey"
                style={{
                  boxShadow: "0px 0px 20px 5px #000000",
                  width: 150,
                  height: 150,
                  borderRadius: "50%",
                }}
              >
                <input
                  type="file"
                  id="profile-picture-input"
                  accept="image/*"
                  className="d-none"
                  onChange={handleProfilePictureUpload}
                />
                <div
                  className="overflow-hidden cursor-pointer border rounded-circle d-flex justify-content-center align-items-center"
                  onClick={handleProfilePictureClick}
                  style={{ width: 150, height: 150, marginTop: -24 }}
                >
                  <img
                    src={
                      profilePhoto ? profilePhoto : default_profile_picture
                    }
                    alt="Profile"
                    className="w-100"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
              <Form className="w-100" ref={form} onSubmit={handleSubmit}>
                <div>
                  <h4 className="text-lightgrey mb-4 mt-4 d-flex justify-content-lg-center">
                    Account Details
                  </h4>
                  <Row className="mb-3 d-flex justify-content-lg-center align-items-center">
                    <Form.Label
                      className="text-lightgrey"
                      style={styles.label}
                    >
                      Email
                    </Form.Label>
                    <Col sm={5}>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="bg-slate text-lightgrey border-grey"
                        defaultValue={user?.email}
                        disabled={user?.is_active}
                        onInput={onInputTextShouldBeLowerCase}
                      />
                    </Col>
                    {user?.is_active &&
                      <Col sm={1}>
                        <IoCheckmarkDoneCircleSharp color="green" />
                      </Col>
                    }
                  </Row>
                  <Row className="mb-3 d-flex justify-content-lg-center align-items-center">
                    <Form.Label
                      className="text-lightgrey"
                      style={styles.label}
                    >
                      Mobile
                    </Form.Label>
                    <Col sm={5} className="d-flex">
                      <Form.Control
                        className="bg-slate text-lightgrey border-grey"
                        value={user?.mobile_code}
                        disabled
                        style={{ maxWidth: 70 }}
                      />
                      <Form.Control
                        type="text"
                        placeholder="Mobile Number"
                        name="mobile"
                        className="bg-slate text-lightgrey border-grey"
                        defaultValue={user?.mobile}
                        disabled={user?.is_mobile_active}
                      />
                    </Col>
                    {user?.is_mobile_active ?
                      <Col sm={1}>
                        <IoCheckmarkDoneCircleSharp color="green" />
                      </Col>
                      :
                      <Col sm={1}>
                        <IoWarning color="red" />
                      </Col>
                    }
                  </Row>
                </div>
                <div className="mt-5">
                  <h4 className="text-lightgrey mb-4 d-flex justify-content-lg-center">
                    Personal Details
                  </h4>
                  <Row className="mb-3 d-flex justify-content-lg-center align-items-center">
                    <Form.Label
                      className="text-lightgrey"
                      style={styles.label}
                    >
                      First Name
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        defaultValue={user?.first_name}
                        className="bg-slate text-white border-grey"
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 d-flex justify-content-lg-center align-items-center">
                    <Form.Label
                      className="text-lightgrey"
                      style={styles.label}
                    >
                      Last Name
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        className="bg-slate text-white border-grey"
                        defaultValue={user?.last_name}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="d-flex">
                  <Button
                    className="bg-primary text-white border border-0 text-uppercase px-4 py-2 mt-4 mx-auto rounded"
                    type="submit"
                  >
                    Save Profile
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </div>
        {/* </div> */}
      </div>
    </div>
    </>
  );
};

const styles = {
  label: {
    width: 150,
  },
};

export default Profile;
