import React, { useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import * as authActions from "Redux/Actions/authActions";
import { useSelector } from "react-redux";
import OrderCard from "./OrderCard";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router";
import { IoIosArrowBack } from "react-icons/io";
import OrderDetails from "./OrderDetails";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from "moment";
import NothingHere from "./NothingHere";

const Orders = () => {

  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { orderId } = useParams();
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const [ticketOrders, setTicketOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOrders = async () => {
    const response = await authActions.getTicketOrder(user?.id)
    if (response) {
      setTicketOrders(response.results)
    }
    setLoading(false);
  }

  useEffect(() => {
    if (user?.id) {
      fetchOrders()
    }
  }, [])

  return (
    <div className="bg-slate pb-5">
      <div>
        <Card
          className={`${medium ? "py-4 px-3" : "px-1"}`}
          style={{
            backgroundColor: "#1c1e29",
            borderRadius: "0px",
          }}
        >
          {medium &&
            <div className="d-flex">
              {orderId && <IoIosArrowBack size={25} className="me-1 cursor-pointer text-white" onClick={() => navigate(`/account/orders`)} />}
              <h4 className="text-white ms-2">Orders</h4>
            </div>
          }
          <>
            {!orderId ?
              <>
                {loading ?
                  <div className="d-flex justify-content-center align-items-center text-white" style={{ minHeight: "30vh" }}>
                    <Spinner />
                  </div>
                  :
                  <div>
                    {ticketOrders.length > 0 ?
                      <div id="orders-tab-user-container">
                        <Tabs
                          defaultActiveKey="upcoming"
                          id="orders-tab-user"
                          className="my-3 ms-2"
                          variant="pills"
                        >
                          <Tab eventKey="all" title="All">
                            {ticketOrders?.length === 0 && <NothingHere />}
                            {ticketOrders.map?.((order) => {
                              return (
                                <OrderCard key={order?.id} order={order} />
                              )
                            })}
                          </Tab>
                          <Tab eventKey="upcoming" title="Upcoming">
                            {ticketOrders?.filter?.((a) =>
                            (
                              a?.show_data?.date >= moment(new Date()).format("YYYY-MM-DD")
                              &&
                              a?.payments?.[0]?.payment_status === "PAID")
                            ).length === 0 && <NothingHere />}
                            {
                              ticketOrders
                                ?.filter?.((a) =>
                                (
                                  a?.show_data?.date >= moment(new Date()).format("YYYY-MM-DD")
                                  &&
                                  a?.payments?.[0]?.payment_status === "PAID")
                                ).map((order) => <OrderCard key={order?.id} order={order} />)
                            }
                          </Tab>
                          <Tab eventKey="past" title="Past">
                            {ticketOrders?.filter?.((a) => (
                              a?.show_data?.date < moment(new Date()).format("YYYY-MM-DD")
                              &&
                              a?.payments?.[0]?.payment_status === "PAID")
                            ).length === 0 && <NothingHere />}
                            {
                              ticketOrders
                                ?.filter?.((a) => (
                                  a?.show_data?.date < moment(new Date()).format("YYYY-MM-DD")
                                  &&
                                  a?.payments?.[0]?.payment_status === "PAID")
                                ).map((order) => <OrderCard key={order?.id} order={order} />)
                            }
                          </Tab>
                          <Tab eventKey="pending" title="Pending">
                            {ticketOrders?.filter?.(a => a?.payments?.[0]?.payment_status === "PENDING").length === 0 && <NothingHere />}
                            {
                              ticketOrders
                                ?.filter?.(a => a?.payments?.[0]?.payment_status === "PENDING")
                                ?.map((order) => <OrderCard key={order?.id} order={order} />)
                            }
                          </Tab>
                          <Tab eventKey="failed" title="Failed">
                            {ticketOrders?.filter?.(a => a?.payments?.[0]?.payment_status === "FAILED").length === 0 && <NothingHere />}
                            {
                              ticketOrders
                                ?.filter?.(a => a?.payments?.[0]?.payment_status === "FAILED")
                                ?.map((order) => <OrderCard key={order?.id} order={order} />)
                            }
                          </Tab>
                        </Tabs>
                      </div>
                      :
                      <div className="d-flex justify-content-center align-items-center text-white" style={{ minHeight: "30vh" }}>
                        No Orders Found!
                      </div>
                    }
                  </div>
                }
              </>
              :
              <div className="text-white">
                <OrderDetails order={ticketOrders.filter(ord => ord?.id === orderId)[0]} />
              </div>
            }
          </>
        </Card>
      </div>
    </div>
  );
};

const styles = {
  label: {
    width: 150,
  },
};

export default Orders;
