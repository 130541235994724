import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { movie_placeholder } from "assets";

const MovieDetailsShowcaseVertical = ({
  title,
  image,
  genres,
  runtime,
  releaseDate,
  certification,
  showCertification,
  showBookBtn = false,
  onClickBook,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [genresString, setGenresString] = useState("");

  useEffect(() => {
    let genres_string = "";
    let end = null
    if (genres) {
      for (let i = 0; i < genres.length; i++) {
        end = genres.length === i + 1 ? "" : ", "
        genres_string = genres_string + genres[i].name + end
      }
    }
    setGenresString(genres_string)
  }, [genres])


  return (
    <Card className="p-0 bg-extradark">
      <div className="px-5 pt-5">
        <img
          src={image}
          alt={`${title} poster`}
          className="img-fluid mb-4 mb-md-0 w-100 mt-3 mt-md-0 rounded"
          onError={(e)=> e.target.src = movie_placeholder}
        />
      </div>
      <div className="px-5 py-3">
        <div>
          <h4 className="text-white mb-3">{title}</h4>
          {genres && (
            <div className="d-flex mb-2 text-feather">
              {/* {genres.map((item, i) => (
                <p key={item.id} className="text-feather me-2">{`${item.name}${genres.length === i + 1 ? "" : ", "
                  }`}</p>
              ))} */}
              {genresString}
            </div>
          )}
          <div className="mb-2">
            {["Hindi", "English"].map((lang, i) => (
              <Badge key={i} bg="white text-black me-2 mb-2 p-2">
                {lang}
              </Badge>
            ))}
          </div>
          <div className="d-flex flex-column flex-md-row align-items-md-center mb-2">
            <div className="d-flex">
              {releaseDate && (
                <p className="text-feather me-2">{moment(releaseDate).format("DD MMM, YYYY")}</p>
              )}
              {runtime && (
                <p className="text-feather">• {runtime} m</p>
              )}
              {showCertification && (
                <p className="text-feather ms-2">• {certification}</p>
              )}
            </div>
          </div>
          {showBookBtn && (
            <Row className="px-3">
              <Button
                className="bg-primary primary-button border border-primary px-4 me-3"
                style={{ width: medium ? 160 : "100%" }}
                onClick={onClickBook}
              >
                Book Now
              </Button>
              <Button
                className="bg-slate primary-button border border-lightgrey px-4"
                style={{ width: medium ? 160 : "100%" }}
              // onClick={}
              >
                Watch Trailer
              </Button>
            </Row>
          )}
        </div>
      </div>
    </Card>
  );
};

export default MovieDetailsShowcaseVertical;
