import log from 'loglevel';
import React, { useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';

const STATUSES = {
    LOADING: 'loading',
    LOADING_COMPLETED: 'loading-completed',
    LOADING_FAILED: 'loading-failed',
}

const MyTPaymentMethod = ({ mytPaymentInfo, timerRef, setPaymentTimeLeft, setShowPaymentTimeLeft }) => {

    const [loading, setLoading] = useState(null);
    const [iframeLoading, setIframeLoading] = useState(true);
    const iframeRef = useRef(null);
    const url = useRef("https://pay.mytmoney.mu/Mt/web/payments");

    useEffect(() => {

        function receiveMessage(event) {
            const receivedData = event.data;

            log.log(receivedData, event, receivedData?.relevantPaymentRecord?.user_is_registered);
            if (receivedData?.event === "redirected") {
                if (receivedData?.code === "F") {
                    setTimeout(() => window.location.reload(), 1000)
                } else if (receivedData?.code === "S") {
                    if (receivedData?.relevantPaymentRecord) {
                        let user_is_registered = receivedData?.relevantPaymentRecord?.user_is_registered;
                        let payment = receivedData?.relevantPaymentRecord;
                        log.log(payment, payment?.order?.id, payment?.order_data?.id, payment?.id);
                        // if (user_is_registered === true) {
                        //     window.location.href = window.location.origin + "/account/orders/" + (payment?.order?.id || payment?.order_data?.id);
                        // } else {
                        //     window.location.href = window.location.origin + "/anonymous-order/" + payment?.id;
                        // }
                        window.location.href = window.location.origin + "/anonymous-order/" + payment?.id;
                    } else {
                        window.location.href = window.location.origin + "/account/orders";
                    }
                }
            } else if (receivedData?.event === "stop_timer") {
                // clearInterval(timerRef.current);
                // setPaymentTimeLeft(0);
                setShowPaymentTimeLeft(false);
            }
        }
        window.addEventListener('message', receiveMessage, false);

        return () => {
            window.removeEventListener('message', receiveMessage);
        }

    }, []);

    const buttonRef = useRef(null);

    useEffect(() => {
        if (buttonRef.current && mytPaymentInfo) {
            buttonRef.current.click();
            setLoading(STATUSES.LOADING_COMPLETED);
        }
    }, [buttonRef, mytPaymentInfo]);

    useEffect(() => {
        const iframe = document.getElementById('my_iframe');

        const onLoad = (e) => {
            setIframeLoading(false);
            // log.log(e)
        };

        iframe?.addEventListener?.('load', onLoad);

        return () => {
            iframe?.removeEventListener?.('load', onLoad);
        };
    }, [])

    return (
        <>
            <Flex gap={20} vertical justify='between'>
                <Flex flex={1} vertical>
                    {
                        mytPaymentInfo?.myt_data ?
                            <form
                                action={url.current}
                                method="post"
                                acceptCharset="application/json"
                                id="paymentform"
                                target='my_iframe'
                                style={{ width: "100%" }}
                            >
                                <input type='hidden' style={{ flex: 1 }} name="appId" value={mytPaymentInfo?.myt_data?.appId} />
                                <input type='hidden' style={{ flex: 1 }} name="merTradeNo" value={mytPaymentInfo?.myt_data?.merTradeNo} />
                                <input type='hidden' style={{ flex: 1 }} name="paymentType" value={mytPaymentInfo?.myt_data?.paymentType} />
                                <input type='hidden' style={{ flex: 1 }} name="payload" value={mytPaymentInfo?.myt_data?.payload} />
                                <input type='hidden' style={{ flex: 1 }} name="sign" value={mytPaymentInfo?.myt_data?.sign} />

                                <input
                                    ref={buttonRef}
                                    type="submit"
                                    value={!loading ? "processing..." : "Please pay from the following QR"}
                                    style={{ background: "transparent", border: "none", color: "white", width: 350 }}
                                    disabled={!loading ? false : true}
                                />
                            </form>
                            :
                            <>
                                {/* <pre style={{ width: "100%", whiteSpace: "break-spaces", backgroundColor: "white" }} lang='JSON'>
                                    {JSON.stringify(mytPaymentInfo)}
                                </pre> */}
                            </>
                    }
                </Flex>
                <Flex vertical style={{ width: 350, backgroundColor: "white" }}>
                    {
                        iframeLoading && <Flex flex={1} style={{ width: 350, minHeight: "10vh" }} justify='center' align='center'>
                            <Spinner />
                        </Flex>
                    }
                    <iframe
                        ref={iframeRef}
                        title='MyT Payment'
                        name="my_iframe"
                        id='my_iframe'
                        style={{
                            width: "100%",
                            height: 500,
                            display: 'block'
                        }}>
                    </iframe>
                </Flex>
            </Flex>
        </>
    )
}

const Flex = ({ children, gap, flex, justify = "start", align = "start", style = {}, vertical = false }) => {

    return <div className={`d-flex justify-content-${justify} align-items-${align} ${vertical ? 'flex-column' : ''}`} style={{
        gap: isNaN(gap) ? 10 : gap,
        flex: isNaN(flex) ? 0 : flex,
        ...style
    }}>
        {children}
    </div>
}

export default MyTPaymentMethod