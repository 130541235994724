import { notify } from 'Utilities/Notifications/Notify';
import axios from 'axios';
import log from 'loglevel';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, InputGroup, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';

const FLAGCDN_BASE_URL = "https://flagcdn.com/20x15/";
const DEFAULT_PHONE_CODE = "230";

const CountryCodeFormField = ({ initialMobileCodeValue, initialMobileValue, setPhone, setPhoneCode }) => {

    const [countryCodes, setCountryCodes] = useState([]);
    const [countryCodesLoading, setCountryCodesLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedCode, setSelectedCode] = useState('');
    const [mobile, setMobile] = useState("");

    const handleSelect = useCallback((e) => {
        log.log(e)
        const selected = countryCodes.find(country => country.phone_code === e);
        setSelectedCountry(selected);
        setSelectedCode(e);
    }, [setSelectedCountry, setSelectedCode, countryCodes]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                setCountryCodesLoading(true);
                const response = await axios.get("/master/countries-list/");
                if (Array.isArray(response.data)) {
                    setCountryCodes(response.data);
                    // setSelectedCountry(response.data[0]);
                    // setSelectedCode(response.data[0].phone_code);
                }
            } catch (err) {
                log.error(err);
                notify("Couldn't load country codes!", "error");
            } finally {
                setCountryCodesLoading(false);
            }
        }

        fetchCountries();

    }, []);

    useEffect(() => {
        if (initialMobileCodeValue) {
            handleSelect(initialMobileCodeValue);

            if (typeof setPhoneCode === "function") {
                setPhoneCode(initialMobileCodeValue);
            }
        } else {
            handleSelect(DEFAULT_PHONE_CODE);
            if (typeof setPhoneCode === "function") {
                setPhoneCode(DEFAULT_PHONE_CODE);
            }
        }

        if (initialMobileValue) {
            setMobile(initialMobileValue);
            if (typeof setPhone === "function") {
                setPhone(initialMobileValue);
            }
        } else {
            setMobile("");
            if (typeof setPhone === "function") {
                setPhone("");
            }
        }

    }, [initialMobileCodeValue, initialMobileValue, handleSelect, setPhone, setPhoneCode]);

    return (
        <Form.Group className="mb-3">
            <Form.Label className="text-feather">Mobile</Form.Label>
            <InputGroup className='gap-2'>
                <Dropdown as={InputGroup.Prepend} onSelect={handleSelect}>
                    <Dropdown.Toggle
                        variant="outline-secondary"
                        id="input-group-dropdown-1"
                    >
                        {selectedCountry?.iso_country_code ? (
                            <>
                                {/* <img
                                    src={`${FLAGCDN_BASE_URL}${selectedCountry?.iso_country_code.toLowerCase()}.png`}
                                    alt={selectedCountry.title}
                                    style={{ width: '20px', height: '15px', marginRight: '8px' }}
                                /> */}
                                {/* <span>{selectedCountry.title}</span> */}
                                <span>(+{selectedCountry.phone_code})</span>
                            </>
                        ) : countryCodesLoading ? <Spinner size='sm' /> : "Select country"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: '30vh', overflowY: 'auto', backgroundColor: '#fff' }}>
                        {countryCodes.map(country => (
                            <Dropdown.Item key={country?.iso_country_code} eventKey={country.phone_code} >
                                <img
                                    src={`${FLAGCDN_BASE_URL}${country?.iso_country_code.toLowerCase()}.png`}
                                    alt={country.title}
                                    style={{ width: '20px', height: '15px', marginRight: '8px' }}
                                />
                                <span>{country.title}</span>
                                <span>(+{country.phone_code})</span>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <Form.Control
                    type="mobile"
                    name="mobile"
                    id="mobile"
                    minLength={7}
                    // maxLength={10}
                    placeholder="Phone Number"
                    style={{ flex: 3 }}
                    value={mobile}
                    onChange={({ target: { value } }) => {
                        if (isNaN(value)) {
                            notify('Phone number can only contain numbers');
                            return;
                        };
                        setMobile(value);
                        if (typeof setPhone === "function") {
                            setPhone(value);
                        }
                    }}
                    className="bg-extradark text-lightgrey border-grey"
                />
                <Form.Control
                    type="hidden"
                    name="mobile_code"
                    id="mobile_code"
                    value={selectedCode}
                    style={{ flex: 3 }}
                    className="bg-extradark text-lightgrey border-grey"
                />
            </InputGroup>
        </Form.Group>
    );
}

export default CountryCodeFormField;
