import React from 'react'
import { Button, Col, Image, Modal, Spinner } from 'react-bootstrap';
import ShowDetailsVertical from 'website/components/Movie/ShowDetailsVertical';
import {
    IoMdAdd,
    IoMdRemove,
} from "react-icons/io";
import SeatDesign from './SeatDesign';
import { auto, bike, bus, bycycle, car, hatchback, minivan, screen } from 'assets';
import SeatSvg20 from './SeatSvg20';
import { notify } from 'Utilities/Notifications/Notify';
import log from 'loglevel';
import ReactGA from 'react-ga4';

const indexWiseImages = [
    bycycle,
    bike,
    auto,
    hatchback,
    car,
    minivan,
    minivan,
    bus,
    bus,
    bus,
];

const TicketMode = ({
    movieData,
    showData,
    thisTheatre,
    small,
    medium,
    large,
    totalSeatsSelected,
    bookedSeats,
    setBookedSeats,
    setTotalSeatsSelected,
    maxSeatSelectionAllowed,
    seatContainerRef,
    hallSeats,
    seatTypes,
    ticketVariety,
    totalPrice,
    seatLockingInProgress,
    lockSeats,
    setTimeLeft,
    seatNumberModal,
    setSeatNumberModal
}) => {
    
    return (
        <>
            <div className={`px-4 booking-wrapper ${medium ? "row mx-xs-0" : ""}`}>
                <Col md={2}>
                    <ShowDetailsVertical
                        title={movieData?.title}
                        image={movieData?.poster_image}
                        language={showData?.language_data}
                        theatre={thisTheatre}
                        showDate={showData?.date}
                        startTime={showData?.start_time}
                        formatType={showData?.format_type_data?.title}
                        screen={showData?.hall_data?.hall_no}
                    />
                </Col>
                <Col md={7} className="my-4 p-0">
                    {!medium && (
                        <div
                            className={`text-white d-flex justify-content-between align-items-center mb-4 ${large ? "me-5" : ""
                                }`}
                        >
                            <p className="m-0">No. of seats</p>
                            <div>
                                <Button
                                    className="rounded-circle"
                                    variant="light"
                                    disabled={totalSeatsSelected <= 1}
                                    style={{
                                        height: 27, width: 27, padding: 0
                                    }}
                                    onClick={() => {
                                        if (totalSeatsSelected - 1 < bookedSeats.length) {
                                            let array = [...bookedSeats];
                                            array.pop();
                                            log.log(array);
                                            setBookedSeats(array);
                                        }
                                        setTotalSeatsSelected(totalSeatsSelected - 1);
                                    }}
                                >
                                    <IoMdRemove />
                                </Button>
                                <span className="px-2">{totalSeatsSelected}</span>
                                <Button
                                    className="rounded-circle"
                                    variant="light"
                                    style={{
                                        height: 27, width: 27, padding: 0
                                    }}
                                    disabled={totalSeatsSelected >= maxSeatSelectionAllowed}
                                    onClick={() =>
                                        setTotalSeatsSelected(totalSeatsSelected + 1)
                                    }
                                >
                                    <IoMdAdd />
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className="p-0 m-0">
                        <div
                            ref={seatContainerRef}
                            className={`d-flex ${medium ? "justify-content-center" : "overflow-scroll"
                                }`}
                            style={{ minHeight: "50vh" }}
                        >
                            {hallSeats?.length > 0 ? (
                                <div className={`d-flex flex-column`}>
                                    {hallSeats?.map?.((seats, row) => {
                                        return (
                                            <div
                                                key={row}
                                                className={`d-flex ${large ? "justify-content-center" : ""
                                                    }`}
                                            >
                                                {seats.map((seat, column) => {
                                                    return (
                                                        <SeatDesign
                                                            key={column}
                                                            row={row}
                                                            column={column}
                                                            seat={seat}
                                                            allSeats={hallSeats}
                                                            totalSeatsSelected={totalSeatsSelected}
                                                            defaultColor={`${seat?.seatType_data?.color}75`}
                                                            color={seat?.seatType_data?.color}
                                                            bookedSeats={bookedSeats}
                                                            setBookedSeats={setBookedSeats}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                    <div
                                        className={`my-5 ${medium
                                            ? "d-flex justify-content-center"
                                            : "d-flex justify-content-center"
                                            }`}
                                    >
                                        <Image src={screen} />
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center">
                                    <Spinner className="text-white" />
                                </div>
                            )}
                        </div>
                        <div
                            // className={`d-flex justify-content-center align-items-center ${small ? "" : "row row-cols-2"}`}
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 8,
                                justifyContent: "center",
                            }}
                        >
                            {seatTypes.map((scheme, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`${small ? "mx-2" : ""
                                            } d-flex justify-content-center align-items-center`}
                                    >
                                        <SeatSvg20 color={scheme.color} />
                                        <div className="mx-2 text-white">{scheme.title}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="mt-4 text-white">
                        {medium && (
                            <div
                                className={`d-flex justify-content-between align-items-center mb-4 ${large ? "me-5" : ""
                                    }`}
                            >
                                <p className="m-0">No. of Seats</p>
                                <div>
                                    <Button
                                        className="rounded-circle"
                                        variant="light"
                                        disabled={totalSeatsSelected <= 1}
                                        style={{
                                            height: 27, width: 27, padding: 0
                                        }}
                                        onClick={() => {
                                            if (totalSeatsSelected - 1 < bookedSeats.length) {
                                                let array = [...bookedSeats];
                                                array.pop();
                                                log.log(array);
                                                setBookedSeats(array);
                                            }
                                            setTotalSeatsSelected(totalSeatsSelected - 1);
                                        }}
                                    >
                                        <IoMdRemove />
                                    </Button>
                                    <span className="px-2">{totalSeatsSelected}</span>
                                    <Button
                                        className="rounded-circle"
                                        variant="light"
                                        style={{
                                            height: 27, width: 27, padding: 0
                                        }}
                                        disabled={totalSeatsSelected >= maxSeatSelectionAllowed}
                                        onClick={() =>
                                            setTotalSeatsSelected(totalSeatsSelected + 1)
                                        }
                                    >
                                        <IoMdAdd />
                                    </Button>
                                </div>
                            </div>
                        )}
                        <div className={`${large ? "me-5" : ""}`}>
                            {ticketVariety?.map((variety, index) => {
                                return (
                                    <div key={index} className="d-flex flex-column flex-md-row flex-md-start justify-content-md-start justify-content-center align-items-md-start align-items-center gap-2">
                                        {small ? <SeatSvg20 color={variety.color} /> : <></>}
                                        <div className="d-flex flex-column text-white ms-2">
                                            <span className="text-md-start text-center">
                                                {small ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        <SeatSvg20 color={variety.color} /> &nbsp;
                                                    </>
                                                )}
                                                {variety.quantity} &nbsp;{variety.type} &nbsp;Ticket
                                                {variety.quantity > 1 ? "s" : ""}
                                            </span>
                                            {/* <span className="text-white">&nbsp;{variety.quantity} &nbsp;{variety.type}  &nbsp;Ticket{variety.quantity > 1 ? "s" : ""}{index === ticketVariety.length - 2 ? " & " : (index === ticketVariety.length - 1 ? "" : ", ")}</span> */}
                                            <span>({variety.displayLabels})</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <hr
                            className={`text-center ${large ? "text-white me-5" : ""}`}
                        />
                        <h4 className="text-center text-sm-start mb-4">
                            MUR {totalPrice}
                        </h4>
                        <div
                            className={`d-flex align-items-center ${large ? "" : "justify-content-center mb-5"
                                }`}
                        >
                            <Button
                                className="bg-primary primary-button border border-primary px-4 me-0 me-sm-3"
                                disabled={seatLockingInProgress}
                                onClick={() => {
                                    if (bookedSeats.length > 0) {
                                        lockSeats();
                                        setTimeLeft(5 * 60);
                                        ReactGA.event({
                                            category: "booking_flow",
                                            action: "next_seat_selection",
                                            label: "NEXT SEAT SELECTION", // optional
                                        });
                                    } else {
                                        notify("Please select seats", "error");
                                    }
                                }}
                            >
                                {seatLockingInProgress ? (
                                    <div className="d-flex gap-3 align-items-center">
                                        <Spinner size="sm" />
                                        <span>Processing ...</span>
                                    </div>
                                ) : (
                                    "Book Ticket"
                                )}
                            </Button>
                        </div>
                    </div>
                </Col>
            </div>
            <Modal
                dialogClassName="d-flex align-items-center h-100"
                contentClassName="bg-slate border-0"
                show={seatNumberModal}
            >
                <Modal.Header
                    closeButton={false}
                    className="d-flex border-0 d-flex justify-content-center align-items-center"
                >
                    <Modal.Title className="text-white">How many?</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-3 d-flex flex-column justify-content-center align-items-center">
                    <Image
                        className="my-5"
                        src={indexWiseImages[totalSeatsSelected - 1]}
                        style={{ height: 100, width: "auto" }}
                    />
                    <div className="d-flex justify-content-between w-100">
                        {indexWiseImages.map((maxS, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`d-flex justify-content-center align-items-center mt-2 cursor-pointer ${totalSeatsSelected === index + 1
                                        ? "text-black"
                                        : "text-white"
                                        }`}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        backgroundColor:
                                            totalSeatsSelected === index + 1
                                                ? "#ffffff"
                                                : "#13151f",
                                        borderRadius: 15,
                                    }}
                                    onClick={() => setTotalSeatsSelected(index + 1)}
                                >
                                    {index + 1}
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        className="bg-primary primary-button border border-primary px-4 mt-5 mb-3"
                        onClick={() => {
                            setSeatNumberModal(false);
                        }}
                    >
                        Select Seats
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TicketMode