import log from "loglevel";

export const getCorrectImageUrl = (img) => {
    let image = img;

    if (!image) return "";

    let no_http_image = image.replace("http://", "");
    no_http_image = image.replace("https://", "");

    let no_http_base_url = process.env.REACT_APP_BASE_URL?.replace("http://", "");
    no_http_base_url = no_http_base_url?.replace("https://", "");
    no_http_base_url = no_http_base_url?.replace("/api", "");

    if (img?.includes?.('http') || img.includes?.('https')) {
        if (no_http_image?.includes?.(no_http_base_url)) {
            image = img;
        } else {
            image = `${process.env.REACT_APP_BASE_URL}${img}`;
        }
    } else {
        image = `${process.env.REACT_APP_BASE_URL}${img}`;
    }
    // log.log({ image });
    return image;
}

export const onInputTextShouldBeLowerCase = (event) => {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) { return; }
    Object.assign(target, { value: target.value.toLowerCase() });
}