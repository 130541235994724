import { notify } from 'Utilities/Notifications/Notify';
import { correct_animation, failed_animation } from 'assets';
import axios from 'axios';
import log from 'loglevel';
import React, { useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';

const ConfirmPayment = () => {

    const params = useParams();
    const { payment_id, theatre_id } = params;

    const [orderManagementLoading, setOrderManagementLoading] = useState(false);
    const [orderManagementResponse, setOrderManagementResponse] = useState(null);


    useEffect(() => {
        const orderManagementApi = async () => {
            try {
                setOrderManagementLoading(true);
                const url = "/order/rest/payments/verify_payment_status/";
                const payload = {
                    "payment_id": payment_id,
                    "theatre_id": theatre_id
                }
                const response = await axios.post(url, payload);
                log.log({ response });
                setOrderManagementResponse(response.data);
                setOrderManagementLoading(false);
            } catch (e) {
                notify(e.message, "error");
                setOrderManagementLoading(false);
                setOrderManagementResponse(null);
            }
        }
        orderManagementApi();
    }, [payment_id])

    return (
        <div
            className='d-flex justify-content-center align-items-center'
            style={{
                minHeight: "60vh"
            }}
        >
            {
                orderManagementLoading ? <Spinner /> : <>
                    {
                        orderManagementResponse &&
                            orderManagementResponse.order_status &&
                            orderManagementResponse?.order_status === "SUCCESS" ?
                            <TicketStatus success />
                            : orderManagementResponse &&
                                orderManagementResponse.order_status &&
                                orderManagementResponse?.order_status === "FAILED" ? <TicketStatus failed /> : <TicketStatus apiError />
                    }
                </>
            }
        </div>
    )
}

const TicketStatus = ({ success, failed, apiError }) => {

    if (success || failed) {
        return (
            <>
                {
                    success ? <img src={correct_animation} alt='Ticket Booked Animation' /> : failed ? <img src={failed_animation} alt='Ticket Booking Failed Animation' /> : <></>
                }
                <h3>Ticket {success ? "Booked" : failed ? "Booking Failed" : ""}</h3>
            </>
        )
    }

    return (
        <>
            <h3>Failed to check the status, please try again</h3>
        </>
    )

}

export default ConfirmPayment