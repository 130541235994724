
const SeatSvg20 = ({ color }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                // d="M7.63636 42C7.09545 42 6.64236 41.776 6.27709 41.328C5.91182 40.88 5.72854 40.3262 5.72727 39.6667V37.3333C4.13636 37.3333 2.78409 36.6528 1.67045 35.2917C0.556818 33.9305 0 32.2778 0 30.3333V18.6667C0 17.3833 0.374182 16.2851 1.12255 15.372C1.87091 14.4589 2.76945 14.0016 3.81818 14C4.86818 14 5.76736 14.4573 6.51573 15.372C7.26409 16.2867 7.63764 17.3849 7.63636 18.6667V28H34.3636V18.6667C34.3636 17.3833 34.7378 16.2851 35.4862 15.372C36.2345 14.4589 37.1331 14.0016 38.1818 14C39.2318 14 40.131 14.4573 40.8794 15.372C41.6277 16.2867 42.0013 17.3849 42 18.6667V30.3333C42 32.2778 41.4432 33.9305 40.3295 35.2917C39.2159 36.6528 37.8636 37.3333 36.2727 37.3333V39.6667C36.2727 40.3278 36.0895 40.8823 35.7229 41.3303C35.3564 41.7783 34.9033 42.0015 34.3636 42C33.8227 42 33.3696 41.776 33.0044 41.328C32.6391 40.88 32.4558 40.3262 32.4545 39.6667V37.3333H9.54545V39.6667C9.54545 40.3278 9.36218 40.8823 8.99564 41.3303C8.62909 41.7783 8.176 42.0015 7.63636 42ZM11.4545 23.3333V18.6667C11.4545 16.5278 10.9219 14.6121 9.85664 12.9197C8.79136 11.2272 7.41491 10.0318 5.72727 9.33333V7C5.72727 5.05555 6.28409 3.40278 7.39773 2.04167C8.51136 0.680555 9.86364 0 11.4545 0H30.5455C32.1364 0 33.4886 0.680555 34.6023 2.04167C35.7159 3.40278 36.2727 5.05555 36.2727 7V9.33333C34.5545 9.87778 33.1705 11.0157 32.1205 12.747C31.0705 14.4783 30.5455 16.4516 30.5455 18.6667V23.3333H11.4545Z"
                d="M3.63636 20C3.37879 20 3.16303 19.8933 2.98909 19.68C2.81515 19.4667 2.72788 19.203 2.72727 18.8889V17.7778C1.9697 17.7778 1.32576 17.4537 0.795454 16.8056C0.265152 16.1574 0 15.3704 0 14.4444V8.88889C0 8.27778 0.178182 7.75481 0.534545 7.32C0.890909 6.88518 1.31879 6.66741 1.81818 6.66667C2.31818 6.66667 2.74636 6.88444 3.10273 7.32C3.45909 7.75555 3.63697 8.27852 3.63636 8.88889V13.3333H16.3636V8.88889C16.3636 8.27778 16.5418 7.75481 16.8982 7.32C17.2545 6.88518 17.6824 6.66741 18.1818 6.66667C18.6818 6.66667 19.11 6.88444 19.4664 7.32C19.8227 7.75555 20.0006 8.27852 20 8.88889V14.4444C20 15.3704 19.7348 16.1574 19.2045 16.8056C18.6742 17.4537 18.0303 17.7778 17.2727 17.7778V18.8889C17.2727 19.2037 17.1855 19.4678 17.0109 19.6811C16.8364 19.8944 16.6206 20.0007 16.3636 20C16.1061 20 15.8903 19.8933 15.7164 19.68C15.5424 19.4667 15.4551 19.203 15.4545 18.8889V17.7778H4.54545V18.8889C4.54545 19.2037 4.45818 19.4678 4.28364 19.6811C4.10909 19.8944 3.89333 20.0007 3.63636 20ZM5.45454 11.1111V8.88889C5.45454 7.87037 5.20091 6.95815 4.69364 6.15222C4.18636 5.3463 3.53091 4.77704 2.72727 4.44444V3.33333C2.72727 2.40741 2.99242 1.62037 3.52273 0.972222C4.05303 0.324074 4.69697 0 5.45454 0H14.5455C15.303 0 15.947 0.324074 16.4773 0.972222C17.0076 1.62037 17.2727 2.40741 17.2727 3.33333V4.44444C16.4545 4.7037 15.7955 5.24556 15.2955 6.07C14.7955 6.89444 14.5455 7.83407 14.5455 8.88889V11.1111H5.45454Z"
                fill={color}
            />
        </svg>
    );
};

export default SeatSvg20;