import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import * as authActions from "Redux/Actions/authActions";
import { useMediaQuery } from "react-responsive";
import { useLocation, useParams } from "react-router";
import OrderCard from "./Account/OrderCard";
import FoodOrderCard from "./Account/FoodOrderCard";
import axios from "axios";
import { notify } from "Utilities/Notifications/Notify";
import { nothing_found } from "assets";
import log from "loglevel";
import { Helmet } from "react-helmet";
import queryString from 'query-string';

const RETRY_INTERVAL = 10; //seconds


const encodeUUIDs = (uuid1, uuid2) => {
    // Remove hyphens
    const uuid1Hex = uuid1.replace(/-/g, '');
    const uuid2Hex = uuid2.replace(/-/g, '');

    // Combine the hex strings
    const combinedHex = uuid1Hex + uuid2Hex;

    // Convert hex to binary
    const binaryData = new Uint8Array(combinedHex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

    // Encode using Base64
    const base64Encoded = btoa(String.fromCharCode.apply(null, binaryData));

    return base64Encoded;
}


const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Failed to load script ${src}`));
        document.head.appendChild(script);
    });
};

const AnonymousOrderView = () => {

    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const maxRetries = useRef(5);

    const { paymentId } = useParams();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    let isForMyTMiniApp = false;
    if ((location.state?.myTHeaders && location?.state?.showId && paymentId && location?.state?.order) || queryParams.show_wait === 'true') {
        isForMyTMiniApp = true;
    }
    const [ticketOrder, setTicketOrder] = useState(null);
    const [ticketOrderLoading, setTicketOrderLoading] = useState(true);
    const [orderManagementLoading, setOrderManagementLoading] = useState(false);


    useEffect(() => {
        const orderManagementApi = async (order) => {
            try {
                setOrderManagementLoading(true);
                const url = "/order/rest/payments/verify_payment_status/";
                const payload = {
                    "order_id": order?.order_data?.id,
                    "payment_id": paymentId,
                    "theatre_id": order?.order_data?.theatre_data?.id
                }
                const response = await axios.post(url, payload);
                log.log({ response });
                if (response?.data?.updated_data) {
                    // const updated_order_data = Object.assign(order, response.data.updated_data);
                    const updated_order_data = { ...order, ...response.data.updated_data }
                    updated_order_data['order']['seats'] = updated_order_data['order_data']['seats'];
                    updated_order_data['order_data'] = { ...updated_order_data['order_data'], ...updated_order_data['order'] };

                    log.log({ updated_order_data });
                    await fetchOrder(updated_order_data?.id);
                    setTicketOrder(updated_order_data);
                }
                setOrderManagementLoading(false);
            } catch (e) {
                notify(e.message, "error");
                console.error(e)
                setOrderManagementLoading(false);
            } finally {
                maxRetries.current = maxRetries.current - 1;
            }
        }

        const fetchOrder = async (payment_id) => {
            setTicketOrderLoading(true);
            const response = await authActions.getAnonymousTicketOrder(payment_id);
            log.log({ response });

            if (response) {
                if (response?.payment_gateway === "MIPS" && response?.payment_status === "PENDING" && maxRetries.current >= 0) await orderManagementApi(response);
                setTicketOrder(response);
            }
            setTicketOrderLoading(false);
        }

        fetchOrder(paymentId);

    }, [paymentId, location?.state?.myTHeaders]);


    if (isForMyTMiniApp) return <MyTMiniApp
        medium={medium}
        ticketOrder={ticketOrder}
        setTicketOrder={setTicketOrder}
        ticketOrderLoading={ticketOrderLoading}
        setTicketOrderLoading={setTicketOrderLoading}
        orderManagementLoading={orderManagementLoading}
    />
    return <NotMyTMiniApp
        medium={medium}
        ticketOrder={ticketOrder}
        ticketOrderLoading={ticketOrderLoading}
        orderManagementLoading={orderManagementLoading}
    />
};


const MyTMiniApp = ({ ticketOrder, setTicketOrder, ticketOrderLoading, setTicketOrderLoading, medium }) => {

    const location = useLocation();
    const retryIntervalRef = useRef(null);
    const { paymentId } = useParams();

    const [logs, setLogs] = useState("");
    const [ticketPaymentPending, setTicketPaymentPending] = useState(false);
    const [timeLeftToRetry, setTimeLeftToRetry] = useState(RETRY_INTERVAL);

    const setRetryTimer = () => {
        // if (retryIntervalRef.current) {
        //     clearInterval(retryIntervalRef.current);
        // }
        clearInterval(retryIntervalRef.current);
        retryIntervalRef.current = setInterval(() => {
            setTimeLeftToRetry(o => {
                const newValue = (isNaN(o) || o <= 0) ? RETRY_INTERVAL - 1 : o - 1;
                // log.log({ newValue, r: (isNaN(o) || o <= 0) });
                return newValue;
            });
        }, 1000);
    }


    useEffect(() => {
        const payment_status = ticketOrder?.order_data?.payments?.[0]?.payment_status;
        // log.log({ payment_status })
        if (payment_status && payment_status === "PENDING") {
            setTicketPaymentPending(true);
            setRetryTimer();
        } else {
            setTicketPaymentPending(false);
        }
    }, [ticketOrder]);


    useEffect(() => {
        if (ticketPaymentPending) {
            if (timeLeftToRetry === 0) {
                clearInterval(retryIntervalRef.current);
                setTimeLeftToRetry(-1);
                const fetchOrder = async (payment_id) => {
                    setTicketOrderLoading(true);
                    const response = await authActions.getAnonymousTicketOrder(payment_id);
                    // log.log({ response });
                    if (response) {
                        setTicketOrder(response);
                    }
                    setTicketOrderLoading(false);
                }

                fetchOrder(paymentId);
            }
        }
    }, [ticketPaymentPending, paymentId, setTicketOrder, setTicketOrderLoading, timeLeftToRetry]);


    useEffect(() => {

        const initializePayment = async (myt_headers, show_id, payment_id, order) => {
            // let merchantTradeNo = "merTest" + Date.now();
            // setLogs(payment_id);
            let merchantTradeNo = `${encodeUUIDs(payment_id, show_id)}`;
            // setPaymentMethod(PAYMENT_METHOD.MYT);
            log.log(merchantTradeNo);
            // setLogs(logs + merchantTradeNo.toString());
            try {
                // const response = await axios.get(
                //   `${window.location.origin}/get-request-headers`
                // ); // Adjust the endpoint as necessary
                // let headers = response.data;
                // log.log(headers);
                // setMyTHeaders(headers);
                try {
                    // Access reLogin function from the global window object
                    const paymentToken = await window.reLogin(
                        myt_headers.AuthToken,
                        myt_headers["M-App-Id"],
                        myt_headers["X-Userid"],
                        myt_headers["Customeruserid"],
                        myt_headers["Channel"],
                        `${process.env.REACT_APP_MYT_PARTNER_APP_MERCHANT_KEY}`
                    );
                    window.doPayment(
                        // 1,
                        order?.payments?.[0]?.total,
                        "remarks",
                        merchantTradeNo,
                        myt_headers["X-Userid"],
                        myt_headers["M-App-Id"],
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        paymentToken,
                        `${process.env.REACT_APP_BASE_URL_HOST}/api/payment/myt/notifyurlcallback/`
                    );
                } catch (err) {
                    console.error(err);
                    setLogs(l => l + err.toString());
                }
            } catch (error) {
                console.error("Error fetching headers:", error);
                setLogs(l => l + error.toString());
            }
        }

        const initializeMiniApp = async (myt_headers, show_id, payment_id, order) => {
            try {
                await loadScript("https://miniapp.mytmoney.mu/mini-app/v1/miniappcore.min.js");
                log.log('Script loaded successfully.');
                await initializePayment(myt_headers, show_id, payment_id, order);
            } catch (error) {
                notify('Error loading miniappcore script', "error");
                console.error('Error loading miniappcore script:', error);
            }
        };

        if (location.state?.myTHeaders && location?.state?.showId && paymentId && location?.state?.order) {
            if (ticketPaymentPending) {
                initializeMiniApp(location.state.myTHeaders, location.state.showId, paymentId, location?.state?.order);
            }
        }
    }, [location.state, paymentId, ticketPaymentPending]);


    return <div>
        <Card
            className={`d-flex flex-column justify-content-center align-items-center ${medium ? "py-4 px-3" : "px-1"}`}
            style={{
                backgroundColor: "rgb(19 21 31)",
                borderRadius: "0px",
            }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{
                    maxWidth: "1140px"
                }}
            >
                {
                    (ticketOrderLoading || ticketPaymentPending) ?
                        <div style={{ height: "50vh" }} className="d-flex flex-column justify-content-center align-items-center gap-3">
                            <div>
                                <h3 className="text-white text-center">Please Wait</h3>
                                <p className="text-center" style={{ color: "red" }}>Do not close or reload this window!</p>
                            </div>
                            <Spinner style={{ color: "white" }} size="sm" />
                            <p className="text-white text-center">
                                please complete your payment on the pop up window,
                                this page will automatically fetch your latest status.
                            </p>

                            {
                                timeLeftToRetry !== 0 ? <>
                                    <p className="text-center text-white">Retrying in {timeLeftToRetry < 0 ? 0 : timeLeftToRetry} seconds.</p>
                                    <Button disabled={ticketOrderLoading} onClick={()=>setTimeLeftToRetry(0)} style={{backgroundColor: "red", borderColor: "red"}}>
                                        Confirm Payment
                                    </Button></> : <Button  style={{backgroundColor: "red", borderColor: "red"}}>Verifying...</Button>
                            }
                        </div> : <>
                            {
                                ticketOrder?.order_data && <div className="text-white w-100">
                                            <h3 className="text-center">Ticket Orders</h3>
                                    <OrderCard order={ticketOrder?.order_data} allow_image_navigate={false} />
                                </div>
                            }
                            {
                                ticketOrder?.food_order_data && <div className="text-white w-100">
                                            <h3 className="text-center">Food Orders</h3>
                                    <FoodOrderCard foodOrder={ticketOrder?.food_order_data} />
                                </div>
                            }
                            {
                                !ticketOrderLoading && !ticketOrder?.order_data && !ticketOrder?.food_order_data && <div style={{ height: "50vh" }} className="d-flex flex-column gap-2 justify-content-center align-items-center text-white text-center">
                                    <img width={150} src={nothing_found} alt="Not Found" />
                                    We're sorry, something went wrong!
                                </div>
                            }
                        </>
                }
            </div>
        </Card>
    </div>
}


const NotMyTMiniApp = ({ ticketOrder, ticketOrderLoading, orderManagementLoading, medium }) => {

    return (
        <div className="bg-slate pb-5">
            <Helmet>
                <title>MCine | Order Details</title>
            </Helmet>

            <div>
                <Card
                    className={`d-flex flex-column justify-content-center align-items-center ${medium ? "py-4 px-3" : "px-1"}`}
                    style={{
                        backgroundColor: "rgb(19 21 31)",
                        borderRadius: "0px",
                    }}
                >
                    <div
                        className="d-flex flex-column justify-content-center align-items-center w-100"
                        style={{
                            maxWidth: "1140px"
                        }}
                    >
                        {
                            (ticketOrderLoading || orderManagementLoading) ? <div style={{ height: "50vh" }} className="d-flex flex-column justify-content-center align-items-center">
                                <Spinner style={{ color: "white" }} size="sm" />
                            </div> :
                                <>
                                    {
                                        ticketOrder?.order_data && <div className="text-white w-100">
                                            <h3 className="text-center">Ticket Orders</h3>
                                            <OrderCard order={ticketOrder?.order_data} allow_image_navigate={false} />
                                        </div>
                                    }
                                    {
                                        ticketOrder?.food_order_data && <div className="text-white w-100">
                                            <h3 className="text-center">Food Receipts</h3>
                                            <FoodOrderCard foodOrder={ticketOrder?.food_order_data} />
                                        </div>
                                    }
                                    {
                                        !ticketOrderLoading && !ticketOrder?.order_data && !ticketOrder?.food_order_data && <div style={{ height: "50vh" }} className="d-flex flex-column gap-2 justify-content-center align-items-center text-white text-center">
                                            <img width={150} src={nothing_found} alt="Not Found" />
                                            We're sorry, but this page isn't accessible at the moment.
                                            <br />
                                            If you need assistance, please contact our support team or return to the homepage.
                                        </div>
                                    }
                                </>
                        }
                    </div>
                </Card>
            </div>
        </div>
    );

}

export default AnonymousOrderView;
