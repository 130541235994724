import { Button, Form, Spinner } from "react-bootstrap";
import React from "react";
import CountryCodeFormField from "./CountryCodeFormField";
import { Link } from 'react-router-dom';
import { onInputTextShouldBeLowerCase } from "utils";
import ReactGA from 'react-ga4';

const Asterisk = () => {
    return <span style={{ color: 'red' }}> *</span>
}

const SignupForm = ({ form, handleSignUp, checked, setChecked, isSubmitting, setShowForm }) => {

    return (
        <Form
            className="text-start w-100"
            ref={form}
            onSubmit={(e) => handleSignUp(e)}
        >
            <div className="d-flex mb-3">
                <Form.Group className="me-3" controlId="signUpForm.first_name">
                    <Form.Label className="text-white">First Name<Asterisk /></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="John"
                        name="first_name"
                        className="bg-extradark py-2 border-grey text-lightgrey"
                        required
                    />
                </Form.Group>
                <Form.Group className="" controlId="signUpForm.last_name">
                    <Form.Label className="text-white">Last Name<Asterisk /></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Doe"
                        name="last_name"
                        className="bg-extradark py-2 border-grey text-lightgrey"
                        required
                    />
                </Form.Group>
            </div>
            <Form.Group className="mb-3" controlId="signUpForm.email">
                <Form.Label className="text-white">Email address<Asterisk /></Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    className="bg-extradark py-2 border-grey text-lightgrey"
                    required
                    onInput={onInputTextShouldBeLowerCase}
                />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="mobile">
                <Form.Label className="text-feather">Mobile<Asterisk /></Form.Label>
                <Form.Control
                    type="mobile"
                    name="mobile"
                    minLength={10}
                    maxLength={10}
                    placeholder="9988989898"
                    className="bg-extradark text-lightgrey border-grey"
                    required
                />
            </Form.Group> */}
            <CountryCodeFormField />
            <div className="d-flex flex-column flex-md-row mb-3">
                <Form.Group className="me-md-3 mb-3 mb-md-0" controlId="signUpForm.password1">
                    <Form.Label className="text-white">Enter Password<Asterisk /></Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        className="bg-extradark py-2 border-grey text-lightgrey"
                        required
                    />
                </Form.Group>
                <Form.Group className="" controlId="signUpForm.password2">
                    <Form.Label className="text-white">Confirm Password<Asterisk /></Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password1"
                        className="bg-extradark py-2 border-grey text-lightgrey"
                        required
                    />
                </Form.Group>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <Form.Check
                    type="checkbox"
                    // label="Receive email notifications"
                    label={<>Agree to <Link to="/privacy-policy" className="text-lightgrey">Terms and Conditions</Link></>}
                    name="notifications"
                    className="text-lightgrey"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                />
            </div>
            <Button
                className="bg-primary border-primary w-100 custom-button my-3 py-2"
                type="submit"
                disabled={isSubmitting}
            >
                {isSubmitting ? (
                    <span>
                        <Spinner size="sm" className="me-2" />
                        SIGNING UP ...
                    </span>
                ) : (
                    <span className="poppins">SIGN UP</span>
                )}
            </Button>
            <div className="d-flex justify-content-center align-items-center">
                <div className="me-2 text-lightgrey">
                    Already have an account?
                </div>
                <div
                    onClick={() => {setShowForm("login");
                        
                        ReactGA.event({
                            category: "login_flow",
                            action: "go_signup_to_login",
                            label: "Going from Sign Up to Login", // optional
                        });
                    }}
                    className="cursor-pointer text-bold text-white"
                >
                    Login
                </div>
            </div>
        </Form>
    );
};

export default SignupForm;
