/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Col, Row, Spinner } from "react-bootstrap";
import { FaLocationDot, FaMap } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import ContactForm from "components/Contact/ContactForm";
import axios from "axios";
import log from "loglevel";
import { Helmet } from "react-helmet";

const IconContainer = ({ icon, link }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center bg-dark rounded-circle p-2 me-3 cursor-pointer"
      style={{ width: 36, height: 36 }}
    >
      <a href={link} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    </div>
  );
};

// const mcine_locations = [
//   {
//     id: 1,
//     location_name: "MCine Trianon",
//     src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3743.034020487533!2d57.48862437611436!3d-20.25742378120582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c5b16d2b40a15%3A0x6aa2b546cb92c9ee!2sMCine%20Trianon!5e0!3m2!1sen!2sin!4v1711015929496!5m2!1sen!2sin",
//   },
//   {
//     id: 2,
//     location_name: "MCine Curepipe",
//     src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3520.1247047591037!2d57.5222716538016!3d-20.31451309664155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c5c410cf22b75%3A0xaee07d6c47a487a1!2sMCine%2C%20Curepipe!5e0!3m2!1sen!2sin!4v1711016152696!5m2!1sen!2sin",
//   },
//   {
//     id: 3,
//     location_name: "MCine Flacq",
//     src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3744.782340000533!2d57.72049167611297!3d-20.18478618126159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217cf942b45a3283%3A0x3a4eedb2a4903b1b!2sMCine%20Flacq!5e0!3m2!1sen!2sin!4v1711016211431!5m2!1sen!2sin",
//   },
//   {
//     id: 4,
//     location_name: "MCine Waterfront",
//     src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3745.3483475645307!2d57.49687117611241!3d-20.161216481279784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c5161c96d1097%3A0xb1f21564531c11a7!2sMCine%20Port-Louis%20Waterfront!5e0!3m2!1sen!2sin!4v1711016390472!5m2!1sen!2sin",
//   },
// ]

// log.log({ mcine_locations, r: mcine_locations[0].src === mcine_locations[0].srd });

// const mcine_addresses = [
//   {
//     id: 1,
//     location_name: "MCine Trianon",
//     address: "La City - Trianon Shopping Park, Quatre Bornes",
//     email: "mcinetrianon@gmail.com",
//     telephone: "+230 4641900",
//   },
//   {
//     id: 2,
//     location_name: "MCine Curepipe",
//     address: "Swami Sivananda Ave, Curepipe",
//     email: "mcinecurepipe@gmail.com",
//     telephone: "+230 6761900",
//   },
//   {
//     id: 3,
//     location_name: "MCine Flacq",
//     address: "A7, Central Flacq",
//     email: "mcineflacq@gmail.com",
//     telephone: "+230 4131900",
//   },
//   {
//     id: 4,
//     location_name: "MCine Waterfront",
//     address: "Caudan Waterfront, Port Louis",
//     email: "mcineportlouis@gmail.com",
//     telephone: "+230 2101900",
//   },
// ]

const Contact = () => {

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });


  const [theatres, setTheatres] = useState(null);
  const [theatresLoading, setTheatresLoading] = useState(false);

  useEffect(() => {

    const fetchTheatres = async () => {
      setTheatresLoading(true);
      try {
        const res = await axios.get(`/theatre/contact?client=${process.env.REACT_APP_CLIENT_ID}`);
        if (res.data) {
          setTheatres(res.data);
        } else {
          setTheatres([]);
        }
      } catch (err) {
        log.error(err);
        setTheatres([]);
      } finally {
        setTheatresLoading(false);
      }
    }

    fetchTheatres();

  }, []);

  return (
    <>
      <Helmet>
        <title>MCine | Contact Us</title>
      </Helmet>
      <div>
        <div className="bg-slate pb-5">
          <div className="wrapper">
            <ul className="breadcrumb subtitle mx-1 pb-4">
              <li><a href="/">Home</a></li>
              <li>Contact</li>
            </ul>
            <Row className="m-0 mb-4">
              <Col xs={12} md={12} lg={6} className="mb-4 mb-lg-5 p-0">
                <div className="pe-4">
                  <div className="d-flex align-items-center text-lightgrey mb-4">
                    <FaLocationDot size={30} className="text-lightgrey me-3" />
                    <h3 className="text-capitalize text-lightgrey mb-0">
                      Where Are We?
                    </h3>
                  </div>
                  {
                    theatresLoading ? <Spinner size="sm" /> : <>
                      {
                        theatres?.length > 0 ? <>
                          {theatres?.map?.((mcine_address) => {
                            return (
                              <div className="mb-3">
                                <h5 className="text-primary m-0">{mcine_address.title}</h5>
                                <div className="text-lightgrey m-0">{mcine_address.address}</div>
                                <div className="text-lightgrey m-0">E-{mcine_address.support_email}</div>
                                <div className="text-lightgrey m-0">T-{mcine_address.support_mobile}</div>
                              </div>
                            )
                          })}
                        </> : <>
                          <small className="text-white">No Data Available</small>
                        </>
                      }
                    </>
                  }
                </div>
              </Col>
              <Col xs={12} md={12} lg={6} className="mb-4 mb-lg-5 p-0">
                <div className="d-flex align-items-center text-lightgrey mb-4">
                  <MdEmail size={30} className="text-lightgrey me-3" />
                  <h3 className="text-capitalize text-lightgrey mb-0">
                    Send us a message
                  </h3>
                </div>
                <ContactForm />
              </Col>
            </Row>
            <div className="pb-5">
              <div className="d-flex align-items-center text-lightgrey mb-4">
                <FaMap size={30} className="me-3" />
                <h3 className="text-capitalize">
                  Find Us on Google Maps
                </h3>
              </div>
              <Row xs={1} md={2} lg={4} className="">
                {
                  theatresLoading ? <Spinner size="sm" /> : <>
                    {
                      theatres?.filter?.(r => r.google_maps_embed_url)?.length > 0 ? <>
                        {theatres?.filter?.(r => r.google_maps_embed_url)?.map?.((mcine_location) => {
                          return (
                            <Col className="mb-3">
                              <iframe
                                className="bg-extradark border-0 mb-3 rounded"
                                // style={{ filter: "invert(80%) hue-rotate(180deg)" }}
                                src={mcine_location.google_maps_embed_url}
                                width="100%"
                                height="240"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              />
                              <p className="text-feather text-center text-uppercase">
                                {mcine_location.title}
                              </p>
                            </Col>
                          )
                        })}
                      </> : <>
                        <small className="text-white">No Data Available</small>
                      </>
                    }
                  </>
                }
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  parentContainer: {
    padding: "40px 100px",
  },
  mobileParentContainer: {
    padding: "20px 10px",
  },
}

export default Contact;
