export const PAYMENT_METHOD = {
    MIPS: "MIPS",
    MYT: "MYT",
};

// const BOOKED_SEAT_COLOR = "#505050";
export const BOOKED_SEAT_COLOR = "#333333";
export const LOCKED_SEAT_COLOR = "grey";

// const NO_EMPTY_SEAT_MESSAGE = "You cannot keep gap between seats or cannot leave aisle seat empty!"

export const NO_EMPTY_SEAT_MESSAGE = {
    heading: "Please Ensure No Gaps Between Seats or Empty Aisle Seats",
    description:
        "Select a seat without leaving a gap between occupied seats or leaving an aisle seat empty.",
};

export const displayTime = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const remainingSeconds = timeLeft % 60;
    return `0${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

export const CONNECTION_MESSAGE = "Please wait while we try to establish a connection with the server.";

export const UNIQUE_MAX_CONNECTION_MESSAGE = "Max retries exceeded."
export const MAX_RETRIES_CONNECTION_MESSAGE = "Sorry, we couldn't establish a connection to the server, please try reloading this page. " + UNIQUE_MAX_CONNECTION_MESSAGE;