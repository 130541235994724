import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation, Link } from "react-router-dom";
import "Webapp.scss";
import SiteFooter from "website/components/Footer/Footer";
import NavBar from "website/components/Navbar/Navbar";
import ReactGA from 'react-ga4';
import axios from "axios";
import { Helmet } from "react-helmet";
import log from "loglevel";
import CookieConsent from "react-cookie-consent";

const backend_url = process.env.REACT_APP_BASE_URL;

function getParams(url) {
  //consolelog(url);
  const params = url.split("/");
  return params.filter((item) => item !== "");
}

function isUUID(string) {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i;

  return uuidRegex.test(string);
}

const generateBreadCrumbConfig = async (paramArray) => {
  let potentialConfig = paramArray.filter(a => !!a);
  console.log(paramArray)
  potentialConfig = potentialConfig.map(a => ({ title: a }))
  console.log({ potentialConfig });
  const modifiedPotentialConfig = [];
  for (let index = 0; index < potentialConfig.length; index++) {
    const pc = potentialConfig[index];
    console.log({ pc, index })
    if (isUUID(pc.title)) {
      try {
        const data = {
          entity: potentialConfig[index - 1].title?.toLowerCase?.(),
          id: pc.title
        }
        console.log("Requesting bb for ", data);
        const response = await axios.post(`${backend_url}/utilities/bb/`, data);
        if (response.data?.title) {
          modifiedPotentialConfig.push(response.data);
        } else {
          throw new Error();
        }
      } catch (err) {
        modifiedPotentialConfig.push(pc);
      }
    } else {
      pc.title = pc.title.replaceAll("-", " ").replaceAll("_", " ");
      pc.title = pc.title.split(" ").map(t => t[0]?.toUpperCase?.() + t.slice(1)).join(" ");
      // pc.title = pc.title[0]?.toUpperCase?.() + pc.title.slice(1);
      modifiedPotentialConfig.push(pc);
    }
  }
  return modifiedPotentialConfig;
}

const WebsiteRoutingOutlet = () => {
  const location = useLocation();

  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}` });
  }, [location.key]);

  return (
    <>
      <NavBar />
      {/* <div className="d-flex flex-column"> */}
      {/* <div className="flex-grow-1" style={{ paddingTop: small ? 66 : 82 }}> */}
      <main style={{ width: "100vw" }}>
        <CookieConsent style={{ background: "#1b1e29" }} buttonStyle={{ background: "#f5382c", color: "#ffffff" }}>This website utilises cookies to improve user experience. By continuing to browse this site, you consent to our use of cookies as outlined in our  <Link to="/terms-of-use" className="text-lightgrey">Terms of Use</Link></CookieConsent>
        <Outlet />
      </main>
      {/* </div> */}
      <SiteFooter />
    </>
  );
};

export default WebsiteRoutingOutlet;
