import { auto, mipslogo, mytlogo } from "assets";
import { PAYMENT_METHOD } from "pages/Movie/SelectSeats/utils";
import React, { useEffect, useRef, useState } from "react";
import { Modal, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import * as authSlices from "Redux/AuthReducer/authSlices";
import { notify } from "Utilities/Notifications/Notify";
import MyTPaymentMethod from "./MyTPaymentMethod";
import axios from "axios";
import log from "loglevel";


const PaymentModal = ({ showPaymentModal, setShowPaymentModal, paymentTimeLeft, setPaymentTimeLeft, paymentMethod, setPaymentMethod, paymentId, fetchMipsPaymentDetails, paymentInfoLoading, iframeContent, fetchMytPaymentDetails, mytPaymentInfo, unlockSeats, showPaymentTimeLeft, setShowPaymentTimeLeft }) => {

    let interval = useRef(null);

    const [availablePaymentMethods, setAvailablePaymentMethods] = useState(null);

    useEffect(() => {
        const fetchPaymentMethodsAvailable = async () => {
            try {
                const response = await axios.post(`payment/available_methods/`, { client: process.env.REACT_APP_CLIENT_ID });
                const data = response.data;
                log.log({ data });
                if (data?.methods) {
                    if (Array.isArray(data.methods) && data.methods.length > 0) {
                        if (data.methods.length === 1) {
                            if (data.methods[0] === PAYMENT_METHOD.MIPS) {
                                setPaymentMethod(PAYMENT_METHOD.MIPS);
                                fetchMipsPaymentDetails(paymentId)
                            } else if (data.methods[0] === PAYMENT_METHOD.MYT) {
                                setPaymentMethod(PAYMENT_METHOD.MYT);
                                fetchMytPaymentDetails(paymentId);
                            }
                            setAvailablePaymentMethods(data.methods);
                        } else {
                            setAvailablePaymentMethods(data.methods)
                        }
                    }
                } else {
                    setAvailablePaymentMethods("error");
                }
            } catch (err) {
                log.error(err);
                setAvailablePaymentMethods("error");
            }
        }
        if (paymentId) fetchPaymentMethodsAvailable();
    }, [paymentId]);

    useEffect(() => {
        interval.current = setTimeout(() => {
            if (paymentTimeLeft > 0) {
                setPaymentTimeLeft(paymentTimeLeft - 1);
            }
            if (showPaymentModal && paymentTimeLeft === 0) {
                notify("Timeout!", "error")
                unlockSeats(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [paymentTimeLeft, setPaymentTimeLeft, showPaymentModal]);

    const displayTime = () => {
        const minutes = Math.floor(paymentTimeLeft / 60);
        const remainingSeconds = paymentTimeLeft % 60;
        return `0${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const onCancel = () => {
        unlockSeats(true);
    }

    return (
        <Modal
            dialogClassName="d-flex align-items-center h-100"
            contentClassName="bg-slate border-0"
            show={showPaymentModal}
            size="md"
            animation={false}
            style={{
                border: "none"
            }}
        >
            <Modal.Header closeButton={false} style={{
                border: "none"
            }} className="d-flex justify-content-center align-items-center position-relative">
                <Modal.Title className="text-white">
                    {paymentMethod ?
                        paymentMethod === PAYMENT_METHOD.MYT ?
                            "my.t money Payment" :
                            "MIPS Payment" :
                        "Select a Payment Method"
                    }
                </Modal.Title>
                <div
                    onClick={onCancel}
                    className="text-white"
                    style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translate(0%, -50%)"
                    }}
                >
                    <RxCross2 className="cursor-pointer" size={20}/>
                </div>
            </Modal.Header>
            <h5 className="text-white pt-3 d-flex justify-content-center">
                <b className="text-primary">Time Left :&nbsp;</b>
                <b>{showPaymentTimeLeft ? displayTime() : "00:00"}</b>
            </h5>
            <p className="text-primary text-center">Do not close or reload this window!</p>
            {paymentMethod ?
                <div className='p-3 d-flex justify-content-center align-items-center'>
                    {paymentInfoLoading ?
                        <Spinner color="white" />
                        :
                        <>
                            {
                                paymentMethod === PAYMENT_METHOD.MIPS ?
                                    <div dangerouslySetInnerHTML={{ __html: iframeContent }} />
                                    : <MyTPaymentMethod
                                        mytPaymentInfo={mytPaymentInfo}
                                        timerRef={interval}
                                        setPaymentTimeLeft={setPaymentTimeLeft}
                                        setShowPaymentTimeLeft={setShowPaymentTimeLeft}
                                    />

                            }
                        </>
                    }
                </div>
                :
                <Row className="justify-content-center" style={{ height: 200 }}>
                    {
                        availablePaymentMethods?.length > 1 ?
                            <>
                                {
                                    availablePaymentMethods?.includes?.(PAYMENT_METHOD.MIPS) &&
                                    <Col sm={6} className="d-flex justify-content-center align-items-center">
                                        <Image
                                            style={{
                                                width: 80,
                                                maxHeight: 80,
                                                objectFit: "contain"
                                            }}
                                            src={mipslogo}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setPaymentMethod(PAYMENT_METHOD.MIPS)
                                                fetchMipsPaymentDetails(paymentId)
                                            }} />
                                    </Col>
                                }
                                {
                                    availablePaymentMethods?.includes?.(PAYMENT_METHOD.MYT) &&
                                    <Col sm={6} className="d-flex justify-content-center align-items-center">
                                        <Image
                                            style={{
                                                width: 80,
                                                maxHeight: 80,
                                                objectFit: "contain"
                                            }}
                                            src={mytlogo}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setPaymentMethod(PAYMENT_METHOD.MYT);
                                                fetchMytPaymentDetails(paymentId)
                                            }} />
                                    </Col>
                                }
                            </> : availablePaymentMethods === null ? <div className='p-3 d-flex justify-content-center align-items-center'>
                                <Spinner />
                                <small className="text-center text-muted">
                                    fetching payment methods
                                </small>
                            </div> : <>
                                <div className='p-3 d-flex justify-content-center align-items-center'>
                                    <small className="text-center text-muted">
                                        No Payment Methods Found
                                    </small>
                                </div>
                            </>
                    }
                </Row>
            }
        </Modal>
    );
};

export default PaymentModal;
