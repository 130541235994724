import React, { useState, useRef } from "react";
import {
    Col,
    Row,
    Card,
    Tabs,
    Tab,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";
import { default_profile_picture } from "assets";
import { BiSolidRightArrow } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";

const AccountPages = ({ title, route }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param2 = params.length > 1 ? params[1] : "";
    const [hover, setHover] = useState(null);

    return (
        <div
            className={`d-flex justify-content-between cursor-pointer py-2 mx-xl-2 ${param2 === route ? "border-primary" : ""
                }`}
            onClick={() => {
                navigate(`/account/${route}`);
            }}
            onMouseEnter={() => setHover(route)}
            onMouseLeave={() => setHover(null)}
            style={{
                color:
                    hover || hover == ""
                        ? hover === route
                            ? "red"
                            : "white"
                        : param2 === route
                            ? "red"
                            : "white",
            }}
        >
            <b>{title}</b>
            {param2 === route && <span><BiSolidRightArrow /></span>}
        </div>
    );
};

const Account = () => {

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param2 = params.length > 1 ? params[1] : "";
    const user = useSelector((state) => state.auth.user);
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const navigate = useNavigate();

    const accountPages = [
        { "id": 1, "title": "Profile", "route": "profile" },
        { "id": 2, "title": "My Orders", "route": "orders" },
        { "id": 3, "title": "My Food Orders", "route": "food-orders" },
    ]

    return (
        <div className="bg-slate" style={{ minHeight: "80vh" }}>
            <div className="wrapper">
                <Row>
                    <Col sm={3}>
                        <Card
                            className="py-4 px-3 text-white d-flex flex-row"
                            style={{
                                backgroundColor: "#1c1e29",
                                borderRadius: "0px",
                            }}
                        >
                            <div
                                className="d-flex justify-content-center align-items-center overflow-hidden border rounded-circle"
                                style={{ width: 50, height: 50 }}
                            >
                                <img
                                    src={user?.profile_pic ? user?.profile_pic : default_profile_picture}
                                    alt="Profile"
                                    className="w-100"
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                            <div className="ms-3">
                                <div className="text-white">Hello,</div>
                                <p className="m-0 p-0">{user?.first_name} {user?.last_name}</p>
                                <p className="mt-1 m-0 p-0 text-small">Visits: {user?.visits}</p>
                            </div>
                        </Card>
                        {medium ?
                            accountPages.map((page, index) => {
                                return (
                                    <Card
                                        key={index}
                                        className="mt-3 py-2 px-3"
                                        style={{
                                            backgroundColor: "#1c1e29",
                                        }}
                                    >
                                        <AccountPages title={page?.title} route={page?.route} />
                                    </Card>
                                )
                            })
                            :
                            <div id="profile-tab-user-container">
                                <Tabs fill defaultActiveKey={param2} className="my-4 orders-tab-user" onSelect={(k) => navigate(`/account/${k}`)}>
                                    {accountPages.map((page, index) => {
                                        return (
                                            <Tab
                                                key={index}
                                                eventKey={page?.route}
                                                title={page?.title}
                                            />
                                        )
                                    })}
                                </Tabs>
                            </div>
                        }
                    </Col>
                    <Col sm={9}>
                        <Outlet />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const styles = {
    label: {
        width: 150,
    },
};

export default Account;
