import { error_boundary } from "assets";
import React from "react"
import { Button } from "react-bootstrap";

class ErrorBoundary extends React.Component {
    state = { hasError: false }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(a, b) {
        console.error(a);
        console.error(b);
    }

    render() {
        if (this.state.hasError) {
            return <div className="d-flex flex-column justify-content-center align-items-center bg-slate" style={{ height: "100vh", height: "100svh" }}>
                <img width={150} src={error_boundary} alt="Error" />
                <p className="text-white">Something went wrong!</p>
                <div className="d-flex gap-2">
                    <Button variant="primary" style={{ backgroundColor: "red", borderColor: "red" }}>
                        <a style={{ textDecoration: "none", color: "white" }} href="/">
                            Home
                        </a>
                    </Button>
                    <Button variant="primary" style={{ backgroundColor: "red", borderColor: "red", color: "white" }} onClick={()=>window.location.reload()}>
                        Reload
                    </Button>
                </div>
            </div>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;