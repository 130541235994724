import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import * as authActions from "Redux/Actions/authActions";
import { notify } from "Utilities/Notifications/Notify";
import ResetPassword from "./ResetPassword";
import { onInputTextShouldBeLowerCase } from "utils";

const ForgotPassword = ({ setShowForm }) => {
  const form = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);
    setEmail(formData.get("email"));
    if (formData.get("email")) {
      const response = await authActions.sendResetOtp({
        email: formData.get("email"),
      });
      if (response) {
        switch (response.status) {
          case "SUCCESSFUL":
            notify(response.msg, "success");
            setIsSent(true);
            break;
          default:
            notify("Error while sending OTP, Try again later", "error");
        }
        form.current.reset();
      }
    } else {
      notify("Please enter the email.", "error");
    }
    setIsSubmitting(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);

    if (formData.get("password1") === formData.get("password2")) {
      const response = await authActions.reset_password({
        password: formData.get("password1"),
        email: email,
        otp: formData.get("otp"),
      });
      if (response) {
        form.current.reset();
        switch (response.status) {
          case "LINK_EXPIRED":
            notify(response.msg, "error");
            setIsSent(false);
            break;
          case "INCORRECT_OTP":
            notify(response.msg, "error");
            break;
          case "DOES_NOT_EXIST":
            notify(response.msg, "error");
            setIsSent(false);
            setShowForm("login");
            break;
          case "RESET_SUCCESSFUL":
            notify(response.msg, "success");
            setIsSent(false);
            setShowForm("login");
            break;
          default:
            notify("Error while sending OTP, Try again later", "error");
        }
      }
    } else {
      notify("Passwords Do Not Match.");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {isSent ?
        <ResetPassword form={form} isSubmitting={isSubmitting} handleResetPassword={handleResetPassword} />
        :
        <Form
          className="text-start w-100"
          ref={form}
          onSubmit={(e) => handleSendOtp(e)}
        >
          <Form.Group
            className="mb-3"
            controlId="forgotPasswordForm.email"
          >
            <p className="text-white mt-2">
              Enter Your Registered Email address
            </p>
            {/* <Form.Label className="text-white">Email address</Form.Label> */}
            <Form.Control
              type="email"
              name="email"
              placeholder="name@example.com"
              className="bg-extradark py-2 border-grey text-lightgrey"
              onInput={onInputTextShouldBeLowerCase}
            />
          </Form.Group>
          <Button
            className="bg-primary border-primary w-100 custom-button mt-2 mb-3 py-2"
            // type="submit"
            disabled={isSubmitting}
            onClick={(e) => handleSendOtp(e)}
          >
            {isSubmitting ? (
              <span>
                <Spinner size="sm" className="me-2" />
                Processing ...
              </span>
            ) : (
              <span className="poppins">Send OTP</span>
              // <span className="poppins">SEND ME A RESET LINK</span>
            )}
          </Button>
        </Form>}
      <div className="d-flex align-items-center my-4" style={{ height: 30 }}>
        <div
          className="w-100"
          style={{ height: 1, backgroundColor: "#FFFFFF" }}
        ></div>
        <div className="text-white mx-2 fs-10px">OR</div>
        <div
          className="w-100"
          style={{ height: 1, backgroundColor: "#FFFFFF" }}
        ></div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <p className="text-lightgrey me-2">Don't have an account?</p>
        <div
          onClick={() => setShowForm("signup")}
          className="cursor-pointer text-bold"
        >
          <p className="text-white">Sign Up</p>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
