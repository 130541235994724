import log from 'loglevel';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as authActions from "Redux/Actions/authActions";
import * as authSlices from "Redux/AuthReducer/authSlices";
import { notify } from 'Utilities/Notifications/Notify';

const VerifyForm = ({ setShowForm, signedUpEmail, setShowLoginModal, timer, setTimer }) => {
    const form = useRef(null);
    const dispatch = useDispatch();
    const timerRef = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resending, setResending] = useState(false);

    useEffect(() => {
        if (timer > 0) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [timer, setTimer]);

    const resendOtpRequest = async (e) => {
        e.preventDefault();
        setResending(true);
        if (isSubmitting) return;
        const response = await authSlices.resendSignupOtp({
            email: signedUpEmail,
        });
        if (response) {
            log.log(response);
            setTimer(120);
        }
        setResending(false);
    }

    const handleVerifyEmail = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        log.log({ e })
        const formData = new FormData(form.current);
        formData.append("username", signedUpEmail);
        const response = await authActions.verifyAccount(formData);
        if (response) {
            switch (response.status) {
                case "VERIFIED":
                    notify(response.msg, "success");
                    dispatch(authSlices.saveLoginInfo(response));
                    setShowForm("login");
                    setShowLoginModal(false);
                    break;
                default:
                    setIsSubmitting(false);
            }
            setIsSubmitting(false);
            // setShowLoginModal(false);
        }
    };

    return (
        <Form
            className="text-start w-100 my-3"
            ref={form}
            onSubmit={(e) => handleVerifyEmail(e)}
        >
            <p className="text-center mb-0" style={{ color: "green" }}><small>✔ OTP was successfully sent to your email.</small></p>
            <Form.Group className="mb-3" controlId="loginForm.email">
                <Form.Label className="text-white"></Form.Label>
                <Form.Control
                    type="text"
                    name="email_verified_otp"
                    placeholder="Enter OTP"
                    className="bg-extradark py-2 mb-4 border-grey text-lightgrey"
                    required
                />
            </Form.Group>
            <div className="mb-3 d-flex justify-content-center align-items-center">
                <div
                    className={`${timer !== 0 ? "text-lightgrey" : "text-white"} ${!resending ? "cursor-pointer" : "cursor-not-allowed"}`}
                onClick={(e) => {
                        if (timer === 0 && !resending) {
                            resendOtpRequest(e);
                        }
                    }}
                >
                    {resending ? "Resending..." : "Resend OTP"}
                </div>
                <div
                    className={`${timer !== 0 ? "text-white mx-2 caption" : "d-none"
                        }`}
                >
                    {`in ${timer} seconds`}
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-start">
                <Button
                    className="bg-primary primary-button border border-primary ms-3 ms-md-0 px-2"
                    type="submit"
                    style={{ width: 100, marginTop: 0 }}
                    disabled={resending || isSubmitting}
                >
                    <p className="text-white mb-0">Verify</p>
                </Button>
            </div>
        </Form>
    )
}

export default VerifyForm