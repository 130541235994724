import log from 'loglevel';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router'
import * as authActions from "Redux/Actions/authActions";
import { notify } from "Utilities/Notifications/Notify";

const ProcessPayment = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [paymentInfoLoading, setPaymentInfoLoading] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [iframeContent, setIframeContent] = useState('');

    const { payment_id, theatre_id } = params;

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            setPaymentInfoLoading(true);
            try {
                const info = await authActions.getPaymentInfo(payment_id, theatre_id);
                log.log({ info });
                setPaymentInfo(info);
                if ("iframe" in info){
                    if ("answer" in info.iframe){
                        if (info.iframe.answer.operation_status === "success") {
                            const iframeContent = info.iframe.answer.payment_zone_data
                            log.log("Setting iframe content to:", iframeContent);
                            setIframeContent(iframeContent);
                        }
                    }
                }
            } catch (e) {
                console.error(e);
                setPaymentInfo(`${e}`);
                notify(`${e}`, "error");
            }
            setPaymentInfoLoading(false);
        }
        if (payment_id) {
            fetchPaymentDetails();
        }
    }, []);

    if (!payment_id || !theatre_id) {
        return (
            <>
                <PaymentError error_info={"No Payment ID provided"} />
            </>
        )
    }

    return (
        <div className='d-flex justify-content-center align-items-center' style={{
            minHeight: "60vh",
            width: "100%",
            overflow: "auto"
        }}>
            {
                paymentInfoLoading ? <Spinner /> : <>
                    <div className='d-flex justify-content-between align-items-center' style={{ width: "80%", height: "100%", overflowWrap: "anywhere", padding: 20 }}>
                        {/* <code lang='JSON'>
                            <pre lang='JSON'>
                                {JSON.stringify(paymentInfo, null, 2)}
                            </pre>
                        </code> */}
                        <div>
                            <h4>Please pay for your order</h4>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: iframeContent }} />
                    </div>
                </>
            }
        </div>
    )
}

export default ProcessPayment;

const PaymentError = ({ error_info }) => {

    return (
        <>
            <div className='d-flex justify-content-center align-items-center' style={{
                height: "60vh",
                width: "80%",
                overflow: "auto"
            }}>
                <h4>Payment Error</h4>
                {
                    error_info ? <>{error_info}</> : <>
                        <p>
                            No info available, please try again later.
                        </p>
                    </>
                }
            </div>
        </>
    )
}