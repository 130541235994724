import { Button, Form, Spinner } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import * as authSlices from "Redux/AuthReducer/authSlices";
import { notify } from "Utilities/Notifications/Notify";
import CountryCodeFormField from "./CountryCodeFormField";
import log from "loglevel";
import { onInputTextShouldBeLowerCase } from "utils";
import ReactGA from 'react-ga4';

const NoLoginForm = ({ email, setEmail, phone, setPhone, phoneCode, setPhoneCode, setShowForm, setShowLoginModal, setAllow, setNoLoginUserId }) => {

    const form = useRef(null);
    const otpForm = useRef(null);
    const timerRef = useRef(null);
    const [infoForm, setInfoForm] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resending, setResending] = useState(false);
    const [timer, setTimer] = useState(0);

    const [sentData, setSentData] = useState(null);


    useEffect(() => {
        if (timer > 0) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [timer, setTimer]);

    const sendVerificationCode = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const formData = new FormData(form.current);
        const data = Array.from(formData.entries()).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});
        setSentData(data);
        const response = await authSlices.noLoginSignUp(formData);
        if (response.status === 201) {
            form.current?.reset();
            setIsSubmitting(false);
            notify(response.data.msg, "success");
            setInfoForm(false);
            setTimer(120);
        } else {
            notify("There was some problem sending OTP!", "error");
        }
        ReactGA.event({
            category: "login_flow",
            action: "send_otp_no_login",
            label: "Send OTP NO LOGIN", // optional
        });
        setIsSubmitting(false);
    }

    const handleVerifyMobile = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const formData = new FormData(otpForm.current);
        formData.append("mobile", phone);
        formData.append("mobile_code", phoneCode);
        formData.append("email", email);
        const response = await authSlices.noLoginVerifyMobile(formData);
        if (response) {
            notify(response.msg, "success")
            otpForm.current.reset();
            setShowLoginModal(false);
            if (response?.user_id) {
                if (typeof setNoLoginUserId === "function") {
                    setNoLoginUserId(response?.user_id);
                }
            }
            setAllow(true);
        }
        setIsSubmitting(false);
    };

    const resendOtpRequest = async (e) => {
        e.preventDefault();
        setResending(true);
        if (isSubmitting) return;
        const response = await authSlices.resendNoLoginOtp(sentData);
        if (response) {
            log.log(response);
            setTimer(120);
        }
        setResending(false);
    }


    return (
        <>
            {infoForm ?
                <Form
                    ref={form}
                    className="text-start w-100"
                    onSubmit={(e) => sendVerificationCode(e)}
                >
                    {/* <Form.Group className="mb-3">
                        <Form.Label className="text-feather">Mobile</Form.Label>
                        <Form.Control
                            type="text"
                            name="mobile"
                            maxLength={10}
                            placeholder="9988989898"
                            className="bg-extradark text-lightgrey border-grey"
                            value={phone}
                            required
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Form.Group> */}
                    <CountryCodeFormField setPhone={setPhone} setPhoneCode={setPhoneCode} />
                    <Form.Group className="mb-3">
                        <Form.Label className="text-white">Email address</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="name@example.com"
                            className="bg-extradark py-2 border-grey text-lightgrey"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            onInput={onInputTextShouldBeLowerCase}
                        />
                    </Form.Group>
                    <Button
                        className="bg-primary border-primary w-100 custom-button my-3 py-2"
                        type="submit"
                    >
                        {isSubmitting ? (
                            <span>
                                <Spinner size="sm" className="me-2" />
                                Sending ...
                            </span>
                        ) : (
                            <span>Send OTP</span>
                        )}
                    </Button>
                </Form>
                :
                <Form
                    ref={otpForm}
                    className="text-start w-100"
                    onSubmit={(e) => handleVerifyMobile(e)}
                >
                    <Form.Group className="mb-3">
                        <Form.Label className="text-feather">OTP</Form.Label>
                        <Form.Control
                            type="text"
                            name="mobile_verification_otp"
                            maxLength={10}
                            placeholder="Enter your OTP"
                            className="bg-extradark text-lightgrey border-grey"
                            required
                        />
                    </Form.Group>
                    <div className="mb-3 d-flex justify-content-center align-items-center">
                        <div
                            className={`${timer !== 0 ? "text-lightgrey" : "text-white"} ${!resending ? "cursor-pointer" : "cursor-not-allowed"}`}
                            onClick={(e) => {
                                if (timer === 0 && !resending) {
                                    resendOtpRequest(e);
                                }
                            }}
                        >
                            {resending ? "Resending..." : "Resend OTP"}
                        </div>
                        <div
                            className={`${timer !== 0 ? "text-white mx-2 caption" : "d-none"
                                }`}
                        >
                            {`in ${timer} seconds`}
                        </div>
                    </div>
                    <Button
                        className="bg-primary border-primary w-100 custom-button my-3 py-2"
                        type="submit"
                        disabled={resending || isSubmitting}
                    >
                        {isSubmitting ? (
                            <span>
                                <Spinner size="sm" className="me-2" />
                                Verifying ...
                            </span>
                        ) : (
                            <span>Verify Information</span>
                        )}
                    </Button>
                </Form>
            }
            <div className="d-flex justify-content-center align-items-center">
                <div className="me-2 text-lightgrey">
                    Already have an account?
                </div>
                <div
                    onClick={() => {setShowForm("login");
                        
                        ReactGA.event({
                            category: "login_flow",
                            action: "go_withoutLogin_to_Login",
                            label: "Going from No Login to Login", // optional
                        });
                    }}
                    className="cursor-pointer text-bold text-white"
                >
                    Login
                </div>
            </div>
        </>
    );
};

export default NoLoginForm;
