import React, { useEffect, useState } from "react";
import { Button, Card, Image, Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { IoTicketOutline } from "react-icons/io5";
import { FaPlayCircle } from "react-icons/fa";
import { FaRegCirclePlay } from "react-icons/fa6";
import { GoPlay } from "react-icons/go";
import { movie_placeholder, ticket_background, ticket_background_hover } from "assets";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const IMAGE_WIDTH = 420;

function MovieCard({ movie, setOpenModal }) {

    const navigate = useNavigate()
    const [isHovered, setIsHovered] = useState(false);
    const [overview, setOverview] = useState(false);
    const [moviePoster, setMoviePoster] = useState(movie.poster_image);
    const small = useMediaQuery({ query: "(min-width: 576px)" });
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    const truncateString = (str, maxLength) => {
        if (str.length <= maxLength) return str;
        return str.substring(0, maxLength) + '...';
    };

    useEffect(() => {
        if (large) {
            if (movie.overview.length > 250) {
                setOverview(truncateString(movie.overview, 250))
            } else {
                setOverview(movie.overview)
            }
        } else {
            if (movie.overview.length > 150) {
                setOverview(truncateString(movie.overview, 150))
            } else {
                setOverview(movie.overview)
            }
        }

    }, [movie])

    return (
        <>
            {large ?
                <Card
                    // className="mx-5 my-4"
                    style={{
                        backgroundColor: "#13151f",
                        width: 920,
                        margin: "100px 100px 100px 100px",
                        borderRadius: 0,
                        boxShadow: "0px 0px 20px #000"
                    }}
                >
                    <Row>
                        <Col
                            sm={6}
                            className="mb-3 ms-3"
                            style={{ margin: "-50px 0px 0px 0px" }}
                        >
                            <div className="position-relative play-trailer-overlay-trigger">
                                <Image 
                                    className="undraggable" 
                                    src={moviePoster} 
                                    alt={movie.title} 
                                    style={{ width: IMAGE_WIDTH }}
                                    onError={()=>setMoviePoster(movie_placeholder)}
                                />
                                <OverlayTrigger
                                    placement="bottom"
                                    // overlay={<Tooltip id="button-tooltip-2">Watch Trailer</Tooltip>}
                                    overlay={<></>}
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <Button
                                            {...triggerHandler}
                                            ref={ref}
                                            className="position-absolute border-0 play-trailer-overlay"
                                            style={{
                                                top: 0,
                                                left: 0,
                                                width: IMAGE_WIDTH,
                                                height: "100%",
                                                borderRadius: 4,
                                                // backgroundColor: "#ec2f23",
                                                backgroundColor: "rgba(0, 0, 0, 0.4)",
                                                // fontSize: 12, 
                                                padding: "3px 5px",
                                            }}
                                            onClick={() => setOpenModal(true)}
                                        >
                                            <div style={{ fontWeight: "bold", width: "100%" }} className="d-flex justify-content-center align-items-center play-trailer-icon">
                                                <GoPlay size={80} />
                                                {/* <span>Trailer</span> */}
                                            </div>
                                        </Button>
                                    )}
                                </OverlayTrigger>
                            </div>
                        </Col>
                        <Col sm={5}>
                            <div className="mt-5 mb-3 text-white">
                                <h2>
                                    <Link style={{ textDecoration: "none", color: "inherit" }} to={`/movies/${movie.id}`}>
                                        {movie.title}
                                    </Link>
                                </h2>
                                <p style={{ color: "#fece50" }}>
                                    <Link style={{ textDecoration: "none", color: "inherit" }} to={`/movies/${movie.id}`}>
                                        {movie.show_rating && <span>Rating: {Number.isInteger(movie.rating) ? movie.rating : movie.rating.toFixed(1)}/10</span>}
                                        {movie.show_rating && movie.show_certification && <span className="px-2">•</span>}
                                        {movie.show_certification && <span>{movie.certification_data?.title}</span>}
                                    </Link>
                                </p>
                                <p style={{ color: "#9a9a9a" }}>{overview}</p>
                                <h2 className="mt-4">Cast</h2>
                                <p style={{ color: "#9a9a9a" }}>
                                    {movie?.program_data?.cast?.slice(0, 5).map((c, i) => {
                                        return c.name + (i === 4 ? "" : ", ")
                                    })}
                                </p>
                                <div
                                    className="position-relative mt-4 cursor-pointer"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={() => navigate(`/show/${movie.id}`)}
                                >
                                    <Image  src={isHovered ? ticket_background_hover : ticket_background} width={160} height={40} />
                                    <div
                                        className="position-absolute d-flex justify-content-center align-items-center"
                                        style={{ top: 0, width: 160, height: 40, color: isHovered ? "#ec2f23" : "white" }}
                                    >
                                        <span className="me-2"><IoTicketOutline /></span>
                                        Buy Tickets
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
                :
                <Card
                    className="p-2 mx-2 mb-4"
                    style={{ backgroundColor: "#13151f", width: "95vw" }}
                >
                    <div className="position-relative">
                        <Image src={movie.poster_image} alt={movie.title} style={{ width: "100%" }} />
                        {/* <Button
                            className="position-absolute p-0 border-0"
                            style={{ top: "15px", right: "15px", width: 32, height: 32, borderRadius: 16, backgroundColor: "#ec2f23" }}
                            onClick={() => setOpenModal(true)}
                        >
                            <FaPlayCircle size={25} />
                        </Button> */}
                        <div
                            className="position-absolute p-0 border-0 d-flex justify-content-center align-items-center play-trailer-icon gap-2 pe-2"
                            style={{ 
                                fontWeight: "bold",
                                top: "15px", 
                                right: "15px", 
                                borderRadius: 16, color: "white",
                                backgroundColor: "rgba(0,0,0,0.66)"
                            }}
                            onClick={() => setOpenModal(true)}>
                            <GoPlay size={32} />
                            <span>Trailer</span>
                        </div>
                    </div>
                    <div className="mt-4 p-2 text-white">
                        <h2>
                            <Link style={{ textDecoration: "none", color: "inherit" }} to={`/movies/${movie.id}`}>
                                {movie.title}
                            </Link>
                        </h2>
                        <p style={{ color: "#fece50" }}>
                            <Link style={{ textDecoration: "none", color: "inherit" }} to={`/movies/${movie.id}`}>
                                {movie.show_rating && <span>Rating: {Number.isInteger(movie.rating) ? movie.rating : movie.rating.toFixed(1)}/10</span>}
                                {movie.show_rating && movie.show_certification && <span className="px-2">•</span>}
                                {movie.show_certification && <span>{movie.certification_data?.title}</span>}
                            </Link>
                        </p>
                        <p style={{ color: "#9a9a9a" }}>{overview}</p>
                        <h2 className="mt-4">Cast</h2>
                        <p style={{ color: "#9a9a9a" }}>
                            {movie?.program_data?.cast?.slice(0, 5).map((c, i) => {
                                return c.name + (i === 4 ? "" : ", ")
                            })}
                        </p>
                        <div
                            className="position-relative mt-4 d-flex justify-content-center align-items-center cursor-pointer"
                            onClick={() => navigate(`/show/${movie.id}`)}
                        >
                            <Image src={ticket_background} width={160} height={40} />
                            <div
                                className="position-absolute d-flex justify-content-center align-items-center"
                                style={{ top: 0, width: 160, height: 40 }}
                            >
                                <span className="me-2"><IoTicketOutline /></span>
                                Buy Tickets
                            </div>
                        </div>
                    </div>
                </Card>
            }
        </>
    );
}

export default MovieCard;
