import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import WebsiteRoutingOutlet from "WebsiteRoutingOutlet";
import Home from "pages/Home/Home";
import Movies from "pages/Movie/Movies";
import MovieDetails from "pages/Movie/MovieDetails";
import SelectShow from "pages/Movie/SelectShow";
import SelectSeats from "pages/Movie/SelectSeats";
import About from "pages/About/About";
import Contact from "pages/Contact/Contact";
import WebPageNotFound from "pages/Errors/WebPageNotFound";
import OurCinemas from "pages/Cinemas/OurCinemas";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import ProtectedRoute from "ProtectedRoutes";
// import Login from "website/pages/Auth/Login";
// import SignUp from "website/pages/Auth/SignUp";
// import ResetPassword from "website/pages/Auth/ResetPassword";
// import ForgotPassword from "website/pages/Auth/ForgotPassword";
import PrivacyPolicy from "website/pages/Policies/PrivacyPolicy";
import TermsOfUse from "website/pages/Policies/TermsOfUse";
import BookingPolicy from "website/pages/Policies/BookingPolicy";
import SecurityPolicy from "website/pages/Policies/SecurityPolicy";
import Account from "website/pages/Account";
import Profile from "website/pages/Account/Profile";
import Orders from "website/pages/Account/Orders";
import FoodOrders from "website/pages/Account/FoodOrders";
import ProcessPayment from "pages/Payment/ProcessPayment";
import ConfirmPayment from "pages/Payment/ConfirmPayment";
import Sitemap from "Sitemap";
import MyTMoneyHelp from "website/pages/MyTMoneyHelp";
import AnonymousOrderView from "website/pages/AnonymousOrderView";
import ErrorBoundary from "website/components/ErrorBoundary";
import { Helmet } from "react-helmet";
import log from "loglevel";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const LOADING_DELAY = 1500;
export const PAGE_TITLE_LOADING_DELAY = 200;

const CurrentURLDisplay = () => {
  const location = useLocation();
  const [myTHeaders, setMyTHeaders] = useState(null);

  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        const response = await axios.get(
          `${window.location.origin}/get-request-headers`
        ); // Adjust the endpoint as necessary
        let headers = response.data;
        log.log({ requestHeaders: headers });
        const headersArray = Object.entries(headers);

        // Sort the array based on the header keys in ascending order
        headersArray.sort((a, b) => a[0].localeCompare(b[0]));
        // const sortedHeadersLine = headersArray.map(header => `<div>${header[0]}: ${header[1]}</div>`).join(', ');
        setMyTHeaders(headersArray);

      } catch (e) {
        log.error(e);
      }
    };

    fetchHeaders();
  }, []);

  return (
    <div>
      <p>Current URL: {location.pathname}</p>
      <p>
        Current Headers: {myTHeaders != null && myTHeaders.map(header => <div>{header[0]}: {header[1]}</div>)}
      </p>
    </div>
  );
};

const Routers = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // const user = useSelector((state) => state.auth.user);
  const [showForm, setShowForm] = useState("login");

  log.log("isAuthenticated", isAuthenticated);

  const token = useSelector((state) => state.auth.token);
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
  axios.defaults.headers.common['X-Client-Id'] = process.env.REACT_APP_CLIENT_ID;

  

  return (
    <Router>
      <ScrollToTop />
      {/* <CurrentURLDisplay /> */}
      <Helmet>
        <title>MCine - Where Cinema Comes to Life</title>
      </Helmet>
      <ErrorBoundary>
        <Routes>
          <Route path="" element={<WebsiteRoutingOutlet />}>
            <Route
              path=""
              element={<Home showForm={showForm} setShowForm={setShowForm} />}
            />
            {/* <Route path="movies" element={<Movies />} /> */}
            <Route path="movies" element={<Movies />}>
              <Route path=":movieId" element={<MovieDetails />} />
            </Route>
            <Route path="show" element={<SelectShow />}>
              <Route path=":movieId" element={<SelectShow />}>
                <Route path="seats" element={<SelectSeats />}>
                  <Route path=":showId" element={<SelectSeats />} />
                </Route>
              </Route>
            </Route>
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="our-cinemas" element={<OurCinemas />} />
            {/* <Route path="login" element={<Login />} /> */}
            {/* <Route path="signup" element={<SignUp />} /> */}
            {/* <Route path="forgot-password" element={<ForgotPassword />} /> */}
            {/* <Route path="reset-password" element={<ResetPassword />} /> */}
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="booking-policy" element={<BookingPolicy />} />
            <Route path="security-policy" element={<SecurityPolicy />} />
            <Route path="myt-money-help" element={<MyTMoneyHelp />} />
            <Route path="*" element={<WebPageNotFound />} />
            <Route path="anonymous-order" element={<Outlet />}>
              <Route path=":paymentId" element={<AnonymousOrderView />} />
            </Route>

            {/* PROTECTED ROUTES */}
            <Route path="account" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Account /></ProtectedRoute>}>
              <Route path="profile" element={<Profile />} />
              <Route path="orders" element={<Orders />}>
                <Route path=":orderId" element={<Orders />} />
              </Route>
              <Route path="food-orders" element={<FoodOrders />} />
            </Route>
            <Route path="payment" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Outlet /></ProtectedRoute>}>
              <Route path="confirm" element={<Outlet />}>
                <Route path=":payment_id" element={<Outlet />}>
                  <Route path=":theatre_id" element={<ConfirmPayment />} />
                </Route>
              </Route>
              <Route path=":payment_id" element={<Outlet />}>
                <Route path=":theatre_id" element={<ProcessPayment />} />
              </Route>
            </Route>
            {/* PROTECTED ROUTES END*/}

            {/* <Route path="sitemap.xml" element={<Sitemap />} /> */}
          </Route>
          {/* {isAuthenticated && (user.is_superuser ? <></> : <></>)} */}
        </Routes>
      </ErrorBoundary>
    </Router>
  );
};

export default Routers;
