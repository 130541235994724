import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { asyncStatuses } from "Redux/enums";
import { notify } from "Utilities/Notifications/Notify";
import axios from "axios";
import log from "loglevel";

const initialState = {
  user: null,
  token: null,
  isAuthenticated: null,
  status: null,
};

// log.log(process.env.REACT_APP_BASE_URLs)
// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const userLogin = createAsyncThunk("auth/userLogin", async (data, thunkApi) => {
  try {
    const response = await axios.post("/user/login/", data);
    notify("You are successfully logged in.", "success");
    return response.data;
  } catch (err) {
    return thunkApi.rejectWithValue({ message: err?.response?.data?.msg || "Login Failed!" })
  }

});

export const userLogout = createAsyncThunk("auth/userLogout", async () => {
  const response = await axios.post("/user/logout/");
  return response;
});

export const userUpdate = createAsyncThunk("auth/userUpdate", async (data) => {
  try {
    const response = await axios.put(`/user/rest/users/${data.get("id")}/`, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    notify("Update Failed!", "error");
    return null;
  }
});

export const userProfilePicture = async (id, data) => {
  try {
    const response = await axios.put(`/user/rest/users/${id}/update_profile_pic/`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg || "Update Failed!", "error");
    return false;
  }
}

export const userUpdateWithMobileCheck = async (data) => {
  try {
    const response = await axios.put(`/user/rest/users/${data.get("id")}/update_with_mobile_check/`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg || "Update Failed!", "error");
    return false;
  }
}

export const resendOtp = async (data) => {
  try {
    const response = await axios.post(`/user/rest/users/${data.id}/reset_mobile_verify_otp/`, data);
    log.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const signingUp = async (data) => {
  try {
    const response = await axios.post(`/user/signup/`, data);
    log.log("Response for signing up: ", response);
    return response;
  } catch (err) {
    log.log("Error signing up: ", err);
    // return { error: true, msg: JSON.stringify(err?.response?.data) };
    return err?.response;
  }
};

export const resendSignupOtp = async (data) => {
  try {
    const response = await axios.post(`/user/resend_signup_otp/`, data);
    log.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const resendLoginOtp = async (data) => {
  try {
    const response = await axios.post(`/user/resend_login_otp/`, data);
    log.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const resendNoLoginOtp = async (data) => {
  try {
    const response = await axios.post(`/user/resend_nologin_otp/`, data);
    log.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const noLoginSignUp = async (data) => {
  try {
    const response = await axios.post(`/user/nologin_signup/`, data);
    log.log("Response for OTP: ", response);
    return response;
  } catch (err) {
    log.log("Error sending OTP: ", err);
    return err?.response;
  }
};

export const noLoginVerifyMobile = async (data) => {
  try {
    const response = await axios.post("/user/nologin_verify/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return false;
  }
};

const authSlices = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetAuthSlice: (state, action) => {
      return initialState
    },
    saveLoginInfo: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.user = { ...action.payload.user };
    },
    saveUserInfo: (state, action) => {
      state.user = { ...action.payload };
    },
    updateMobileActive: (state, action) => {
      state.user.is_mobile_active = true;
    },
    updateProfilePicture: (state, action) => {
      state.user.profile_pic = action.payload;
    }
  },
  extraReducers: (builder) => {
    // LOGIN
    builder.addCase(userLogin.pending, (state) => {
      state.status = asyncStatuses.LOADING;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.status = asyncStatuses.SUCCESS;
      log.log(action.payload);
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.user = { ...action.payload.user };
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.status = asyncStatuses.FAILED;
      state.isAuthenticated = null;
      state.token = null;
      state.user = null;
      if (action.payload.message) {
        notify(action.payload.message, "error", "Login-Failed")
      }
    });

    // LOGOUT
    builder.addCase(userLogout.pending, (state) => {
      state.status = asyncStatuses.LOADING;
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {
      state.status = asyncStatuses.SUCCESS;
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
    });
    builder.addCase(userLogout.rejected, (state, action) => {
      state.status = asyncStatuses.FAILED;
      // state.isAuthenticated = null;
      // state.token = null;
      // state.user = null;
    });

    // Profile
    builder.addCase(userUpdate.pending, (state) => {
      state.status = asyncStatuses.LOADING;
    });
    builder.addCase(userUpdate.fulfilled, (state, action) => {
      state.status = asyncStatuses.SUCCESS;
      state.user = { ...action.payload };
    });
    builder.addCase(userUpdate.rejected, (state, action) => {
      state.status = asyncStatuses.FAILED;
    });
  },
});

export const {
  resetAuthSlice,
  saveLoginInfo,
  saveUserInfo,
  updateMobileActive,
  updateProfilePicture
} = authSlices.actions;

export default authSlices.reducer;
