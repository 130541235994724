import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Image } from "react-bootstrap";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { useMediaQuery } from "react-responsive";
import { food_fallback } from "assets";

const FoodCard = ({ food, orderedFood, setOrderedFood, maxAllowedFoodQuantity, totalFoodQuantity, setTotalFoodQuantity, errorMessage, setErrorMessage }) => {

    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(null);

    const units = [
        { value: "UNIT", label: "unit" },
        { value: "GRAM", label: "g" },
        { value: "LITER", label: "L" },
        { value: "MILLI_LITER", label: "ml" },
        { value: "POUND", label: "lb" },
        { value: "BASKET", label: "Basket" },
        { value: "PIECE", label: "pc" },
    ]

    const handleIncrement = (foodId) => {
        const newArray = orderedFood.map(item => {
            if (item.food === foodId) {
                return { ...item, quantity: item.quantity + 1 };
            }
            return item;
        });
        setOrderedFood(newArray);
    };

    const handleDecrement = (foodId) => {
        const newArray = orderedFood.map(item => {
            if (item.food === foodId) {
                return { ...item, quantity: item.quantity - 1 };
            }
            return item;
        });
        setOrderedFood(newArray);
    };

    useEffect(() => {
        if (containerRef.current) {
            const updateDimensions = () => {
                const width = containerRef.current.offsetWidth;
                setContainerWidth(width);
            };

            updateDimensions();

            window.addEventListener('resize', updateDimensions);

            return () => {
                window.removeEventListener('resize', updateDimensions);
            };
        }
    }, []);

    return (
        <div className="p-2">
            <Card
                className={`p-3 text-white d-flex ${large ? "flex-row" : ""}`}
                style={{
                    backgroundColor: "#13151f",
                    borderRadius: "0px"
                }}
            >
                <div className="border" ref={containerRef} style={{ width: large ? 100 : "auto", height: containerWidth ? containerWidth : 100 }}>
                    <Image
                        className="w-100 h-100"
                        src={food?.image || food_fallback}
                        alt={food?.title}
                        onError={()=>food_fallback}
                    />
                </div>
                <div className={`${large ? "ms-3 w-75 d-flex flex-column justify-content-between" : "w-100"}`}>
                    <div>
                        <div className={`${large ? "d-flex justify-content-between align-items-center" : ""}`}>
                            <b>{food?.title}</b>
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    width: 20,
                                    height: 20,
                                    border: food?.type === "VEG" ? "1px solid #00923f" : "1px solid red"
                                }}
                            >
                                <GoDotFill fill={food?.type === "VEG" ? "#00923f" : "red"} />
                            </div>
                        </div>
                        <div className="fs-14">{food?.description} {food?.quantity} {units.filter(unit => unit.value === food?.unit)[0].label}</div>
                    </div>
                    <div className={`${large ? "d-flex justify-content-between align-items-center" : "mt-2"}`}>
                        <div>Rs. {food.price}</div>
                        {orderedFood.filter(item => item.food === food.id).length > 0 ?
                            <div className={`d-flex align-items-center justify-content-center ${large ? "" : "mt-2"}`}>
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: 30, height: 30, backgroundColor: "#ffffff", borderRadius: 15 }}
                                    onClick={() => {
                                        setErrorMessage(null)
                                        if (orderedFood.filter(item => item.food === food.id)[0].quantity >= 2) {
                                            handleDecrement(food.id)
                                            setTotalFoodQuantity(totalFoodQuantity - 1)
                                        } else if (orderedFood.filter(item => item.food === food.id)[0].quantity >= 1) {
                                            setOrderedFood(orderedFood.filter(item => item.food !== food.id))
                                            setTotalFoodQuantity(totalFoodQuantity - 1)
                                        }
                                    }}
                                >
                                    <IoMdRemove color="#000000" />
                                </div>
                                <span className="px-3">{orderedFood.filter(item => item.food === food.id)[0].quantity}</span>
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: 30, height: 30, backgroundColor: "#ffffff", borderRadius: 15 }}
                                    onClick={() => {
                                        if (totalFoodQuantity < maxAllowedFoodQuantity) {
                                            handleIncrement(food.id)
                                            setTotalFoodQuantity(totalFoodQuantity + 1)
                                        } else {
                                            setErrorMessage(`Only ${maxAllowedFoodQuantity} Food items can be selected at once!`)
                                        }
                                    }}
                                >
                                    <IoMdAdd color="#000000" />
                                </div>
                            </div>
                            :
                            <Button
                                className={`bg-primary primary-button border border-primary px-3 py-1 ${large ? "" : "mt-2 w-100"}`}
                                onClick={() => {
                                    if (totalFoodQuantity < maxAllowedFoodQuantity) {
                                        setOrderedFood(orderedFood => [...orderedFood, { "food": food.id, "title": food.title, "quantity": 1, "price": food.price }])
                                        setTotalFoodQuantity(totalFoodQuantity + 1)
                                    } else {
                                        setErrorMessage("Only 10 Food items can be selected at once!")
                                    }
                                }}
                            >
                                ADD
                            </Button>
                        }
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default FoodCard;
