import React, { useState, useEffect, useRef } from "react";
import { Container, Nav, Navbar, Button, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { mcine, ticket_background, ticket_background_hover } from "assets";
import LoginModal from "components/Auth/LoginModal";
import { CgMenu } from "react-icons/cg";
import * as authSlices from "Redux/AuthReducer/authSlices";
import { IoMdLogOut } from "react-icons/io";
import { IoTicketOutline } from "react-icons/io5";

const useOutsideClickAndScroll = (ref, callback) => {
  useEffect(() => {
    const handleClickOrScroll = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOrScroll);
    window.addEventListener("scroll", handleClickOrScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOrScroll);
      window.removeEventListener("scroll", handleClickOrScroll);
    };
  }, [ref, callback]);
};

const NavLink = ({ title, route, handleLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const param1 = location.pathname.split("/")[1] || "";
  const [hover, setHover] = useState(null);

  return (
    <Nav.Link
      className={`text-center py-2 mx-xl-2 ${param1 === route ? "border-primary" : ""}`}
      onClick={() => (route === "logout" ? handleLogout() : navigate(`/${route}`))}
      onMouseEnter={() => setHover(route)}
      onMouseLeave={() => setHover(null)}
      style={{
        color: hover === route || param1 === route ? "red" : "white",
      }}
    >
      {title}
    </Nav.Link>
  );
};

const LoginSignUpOptions = ({ title, route, toggleModal, setShowForm }) => {
  const [hover, setHover] = useState(null);
  const handleClick = () => {
    setShowForm(route);
    toggleModal();
  };

  return (
    <Nav.Link
      className="text-center py-2 mx-xl-2 border-primary"
      onMouseEnter={() => setHover(route)}
      onMouseLeave={() => setHover(null)}
      onClick={handleClick}
      style={{ color: hover === route ? "red" : "white" }}
    >
      {title}
    </Nav.Link>
  );
};

const MovingNavbar = ({ showForm, setShowForm }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);

  const navbarRef = useRef(null);
  useOutsideClickAndScroll(navbarRef, () => setIsNavbarCollapsed(old => old ? false : old));

  const handleLogout = async () => {
    dispatch(authSlices.userLogout());
    navigate("/");
  };

  const toggleModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const navbarItems = [
    { id: 1, title: "Home", route: "" },
    { id: 2, title: "Movies", route: "movies" },
    { id: 3, title: "About", route: "about" },
    { id: 4, title: "Our Cinemas", route: "our-cinemas" },
    { id: 5, title: "Contact", route: "contact" },
  ];

  const loginSignupOptions = [
    { id: 1, title: "Login", route: "login" },
    { id: 2, title: "Sign Up", route: "signup" },
  ];

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="transparent"
      ref={navbarRef}
      onToggle={setIsNavbarCollapsed}
      expanded={isNavbarCollapsed}
    >
      <Navbar.Brand href="/" className="d-flex align-items-center">
        <img src={mcine} alt="" className="img-fluid me-2" />
      </Navbar.Brand>

      {
        !large ? <Navbar.Toggle
          aria-controls="navbarScroll"
          className="p-1 border-0"
          onClick={() => setIsNavbarCollapsed(old => !old)}
        >
          <CgMenu size={medium ? 28 : small ? 24 : 22} color="#fff" />
        </Navbar.Toggle> : <></>
      }

      <Navbar.Collapse
        id="navbarScroll"
        className={`${large ? "d-flex flex-column justify-content-end align-items-end position-static" : "bg-extradark pb-4 position-fixed"}`}
        style={large ? { borderRadius: 10, flex: 1 } : { borderRadius: 10, zIndex: 99, top: '12vh', right: 0, width: "100%" }}
      >
        <div className={`d-flex flex-column flex-lg-row justify-content-center align-items-center ${large ? '' : 'bg-extradark pb-4'}`}>
          <Nav className={`${large ? "ms-auto my-2 my-lg-0 font-inter" : "my-2 my-lg-0 font-inter"}`} navbarScroll style={{ fontWeight: 600, fontSize: "14px" }}>
            {isAuthenticated ? (
              <>
                <div className="ms-3">
                  <NavLink title={`Hello ${user?.first_name}!`} route={"account/profile"} />
                </div>
                {large && (
                  <div className="ms-3">
                    <NavLink title="Logout" route="logout" handleLogout={handleLogout} />
                  </div>
                )}
              </>
            ) : (
              loginSignupOptions.map((item, i) => (
                <div key={i} className="ms-3">
                  <LoginSignUpOptions title={item.title} route={item.route} toggleModal={toggleModal} 
                    setShowForm={setShowForm} />
                </div>
              ))
            )}
          </Nav>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
          <Nav className={`${large ? "ms-auto mt-2 my-lg-0 font-inter" : "my-2 my-lg-0 font-inter"}`} navbarScroll style={{ fontWeight: 600, fontSize: "14px" }}>
            {navbarItems.map((item, i) => (
              <div key={i} className="ms-3 d-flex justify-content-center align-items-center">
                <NavLink title={item.title} route={item.route} />
              </div>
            ))}
            {isAuthenticated && !large && (
              <div className="ms-3">
                <NavLink title="Logout" route="logout" handleLogout={handleLogout} />
              </div>
            )}
            <div
              className="position-relative ms-3 my-3 cursor-pointer"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => navigate(`/movies`)}
            >
              <Image src={isHovered ? ticket_background_hover : ticket_background} width={160} height={40} />
              <div className="position-absolute d-flex justify-content-center align-items-center" style={{ top: 0, width: 160, height: 40, color: isHovered ? "#ec2f23" : "white" }}>
                <span className="me-2"><IoTicketOutline /></span>
                Buy Tickets
              </div>
            </div>
          </Nav>
        </div>
      </Navbar.Collapse>

      <LoginModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} showForm={showForm} setShowForm={setShowForm} />
    </Navbar>
  );
};

export default MovingNavbar;
