import React from "react";
import { Container, Spinner, Image } from 'react-bootstrap';
import { movie_loader } from "assets";

const Loader = ({ faded }) => {
    return (
        <Container
            fluid
            className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
            style={styles.parentContainer}
        >
            <Image src={movie_loader} loading="lazy" />
        </Container>
    );
}

const styles = {
    parentContainer: {
        zIndex: 9999,
        top: 0,
        bottom: 0,
        background: "#000000",
    }
}

export default Loader;