import "./App.css";
import "./styles.scss";
import Routers from "./Router";
import { Provider } from "react-redux";
import store, { persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from 'react-ga4';
import { useEffect, useRef } from "react";
import log from 'loglevel';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  log.setLevel('silent');
} else {
  log.setLevel('debug');
}

function App() {

  useEffect(() => {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
    } else {
      log.error("Google Analytics Tracking ID is not defined.");
    }
  }, []);

  if (GOOGLE_CLIENT_ID) {
    return (
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            <Routers />
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    );
  }

  return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />
          <Routers />
        </PersistGate>
      </Provider>
  );
}

export default App;