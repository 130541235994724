import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IoInformationCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const ShowCard = ({ theatre, shows, movieId }) => {
  const navigate = useNavigate();
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const facilities = ["Food", "Parking", "Mobile Ticket"]

  return (
    // <div className="bg-extradark p-2 mb-4 border border-darkgrey rounded">
    <div className="bg-extradark p-2 pt-4 mb-4 rounded">
      <Row className="m-0">
        {theatre && <Col lg={3}>
          <p className="text-white mb-1">
            {theatre?.title}
            {/* <IoInformationCircleSharp className="ms-1 text-grey" /> */}
          </p>
          {/* <small>
            {facilities?.map((item, i) => (
              <span key={i} className="text-green me-1">
                {`${item}${facilities?.length === i ? "" : ", "}`}
              </span>
            ))}
          </small> */}
        </Col>}
        <Col lg={theatre ? 9 : 12} className="p-0">
          <Row className={`${medium ? "row-cols-4" : "row-cols-3"} w-100 m-0 my-2 mt-lg-0 d-flex`}>
            {shows?.map((item, i) => (
              <Col
                sm={3}
                md={3}
                lg={3}
                key={i}
                className="mb-5"
                onClick={() => navigate(`/show/${movieId}/seats/${item.id}/`)}
                style={{ height: "auto", borderRadius: 10 }}
              >
                <div
                  className="px-2 py-3 d-flex flex-column justify-content-center align-items-center rounded border-white p-2 cursor-pointer position-relative hover-dark"
                >
                  {/* {item?.start_time && <p className="text-white mb-0">{moment(item?.start_time).format("HH:mm")}</p>} */}
                  <small
                    className="text-lightgrey px-2 mb-0 position-absolute bg-extradark"
                    style={{ top: "-10px", zIndex: 99 }}
                  >
                    {item?.format_type_data?.title}
                  </small>
                  <small className="text-white mb-0">{moment(item?.start_time, "HH:mm:ss").format('hh:mm A')}</small>
                  <small className="text-lightgrey mb-0">{item?.language_data?.title}</small>
                  {/* <span className="text-lightgrey mb-0">{item?.hall_data?.hall_no}</span> */}
                  <small
                    className="text-lightgrey px-2 mb-0 position-absolute bg-extradark text-center"
                    style={{ top: "85%", maxWidth: "75%" }}
                  >
                    {item?.hall_data?.hall_no}
                  </small>
                  {/* <small 
                    className="text-lightgrey px-2 mb-0"
                  >
                    {item?.hall_data?.hall_no}
                  </small> */}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ShowCard;
