import React from 'react';
import styles from './NoShows.module.css';
import { no_shows_animation_1, no_shows_animation_2 } from 'assets';

const NoShows = () => {
    return (
        <div className={styles.container}>
            <div>
                <img src={no_shows_animation_2} alt="" />
            </div>
            <p className="text-white">
                No shows available for the day!
            </p>
        </div>
    );
};

export default NoShows;