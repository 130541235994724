import { notify } from "Utilities/Notifications/Notify";
import SeatSvg20 from "./SeatSvg20";
import { BOOKED_SEAT_COLOR, LOCKED_SEAT_COLOR, NO_EMPTY_SEAT_MESSAGE } from "./utils";
import log from "loglevel";

const SeatDesign = ({
    row,
    column,
    allSeats,
    seat,
    totalSeatsSelected,
    defaultColor,
    color,
    bookedSeats,
    setBookedSeats,
}) => {
    const seat_status = bookedSeats.filter(
        (bookedSeat) => bookedSeat.id === seat.id
    );
    const availableSeats = allSeats
        .flat()
        .filter((x) => x.status === "AVAILABLE");
    let next_seat = column;
    // alert(availableSeats.length);
    // log.log(allSeats);

    return (
        <div
            className={`m-1 d-flex justify-content-center align-items-center position-relative ${seat?.status === null ||
                seat?.status === "BOOKED" ||
                seat?.status === "LOCKED"
                ? ""
                : "cursor-pointer"
                }`}
            onClick={() => {
                // let new_array = []
                // if (seat?.status !== null && seat?.status !== "BOOKED" && seat?.status !== "LOCKED") {
                //   if (!seat_status.length > 0) {
                //     if (bookedSeats.length + 1 <= totalSeatsSelected) {
                //       while (allSeats[row][next_seat] && (allSeats[row][next_seat].status !== "BOOKED" && allSeats[row][next_seat].status !== "LOCKED" && allSeats[row][next_seat].status !== null)) {
                //         if (new_array.length + bookedSeats.length + 1 <= totalSeatsSelected) {
                //           new_array.push(allSeats[row][next_seat])
                //           next_seat += 1
                //         } else {
                //           break
                //         }
                //       }
                //       setBookedSeats([...bookedSeats, ...new_array])
                //     }
                //   } else {
                //     setBookedSeats(bookedSeats.filter(bookedSeat => bookedSeat.id !== seat.id))
                //   }
                // }

                let _bookedSeats = [...bookedSeats];

                if (
                    seat?.status !== null &&
                    seat?.status !== "BOOKED" &&
                    seat?.status !== "LOCKED"
                ) {
                    let leftpointer = 0,
                        rightpointer = 0,
                        seats_counter_pointer = 0,
                        seatsCount = totalSeatsSelected - _bookedSeats.length;
                    if (seatsCount === 0) {
                        _bookedSeats = [];
                        seatsCount = totalSeatsSelected;
                    }
                    let seatAvailable = (index) => {
                        let availabilityStatus =
                            allSeats[row][index] &&
                            allSeats[row][index].status !== "BOOKED" &&
                            allSeats[row][index].status !== "LOCKED" &&
                            allSeats[row][index].status !== null;
                        // log.log(_bookedSeats);
                        let selectedStatus = _bookedSeats.some(
                            (x) => x.row_index === row && x.column_index === index
                        );
                        // log.log(_bookedSeats && !selectedStatus);
                        return availabilityStatus && !selectedStatus;
                    };

                    let new_array = [];
                    let readyToBook = [];
                    let tempCountCheck =
                        seatsCount === 0 ? totalSeatsSelected : seatsCount;
                    for (let i = next_seat; i < allSeats[row].length; i++) {
                        if (seatAvailable(i)) {
                            if (readyToBook.length < tempCountCheck) {
                                readyToBook.push(i);
                                new_array.push(allSeats[row][i]);
                                seats_counter_pointer += 1;
                            } else {
                                rightpointer += 1;
                            }
                        } else {
                            break;
                        }
                    }
                    log.log(next_seat);
                    for (let i = next_seat - 1; i >= 0; i--) {
                        if (seatAvailable(i)) {
                            leftpointer += 1;
                        } else {
                            break;
                        }
                    }
                    let let_book_the_seat = false;
                    if (
                        (leftpointer === 1 && rightpointer === 0) ||
                        (leftpointer === 0 && rightpointer === 1)
                    ) {
                        log.log(availableSeats.length, _bookedSeats.length, seatsCount);
                        // if (
                        //     !(availableSeats.length - _bookedSeats.length === seatsCount + 1)
                        // ) {
                        //     // alert("Hello");
                        //     if (leftpointer === 1) {
                        //         notify(NO_EMPTY_SEAT_MESSAGE);
                        //         return;
                        //     } else if (rightpointer === 1) {
                        //         notify(NO_EMPTY_SEAT_MESSAGE);
                        //         return;
                        //     }
                        // } else {
                        //     let_book_the_seat = true;
                        // }
                        let_book_the_seat = true;
                    }

                    if (!let_book_the_seat) {
                        if (leftpointer === 1) {
                            notify(NO_EMPTY_SEAT_MESSAGE);
                            return;
                        } else if (rightpointer === 1) {
                            notify(NO_EMPTY_SEAT_MESSAGE);
                            return;
                        }
                    }

                    log.log(leftpointer, seats_counter_pointer, rightpointer);

                    if (seatsCount === 0) {
                        setBookedSeats([...new_array]);
                    } else {
                        // if(new_array.length > _bookedSeats.length) {
                        //   setBookedSeats(new_array);
                        // }
                        setBookedSeats([..._bookedSeats, ...new_array]);
                    }
                }
            }}
        >
            <SeatSvg20
                color={
                    seat?.status === "BOOKED"
                        ? BOOKED_SEAT_COLOR
                        : seat?.status === "LOCKED"
                            ? LOCKED_SEAT_COLOR
                            : !seat?.status
                                ? "#13151f"
                                : seat_status.length > 0
                                    ? color
                                    : defaultColor
                }
            />
            {/* <div className={`user-select-none position-absolute pb-2 fs-6 ${seat?.status === "BOOKED" || seat?.status === "LOCKED" ? "text-white" : ""}`}>
        {seat?.display_label}
      </div> */}
        </div>
    );
};

export default SeatDesign;