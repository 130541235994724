import Carousel from "react-simply-carousel";
import { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import MovieCard from "./MovieCard";
import { useMediaQuery } from "react-responsive";

const MovieCarousel = (props) => {
  const { cards, setOpenModal, setCurrentFilm } = props;
  const [activeSlide, setActiveSlide] = useState(0);
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    opacity: 1,
    transform: show ? "scale(1.03)" : "scale(1)",
  });

  useEffect(() => {
    setCurrentFilm(activeSlide);
  }, [activeSlide]);

  return (
    <Carousel
      containerProps={{
        style: {
          width: "100%",
          justifyContent: "space-between",
          userSelect: "none",
          background: "transparent",
        },
      }}
      forwardBtnProps={{ style: styles.carouselButton }}
      backwardBtnProps={{ style: styles.carouselButton }}
      swipeTreshold={60}
      activeSlideIndex={activeSlide}
      onRequestChange={setActiveSlide}
      itemsToShow={2}
      speed={400}
      centerMode
      updateOnItemClick={true}
      autoplay={true}
      autoplayDelay={4000}
      infinite={true}
    >
      {cards.map((card, index) => {
        let thisClassName = "";
        if (activeSlide === index) {
          thisClassName = "";
        } else if (activeSlide === (cards.length + index - 1) % cards.length) {
          thisClassName = "right-movie-card-rotate";
        } else if (activeSlide === (index + 1) % cards.length) {
          thisClassName = "left-movie-card-rotate d-flex";
        }
        return (
          <div className="perspective-div" key={card.key || index}>
            <animated.div
              index={index}
              
              className={`${thisClassName}`}
              style={props3}
              onMouseEnter={() => {
                if (index === activeSlide) setShown(true);
              }}
              onMouseLeave={() => {
                if (index === activeSlide) setShown(false);
              }}
            >
              <MovieCard
                movie={card}
                setOpenModal={setOpenModal}
              />
            </animated.div>
          </div>
        );
      })}
    </Carousel>
  );
};

const styles = {
  carouselButton: {
    width: 0,
    height: 0,
    minWidth: 0,
    border: 0,
    boxShadow: 0,
    background: "transparent",
  },
};

export default MovieCarousel;
