import React, { useEffect, useRef, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
// import FormatSelectionModal from "website/components/Movie/FormatSelectionModal";
import MovieDetailsShowcase from "website/components/Movie/MovieDetailsShowcase";
import { useNavigate, useParams } from "react-router";
import * as authActions from "Redux/Actions/authActions";
import { default_cast_crew } from "assets";
import { Helmet } from "react-helmet";

const scrollLeft = (container) => {
  if (container) {
    container.scrollLeft -= 200;
  }
};

const scrollRight = (container) => {
  if (container) {
    container.scrollLeft += 200;
  }
};

const MovieDetails = () => {

  const { movieId } = useParams();
  const navigate = useNavigate()
  const castContainer = useRef(null);
  const crewContainer = useRef(null);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [open, setOpen] = useState(false);
  const [movieData, setMovieData] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [filmTrailer, setFilmTrailer] = useState(null);

  const [showCrewLeftRight, setShowCrewLeftRight] = useState(true);
  const [showCastLeftRight, setShowCastLeftRight] = useState(true);

  const clientId = process.env.REACT_APP_CLIENT_ID;

  const fetchMovie = async (e) => {
    const response = await authActions.fetchClientProgram(movieId);
    if (response.status === 200) {
      setMovieData(response.data)
      setFilmTrailer(response.data.trailer_link)
    }
  };

  const crewLeft = () => scrollLeft(crewContainer.current);
  const crewRight = () => scrollRight(crewContainer.current);
  const castLeft = () => scrollLeft(castContainer.current);
  const castRight = () => scrollRight(castContainer.current);
  const seenCast = new Set();
  const seenCrew = new Set();

  useEffect(() => {
    if (movieId) {
      fetchMovie()
    }
  }, [movieId]);

  useEffect(() => {
    if (castContainer.current?.scrollWidth > castContainer.current?.clientWidth) {
      setShowCastLeftRight(true);
    } else {
      setShowCastLeftRight(false);
    }

    if (crewContainer.current?.scrollWidth > crewContainer.current?.clientWidth) {
      setShowCrewLeftRight(true);
    } else {
      setShowCrewLeftRight(false);
    }
  }, [movieData]);

  return (
    <>
      <Helmet>
        <title>{movieData?.title ? `MCine | Movies > ${movieData?.title}` : 'MCine | Movies'}</title>
      </Helmet>
      <div>
        <div className="bg-slate">
          <div className="wrapper">
            <ul className="breadcrumb subtitle mx-1 pb-4">
              <li><a href="/">Home</a></li>
              <li><a href="/movies">Movies</a></li>
              <li>{movieData.title}</li>
            </ul>
          </div>
          <MovieDetailsShowcase
            title={movieData?.title}
            image={movieData?.poster_image}
            genres={movieData?.program_data?.genres}
            runtime={movieData?.runtime}
            releaseDate={movieData?.release_date}
            rating={movieData?.rating}
            showRating={movieData?.show_rating}
            certification={movieData?.certification_data?.title}
            showCertification={movieData?.show_certification}
            showBookBtn={true}
            onClickBook={() => navigate(`/show/${movieId}`)}
            setOpenModal={setOpenModal}
            nextShow={movieData?.nextShow}
            languages={movieData?.languages}
          />
          <div className="wrapper">
            <div className="py-5">
              <div className="mb-4">
                <h2 className="text-white">About</h2>
                <div className="d-flex">
                  <p className="text-feather">{movieData?.overview}</p>
                </div>
              </div>
              <div className="mb-4">
                <h2 className="text-white mb-4">Cast</h2>
                <div className="d-flex align-items-center mb-3 position-relative">
                  {showCrewLeftRight &&
                    <div
                      className="position-absolute top-0 start-0 h-100 d-flex justify-content-center align-items-center"
                      onClick={crewLeft}
                      disabled={!small}
                      style={{
                        width: 30,
                        color: "white",
                        // background: "rgb(2, 0, 36)",
                        background: "linear-gradient(90deg, #13151F 60%, rgba(13, 21, 19, 0.098) 100%)",
                        cursor: "pointer"
                      }}
                    >
                      {"<"}
                    </div>
                  }
                  <div
                    ref={crewContainer}
                    className="d-flex overflow-x-scroll scrollbar-none"
                    style={{ flexWrap: "nowrap", padding: "10px 20px", marginBottom: "10px", scrollBehavior: "smooth" }}
                  >
                    {/* {movieData?.program_data?.cast?.map((item, i) => (
                      <ProfileCard
                        key={i}
                        title={item?.name}
                        image={item?.profile_path ? process.env.REACT_APP_TMDB_URL + item?.profile_path : default_cast_crew}
                        className="me-3"
                      />
                    ))} */}
                    {movieData?.program_data?.cast
                      ?.filter(item => {
                        const key = item?.name + item?.profile_path;
                        if (seenCast.has(key)) {
                          return false;
                        }
                        seenCast.add(key);
                        return true;
                      })
                      .map((item, i) => (
                        <ProfileCard
                          key={i}
                          title={item?.name}
                          image={item?.profile_path ? process.env.REACT_APP_TMDB_URL + item?.profile_path : default_cast_crew}
                          className="me-3"
                        />
                      ))}
                  </div>
                  {showCrewLeftRight &&
                    <div
                      className="position-absolute top-0 end-0 h-100 d-flex justify-content-center align-items-center"
                      onClick={crewRight}
                      disabled={!small}
                      style={{
                        width: 30,
                        color: "white",
                        // background: "rgb(2, 0, 36)",
                        background: "linear-gradient(270deg, #13151F 60%, rgba(13, 21, 19, 0.098) 100%)",
                        cursor: "pointer"
                      }}
                    >
                      {">"}
                    </div>
                  }
                </div>
              </div>
              <div>
                <h2 className="text-white mb-4">Crew</h2>
                <div className="d-flex align-items-center mb-3 position-relative">
                  {showCrewLeftRight &&
                    <div
                      className="position-absolute top-0 start-0 h-100 d-flex justify-content-center align-items-center"
                      onClick={castLeft}
                      disabled={!small}
                      style={{
                        width: 30,
                        color: "white",
                        // background: "rgb(2, 0, 36)",
                        background: "linear-gradient(90deg, #13151F 60%, rgba(13, 21, 19, 0.098) 100%)",
                        cursor: "pointer"
                      }}
                    >
                      {"<"}
                    </div>
                  }
                  <div
                    ref={castContainer}
                    className="d-flex overflow-x-scroll scrollbar-none"
                    style={{ flexWrap: "nowrap", padding: "10px 20px", marginBottom: "10px", scrollBehavior: "smooth" }}
                  >
                    {/* {movieData?.program_data?.crew?.map((item, i) => (
                      <ProfileCard
                        key={i}
                        title={item?.name}
                        image={item?.profile_path ? process.env.REACT_APP_TMDB_URL + item?.profile_path : default_cast_crew}
                        className="me-3"
                      />
                    ))} */}
                    
                    {movieData?.program_data?.crew
                      ?.filter(item => {
                        const key = item?.name + item?.profile_path;
                        if (seenCrew.has(key)) {
                          return false;
                        }
                        seenCrew.add(key);
                        return true;
                      })
                      .map((item, i) => (
                        <ProfileCard
                          key={i}
                          title={item?.name}
                          image={item?.profile_path ? process.env.REACT_APP_TMDB_URL + item?.profile_path : default_cast_crew}
                          className="me-3"
                        />
                      ))}
                  </div>
                  {showCrewLeftRight &&
                    <div
                      className="position-absolute top-0 end-0 h-100 d-flex justify-content-center align-items-center"
                      onClick={castRight}
                      disabled={!small}
                      style={{
                        width: 30,
                        color: "white",
                        // background: "rgb(2, 0, 36)",
                        background: "linear-gradient(270deg, #13151F 60%, rgba(13, 21, 19, 0.098) 100%)",
                        cursor: "pointer"
                      }}
                    >
                      {">"}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <Modal
            contentClassName="bg-transparent border-0"
            show={openModal}
            onHide={() => setOpenModal(false)}
            animation={true}
            centered
            size="lg"
          >
            <Modal.Header className="border-0" closeButton closeVariant="white"></Modal.Header>
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center p-0">
              {filmTrailer ?
                <iframe
                  width="90%"
                  height="450"
                  src={filmTrailer}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                :
                <div className="text-white mt-4 mb-5 pt-4 pb-5 d-flex justify-content-center">
                  Trailer not available!
                </div>
              }
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

const styles = {
  parentContainer: {
    padding: "30px 100px 0px 100px",
    // padding: "40px 100px",
  },
  mobileParentContainer: {
    padding: "20px 10px",
  },
};

export default MovieDetails;

const ProfileCard = ({ title, image, className }) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <div
      className={`text-center ${className}`}
      style={{ minWidth: large ? 140 : medium ? 128 : small ? 128 : 120 }}
    >
      <div
        className="overflow-hidden mb-2 border border-dark mx-auto"
        style={{
          width: large ? 100 : medium ? 90 : small ? 80 : 74,
          height: large ? 100 : medium ? 90 : small ? 80 : 74,
          borderRadius: "50%",
        }}
      >
        <img
          src={image}
          alt={title}
          className="img-fluid"
          style={{
            objectFit: "cover",
          }}
        />
      </div>
      <p className="text-white">{title}</p>
    </div>
  );
};
