import log from 'loglevel';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import * as authActions from "Redux/Actions/authActions";
import * as authSlices from "Redux/AuthReducer/authSlices";
import { notify } from 'Utilities/Notifications/Notify';
import { onInputTextShouldBeLowerCase } from 'utils';

const LoginWithOtp = ({ setShowLoginModal }) => {
    const form = useRef(null);
    const dispatch = useDispatch();
    const timerRef = useRef(null);

    const [otpSent, setOtpSent] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resending, setResending] = useState(false);
    const [timer, setTimer] = useState(0);
    const [email, setEmail] = useState("");

    const handleSendOtp = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const formData = new FormData(form.current);
        let response = null;
        if (!isNaN(parseFloat(formData.get("username")))) {
            response = await authActions.sendOtp({
                mobile: formData.get("username"),
            });
        } else if (formData.get("username").includes("@")) {
            response = await authActions.sendOtp({
                email: formData.get("username"),
            });
        }
        if (response) {
            switch (response.status) {
                case "SUCCESSFUL":
                    setOtpSent(true);
                    notify(response.msg, "success");
                    setIsSubmitting(true);
                    setTimer(120);
                    break;
                default:
                    // notify("Error while sending OTP, Try again later", "error");
                    break;
            }
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (timer > 0) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [timer, setTimer]);

    const loginWithOtp = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const formData = new FormData(form.current);
        let response = null;
        if (!isNaN(parseFloat(formData.get("username")))) {
            response = await authActions.otpLogin({
                mobile: formData.get("username"),
                otp: formData.get("otp"),
            });
        } else if (formData.get("username").includes("@")) {
            response = await authActions.otpLogin({
                email: formData.get("username"),
                otp: formData.get("otp"),
            });
        }
        if (response) {
            switch (response.status) {
                case "SUCCESSFUL":
                    dispatch(authSlices.saveLoginInfo(response))
                    setOtpSent(false);
                    setShowLoginModal(false);
                    setIsSubmitting(false);
                    break;
                default:
                    notify("Error while sending OTP, Try again later", "error");
            }
        }
        setIsSubmitting(false);
    };

    const resendOtpRequest = async (e) => {
        e.preventDefault();
        setResending(true);
        if (isSubmitting) return;
        const response = await authSlices.resendLoginOtp({
            email: email,
        });
        if (response) {
            log.log(response);
            setTimer(120);
        }
        setResending(false);
    }

    return (
        <Form
            className="text-start w-100"
            ref={form}
            onSubmit={(e) => {
                if (otpSent) {
                    loginWithOtp(e)
                } else {
                    handleSendOtp(e)
                }
            }}
        >

            <Form.Group className="mb-3" controlId="loginForm.email">
                <Form.Label className="text-white">Email/Phone</Form.Label>
                <Form.Control
                    type="text"
                    name="username"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                    className="bg-extradark py-2 border-grey text-lightgrey"
                    onInput={onInputTextShouldBeLowerCase}
                />
            </Form.Group>

            {otpSent &&
                <>
                    <Form.Group className="mb-3" controlId="loginForm.password">
                        <Form.Label className="text-white">OTP</Form.Label>
                        <Form.Control
                            type="password"
                            name="otp"
                            className="bg-extradark py-2 border-grey text-lightgrey"
                            required
                        />
                    </Form.Group>
                    <div className="mb-3 d-flex justify-content-center align-items-center">
                        <div
                            className={`${timer !== 0 ? "text-lightgrey" : "text-white"} ${!resending ? "cursor-pointer" : "cursor-not-allowed"}`}
                            onClick={(e) => {
                                if (timer === 0 && !resending) {
                                    resendOtpRequest(e);
                                }
                            }}
                        >
                            {resending ? "Resending..." : "Resend OTP"}
                        </div>
                        <div
                            className={`${timer !== 0 ? "text-white mx-2 caption" : "d-none"
                                }`}
                        >
                            {`in ${timer} seconds`}
                        </div>
                    </div>
                </>
            }
            {/* {
                    otpSent && <p>Resend</p>
                  } */}
            <Button
                className="bg-primary border-primary w-100 custom-button mt-2 py-2"
                type="submit"
                disabled={resending || isSubmitting}
            >
                {isSubmitting ? (
                    <span>
                        <Spinner size="sm" className="me-2" />
                        {otpSent ? "LOGGING IN ..." : "SENDING ..."}
                    </span>
                ) : (
                    <span>{otpSent ? "LOGIN" : "SEND OTP"}</span>
                )}
            </Button>
        </Form>
    )
}

export default LoginWithOtp