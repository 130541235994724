import { Button, Form, Modal } from "react-bootstrap";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authSlices from "Redux/AuthReducer/authSlices";
import * as authActions from "Redux/Actions/authActions";
import { asyncStatuses } from "Redux/enums";
import { notify } from "Utilities/Notifications/Notify";
import { RxCross2 } from "react-icons/rx";
import SignupForm from "./SignupForm";
import { IoIosArrowBack } from "react-icons/io";
import NoLoginForm from "./NoLoginForm";
import ForgotPassword from "website/pages/Auth/ForgotPassword";
import log from "loglevel";
import LoginForm from "./LoginForm";
import VerifyForm from "./VerifyForm";
import ReactGA from 'react-ga4';


const LoginModal = ({ showLoginModal, setShowLoginModal, showForm, setShowForm, showNoLogin = false, email, setEmail, phone, setPhone, phoneCode, setPhoneCode, setAllow, setNoLoginUserId, checkoutMode = false }) => {
  const form = useRef(null);
  const dispatch = useDispatch();

  const loginRequestStatus = useSelector((store) => store.auth.status);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [showForm, setShowForm] = useState("login");
  const [signedUpEmail, setSignedUpEmail] = useState("");
  const [timer, setTimer] = useState(0);

  const [checked, setChecked] = useState(false);

  // useEffect(() => {
  //   showLoginModal(true);
  //   setShowLoginModal(show);
  // }, [show]);

  useEffect(() => {
    log.log({ loginRequestStatus, setShowLoginModal, dispatch })
    if (loginRequestStatus === asyncStatuses.LOADING) {
      // notify("Login failed");
    } else if (loginRequestStatus === asyncStatuses.SUCCESS) {
      form.current?.reset();
      setShowLoginModal(false);
      setIsSubmitting(false);
    } else if (loginRequestStatus === asyncStatuses.FAILED) {
      setIsSubmitting(false);
      dispatch(authSlices.resetAuthSlice());
    }
  }, [loginRequestStatus, setShowLoginModal, dispatch]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    const formData = new FormData(form.current);
    log.log(formData);

    if (formData.get("password") !== formData.get("password1")) {
      notify("Passwords did not match", "error");
      setIsSubmitting(false);
      return;
    }

    if (checked) {
      for (const value of formData.values()) {
        if (value === "" || value === null) {
          setIsSubmitting(false);
          notify("Please enter all the values");
          return false;
        }
      }
      formData.append("username", formData.get("email"));
      const response = await authSlices.signingUp(formData);
      log.log(response);
      if (response.status === 201) {
        form.current.reset();
        setIsSubmitting(false);
        notify("Account created successfully", "success");
        setSignedUpEmail(formData.get("email"));
        setShowForm("verify");
        setTimer(120);
      } else if (response?.status === 406) {
        notify(response?.data?.msg, "error");
      } else if (response?.status === 400) {
        notify("Please enter the valid data!", "error");
      } else {
        notify("There was some problem while signing up!", "error");
      }
      setIsSubmitting(false);
    } else {
      notify("Please agree to the terms and conditions", "warning");
      setIsSubmitting(false);
    }
  };

  const toggleToLogin = () => {
    setShowForm("login");
    setShowLoginModal(true);
  };

  const onModalHide = useCallback(() => {
    setShowForm("login");
    setShowLoginModal(false);
    
    ReactGA.event({
      category: "login_flow",
      action: "user_flow_cancelled",
      label: "User Flow Cancelled", // optional
    });
  }, [setShowForm, setShowLoginModal]);

  return (
    <Modal
      dialogClassName="d-flex align-items-center h-100"
      contentClassName="bg-dark"
      show={showLoginModal}
      onHide={onModalHide}
      animation={false}
    >
      <Modal.Header closeButton={false} className="d-flex justify-content-between border-grey d-flex ">
        {showForm === "login" ? null : (
          <div onClick={toggleToLogin} className="text-white cursor-pointer">
            <IoIosArrowBack />
          </div>
        )}
        <Modal.Title className="text-white">
          {showForm === "login"
            ? "Login"
            : showForm === "signup"
              ? "Signup"
              : showForm === "forgotpassword"
                ? "Forgot Password"
                : showForm === "verify"
                  ? "Verify Account"
                  : showForm === "nologin"
                    ? "Verify Information"
                    : ""}
        </Modal.Title>
        <div
          onClick={onModalHide}
          className="text-white"
        >
          <RxCross2 className="cursor-pointer" />
        </div>
      </Modal.Header>

      <Modal.Body className="px-3">
        <div className="bg-dark border-grey rounded">
          {showForm === "login" ? (
            <LoginForm 
              checkoutMode={checkoutMode} 
              setAllow={setAllow} 
              setShowLoginModal={setShowLoginModal} 
              setShowForm={setShowForm}
            />
          ) : showForm === "signup" ? (
            <SignupForm
              form={form}
              handleSignUp={handleSignUp}
              checked={checked}
              setChecked={setChecked}
              isSubmitting={isSubmitting}
              setShowForm={setShowForm}
            />
          ) : showForm === "verify" ? (
            <VerifyForm 
              setShowForm={setShowForm} 
              signedUpEmail={signedUpEmail} 
              setShowLoginModal={setShowLoginModal} 
              timer={timer} 
              setTimer={setTimer}
            />
          ) : showForm === "forgotpassword" ? (
            <ForgotPassword setShowForm={setShowForm} />
          ) : showForm === "nologin" ? (
            <NoLoginForm email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} phoneCode={phoneCode} setPhoneCode={setPhoneCode} setShowForm={setShowForm} setShowLoginModal={setShowLoginModal} setAllow={setAllow} setNoLoginUserId={setNoLoginUserId} />
          ) : null}
        </div>
        
        {showNoLogin && showForm !== "nologin" &&
          <>
            <div className="d-flex align-items-center">
              <div
                className="w-100"
                style={{ height: 1, backgroundColor: "#FFFFFF" }}
              ></div>
              <div className="text-white mx-2 fs-10px">OR</div>
              <div
                className="w-100"
                style={{ height: 1, backgroundColor: "#FFFFFF" }}
              ></div>
            </div>
            <div
              onClick={() => {
                setShowForm("nologin");
                
                ReactGA.event({
                  category: "login_flow",
                  action: "continue_without_login",
                  label: "Continue Without Login", // optional
                });
              }}
              className="cursor-pointer text-bold d-flex justify-content-center align-items-center my-4"
            >
              <u className="text-white"><p className="m-0">Continue without Login</p></u>
            </div>
          </>
        }
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
