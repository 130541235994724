import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Card,
    Image,
    Spinner,
    Modal,
    Button,
    Carousel 
} from "react-bootstrap";
import * as authActions from "Redux/Actions/authActions";
import moment from "moment";
import { movie_placeholder, our_cinemas } from "assets";
import { FaDivide, FaTicketAlt } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { getCorrectImageUrl } from "utils";
import log from "loglevel";

const SeatSvg = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.63636 20C3.37879 20 3.16303 19.8933 2.98909 19.68C2.81515 19.4667 2.72788 19.203 2.72727 18.8889V17.7778C1.9697 17.7778 1.32576 17.4537 0.795454 16.8056C0.265152 16.1574 0 15.3704 0 14.4444V8.88889C0 8.27778 0.178182 7.75481 0.534545 7.32C0.890909 6.88518 1.31879 6.66741 1.81818 6.66667C2.31818 6.66667 2.74636 6.88444 3.10273 7.32C3.45909 7.75555 3.63697 8.27852 3.63636 8.88889V13.3333H16.3636V8.88889C16.3636 8.27778 16.5418 7.75481 16.8982 7.32C17.2545 6.88518 17.6824 6.66741 18.1818 6.66667C18.6818 6.66667 19.11 6.88444 19.4664 7.32C19.8227 7.75555 20.0006 8.27852 20 8.88889V14.4444C20 15.3704 19.7348 16.1574 19.2045 16.8056C18.6742 17.4537 18.0303 17.7778 17.2727 17.7778V18.8889C17.2727 19.2037 17.1855 19.4678 17.0109 19.6811C16.8364 19.8944 16.6206 20.0007 16.3636 20C16.1061 20 15.8903 19.8933 15.7164 19.68C15.5424 19.4667 15.4551 19.203 15.4545 18.8889V17.7778H4.54545V18.8889C4.54545 19.2037 4.45818 19.4678 4.28364 19.6811C4.10909 19.8944 3.89333 20.0007 3.63636 20ZM5.45454 11.1111V8.88889C5.45454 7.87037 5.20091 6.95815 4.69364 6.15222C4.18636 5.3463 3.53091 4.77704 2.72727 4.44444V3.33333C2.72727 2.40741 2.99242 1.62037 3.52273 0.972222C4.05303 0.324074 4.69697 0 5.45454 0H14.5455C15.303 0 15.947 0.324074 16.4773 0.972222C17.0076 1.62037 17.2727 2.40741 17.2727 3.33333V4.44444C16.4545 4.7037 15.7955 5.24556 15.2955 6.07C14.7955 6.89444 14.5455 7.83407 14.5455 8.88889V11.1111H5.45454Z"
                fill="red"
            />
        </svg>
    )
}

const OrderCard = ({ order, allow_image_navigate = true }) => {
    const navigate = useNavigate();
    const [downloading, setDownloading] = useState(false);
    const [allowDownload, setAllowDownload] = useState(true);
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const today = new Date();
    const [showModal, setShowModal] = useState(false);
    const containerStyle = medium ? { justifyContent: 'space-between' } : {};
    const itemStyle = { minWidth: 0, margin: medium ? '0 10px' : '0 5px' };

    const downloadTicket = async () => {
        const response = await authActions.getTicketPdf(order.id)
        if (response) {
            setDownloading(false)
        }
    }

    useEffect(() => {
        if (order) {
            const today = new Date();

            let _today = moment(today).format("YYYY-MM-DD");
            let _show_date = moment(order?.show_data?.date).format("YYYY-MM-DD");
            let _show_start_time = order?.show_data?.start_time;
            let _movie_runtime = order?.show_data?.client_program_data?.runtime;
            let _payment_status = order?.payments[0]?.payment_status;

            let showDateTime = moment(`${_show_date} ${_show_start_time}`, "YYYY-MM-DD HH:mm");

            let showEndTime = showDateTime.add(_movie_runtime, 'minutes');

            log.log({
                _today,
                _show_date,
                _payment_status,
                showEndTime: showEndTime.format(),
                now: moment(today).format(),
                showPassed: moment(today).isAfter(showEndTime),
                paymentStatusPaid: _payment_status === "PAID"
            });

            if (moment(today).isAfter(showEndTime) || _payment_status !== "PAID") {
                setAllowDownload(false);
            } else {
                setAllowDownload(true);
            }
        }
    }, [order])
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    return (
        <div className={`${medium ? "my-4" : "my-3 mx-3"}`}>
            <Card
                className="py-3 px-4 text-white d-flex flex-column"
                style={{
                    // borderRadius: "10px",
                    borderRadius: "10px 10px 0px 0px",
                    // border: "1px solid #9a9a9a"
                    backgroundColor: "#1b1e29"
                }}
            >
                <div className={`${medium ? "row" : ""} w-100`}>
                    <Col sm={3} className={`mb-3 mb-sm-0 ${medium ? '' : 'w-100'}`}>
                        <Image
                            className="cursor-pointer"
                            src={getCorrectImageUrl(order?.show_data?.client_program_data?.poster_image)}
                            style={{ maxHeight: 250, objectFit: "contain", width: "100%" }}
                            rounded
                            onClick={() => allow_image_navigate ? navigate(`${order?.id}`) : null}
                            onError={(e) => e.target.src = movie_placeholder}
                        />
                    </Col>
                    <Col sm={9} className={`p-0 ${medium ? '' : 'w-100'}`}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 style={{ cursor: "pointer" }} onClick={() => allow_image_navigate ? navigate(`${order?.id}`) : null} className="m-0">{order?.show_data?.client_program_data?.title}</h3>
                            {downloading ?
                                <div className="position-relative d-flex justify-content-center align-items-center">
                                    <FaTicketAlt className="position-absolute" size={40} color="#ff000050" />
                                    <Spinner size="sm" />
                                </div>
                                :
                                <div
                                    className="position-relative d-flex justify-content-center align-items-center"
                                    style={{
                                        width: 40
                                    }}
                                    onClick={() => {
                                        if (allowDownload) {
                                            handleShowModal()
                                        }
                                    }}
                                >
                                    <FaTicketAlt className={`position-absolute ${allowDownload ? "cursor-pointer" : ""}`} size={40} color={allowDownload ? "#ff0000" : "#ff000050"} />
                                </div>
                            }
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="m-0 my-1">{order?.theatre_data?.title}</p>
                                <div>
                                    <span>{order?.show_data?.start_time?.substring?.(0, 5)}</span>
                                    <span> | </span>
                                    <span>{moment(order?.show_data?.date).format("DD MMM YYYY")}</span>
                                </div>
                                <div>{`Ticket${order?.seats.length > 1 ? 's' : ''}: ${order?.seats.length}`}</div>
                                <h5>
                                    <span><SeatSvg /></span>
                                    <span> {order?.seats[0]?.seat_type_title} - </span>
                                    <span>
                                        {order?.seats.map((seat, i) => {
                                            return (
                                                <span key={i}>{seat?.seat_label}{i < order?.seats.length - 1 ? ", " : ""}</span>
                                            )
                                        })}
                                    </span>
                                </h5>
                            </div>
                            <div>
                                {allowDownload && <img src={`data:image/png;base64,${order?.seats?.[0]?.qr_code}`} alt="" width={100} />}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>Ticket price</div>
                            <div>MUR. {order?.subtotal}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>Discount</div>
                            <div>- MUR. {order?.discount || `0.00`}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>Tax</div>
                            <div>MUR. {order?.tax || `0.00`}</div>
                        </div>
                        {/* <hr style={{ border: "1px dashed" }} /> */}
                        <hr />
                        <div className="d-flex justify-content-between">
                            <div>TOTAL AMOUNT</div>
                            <h5>MUR. {order?.payments?.sort((p, q) => p.updated_at > q.updated_at ? -1 : 1)?.[0]?.total}</h5>
                        </div>
                    </Col>
                </div>
            </Card>
            <Card
                className={`${medium ? "" : ""} mt-1 py-3 px-4 text-white d-flex flex-column`}
                style={{
                    // borderRadius: "10px",
                    borderRadius: "0px 0px 10px 10px",
                    // border: "1px solid #9a9a9a"
                    backgroundColor: "#1b1e29"
                }}
            >

<div className="d-flex justify-content-between">
    <div className="d-flex flex-column justify-content-start align-items-center" style={{ flex: 1, minWidth: 0, margin: '0 10px' }}>
        <div className="text-grey subtitle text-center text-truncate-hover" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>
            Booking Date & Time
        </div>
        <p className="m-0">{moment(order?.created_at).format("DD MMM YYYY hh:mm a")}</p>
    </div>
    <div className="d-flex flex-column justify-content-start align-items-center" style={{ flex: 1, minWidth: 0, margin: '0 10px' }}>
        <div className="text-grey subtitle text-center text-truncate-hover" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>
            Mode
        </div>
        <p className="m-0">{order?.payments[0]?.payment_method}
            {
                order?.payments[0]?.payment_gateway === "MIPS"
                    ? <span>&nbsp;(MIPS)</span>
                    : order?.payments[0]?.payment_gateway === "MYT"
                        ? <span>&nbsp;(my.t money)</span>
                        : <></>
            }
        </p>
    </div>
    <div className="d-flex flex-column justify-content-start align-items-center" style={{ flex: 1, minWidth: 0, margin: '0 10px' }}>
        <div className="text-grey subtitle text-center text-truncate-hover" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>
            Status
        </div>
        <p className="m-0">{order?.payments[0]?.payment_status}</p>
    </div>
</div>
            </Card>
            <Modal show={showModal} onHide={handleCloseModal} centered
            dialogClassName="d-flex align-items-center h-100"
            contentClassName="bg-dark"
            animation={false}>
                <Modal.Header closeButton={false} className="d-flex justify-content-between border-grey d-flex ">
                    <Modal.Title  className="text-white">Ticket QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {allowDownload && (
                       <Carousel controls indicators={false}>
                       {order.seats.map((seat, index) => (
                           <Carousel.Item key={index}>
                               <img
                                   src={`data:image/png;base64,${seat.qr_code}`}
                                   alt={`QR Code ${index + 1}`}
                                   style={{ width: "auto", height: "200px", margin: "0 auto" }}
                               />
                               <div className="mt-3 text-white">
                                   <h5>{seat.seat_label}</h5>
                               </div>
                           </Carousel.Item>
                       ))}
                   </Carousel>
                    )}
                    
                    <Button
                        className="bg-primary border-primary w-100 custom-button my-3 py-2"
                        type="submit"
                        disabled={downloading}
                        
                        onClick={() =>{
                            setDownloading(true);
                            downloadTicket();
                            handleCloseModal();
                        }}
                    >
                        {downloading ? (
                            <span>
                                <Spinner size="sm" className="me-2" />
                                Downloading ...
                            </span>
                        ) : (
                            <span className="poppins">Download {`Ticket${order?.seats.length > 1 ? 's' : ''}`}</span>
                        )}
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default OrderCard;
