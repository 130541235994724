import React, { useEffect, useRef, useState } from "react";
import { Modal, Row, Col, Button, Image } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router";
import { auto, mytlogo } from "assets";
import { notify } from "Utilities/Notifications/Notify";


const MyTPaymentModal = ({
  showId,
  showPaymentModal,
  setShowPaymentModal,
  paymentTimeLeft,
  setPaymentTimeLeft,
  paymentId,
  paymentInfoLoading,
  mytPaymentInfo,
  unlockSeats,
  myTHeaders,
  setPaymentMethod,
  showPaymentTimeLeft,
  setShowPaymentTimeLeft,
  order
}) => {

  const interval = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    interval.current = setTimeout(() => {
      if (paymentTimeLeft > 0) {
        setPaymentTimeLeft(paymentTimeLeft - 1);
      }
      if (showPaymentModal && paymentTimeLeft === 0) {
        notify("Timeout!", "error")
        unlockSeats(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [paymentTimeLeft, setPaymentTimeLeft, showPaymentModal]);

  useEffect(() => {
    console.log({paymentId, showId, myTHeaders, order})
    if (paymentId && showId && order && myTHeaders) {
      navigate(`/anonymous-order/${paymentId}`, {
        state: {
          showId: showId,
          myTHeaders: myTHeaders,
          order: order
        }
      })
    }
  }, [paymentId, showId, myTHeaders, order, navigate])

  const displayTime = () => {
    const minutes = Math.floor(paymentTimeLeft / 60);
    const remainingSeconds = paymentTimeLeft % 60;
    return `0${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const onCancel = () => {
    unlockSeats(true);
  }

  return (
    <Modal
      dialogClassName="d-flex align-items-center h-100"
      contentClassName="bg-slate border-0"
      show={showPaymentModal}
      size="md"
      animation={false}
      style={{
        border: "none"
      }}
    >
      <Modal.Header closeButton={false} style={{
        border: "none"
      }} className="d-flex justify-content-center align-items-center position-relative">
        <Modal.Title className="text-white">
          Pay using my.t money
        </Modal.Title>
        <div
          onClick={onCancel}
          className="text-white"
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translate(0%, -50%)"
          }}
        >
          <RxCross2 className="cursor-pointer" size={20} />
        </div>
      </Modal.Header>
      <h5 className="text-white pt-3 d-flex justify-content-center">
        <b className="text-primary">Time Left :&nbsp;</b>
        <b>{showPaymentTimeLeft ? displayTime() : "00:00"}</b>
      </h5>
      <p className="text-primary text-center">Do not close or reload this window!</p>
      <Row className="justify-content-center" style={{ height: 200 }}>
        <Col sm={12} className="d-flex justify-content-center align-items-center gap-3">

          <Image
            style={{
              width: 80,
              maxHeight: 80,
              objectFit: "contain"
            }}
            src={mytlogo}
            id="payBtn"
            className="cursor-pointer"

            onClick={() => {
              navigate(`/anonymous-order/${paymentId}`, {
                state: {
                  showId: showId,
                  myTHeaders: myTHeaders,
                  order: order
                }
              })
            }}
          />
          {/* <Button
            style={{
              backgroundColor: "red",
              color: "white",
              borderColor: "red"
            }}
            variant="primary"
            size="sm"
            id="reloadPage"
          >
            Reload Page
          </Button> */}
        </Col>
      </Row>
    </Modal>
  );
};

export default MyTPaymentModal;
