import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";

const ResetPassword = ({ form, isSubmitting, handleResetPassword }) => {

  return (
    <Form
      className="text-start w-100"
      ref={form}
      onSubmit={(e) => handleResetPassword(e)}
    >
      <Form.Group className="mb-3" controlId="resetPassForm.otp">
        <Form.Label className="text-white">OTP</Form.Label>
        <Form.Control
          type="text"
          name="otp"
          placeholder="OTP"
          className="bg-extradark py-2 border-grey text-lightgrey"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="resetPassForm.password1">
        <Form.Label className="text-white">Enter Password</Form.Label>
        <Form.Control
          type="password"
          name="password1"
          placeholder="Password"
          className="bg-extradark py-2 border-grey text-lightgrey"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="resetPassForm.password2">
        <Form.Label className="text-white">Confirm Password</Form.Label>
        <Form.Control
          type="password"
          name="password2"
          placeholder="Password"
          className="bg-extradark py-2 border-grey text-lightgrey"
        />
      </Form.Group>
      <Button
        className="bg-primary border-primary w-100 custom-button mt-2 mb-3 py-2"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <span>
            <Spinner size="sm" className="me-2" />
            Processing ...
          </span>
        ) : (
          <span className="poppins">Reset Password</span>
        )}
      </Button>
    </Form>
  );
};

export default ResetPassword;
