import React, { forwardRef, useRef, useState } from "react";
import moment from "moment";
import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateSelection = ({ selectedDate, setSelectedDate }) => {

  const [customDate, setCustomDate] = useState(false)
  const today = new Date();

  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div className="me-2 me-md-3">
      <div
        onClick={() => {
          setCustomDate(true)
          onClick()
        }}
        ref={ref}
        className={`d-flex flex-column justify-content-center align-items-center cursor-pointer rounded ${customDate ? "bg-primary text-white fw-bold" : "bg-dark text-feather"}`}
        style={{
          width: "60px",
          height: "80px",
        }}
      >
        <FaCalendar size={20} />
        <span className="mt-1 fs-12">{moment(selectedDate).format("D MMM")}</span>
      </div>
    </div>
  ))

  return (
    <div className="d-flex overflow-scroll scrollbar-none">
      {[...Array(7)].map((_, i) => {
        const currentDate = moment().add(i, "days");
        const currentDayOfWeek = currentDate.format("ddd");
        const isSelected =
          currentDate.format("YYYY-MM-DD") === (selectedDate || "");

        return (
          <div key={i} className="me-2 me-md-3">
            <div
              onClick={() => {
                setSelectedDate(currentDate.format("YYYY-MM-DD"))
                setCustomDate(false)
              }}
              className={`d-flex flex-column justify-content-center align-items-center cursor-pointer p-3 rounded ${isSelected ? "bg-primary" : "bg-dark"}`}
              style={{
                width: "60px",
                height: "80px",
              }}
            >
              <p
                className={`mb-0 ${isSelected ? "text-white fw-bold" : "text-feather"
                  }`}
              >
                {currentDate.format("D")}
              </p>
              <div style={{ height: "8px" }} />
              <p
                className={`mb-0 ${isSelected ? "text-white fw-bold" : "text-feather"
                  }`}
              >
                {currentDayOfWeek}
              </p>
            </div>
          </div>
        );
      })}
      <div style={{ zIndex: 9 }}>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(moment(date).format("YYYY-MM-DD"))}
          customInput={<CustomDatePicker />}
          minDate={today}
        />
      </div>
    </div>
  );
};

export default DateSelection;
