import { notify } from "Utilities/Notifications/Notify";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as authActions from "Redux/Actions/authActions";
import log from "loglevel";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from "axios";
import { onInputTextShouldBeLowerCase } from "utils";

const RECAPTCHA_SITE_KEY_STATUS = {
  PRESENT: "P",
  ABSENT: "A",
  NOT_LOADED: "NL"
}

const ContactForm = () => {
  const form = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [reCaptchaSiteKey, setRecaptchaSiteKey] = useState(null);
  const [reCaptchaSiteKeyStatus, setRecaptchaSiteKeyStatus] = useState(RECAPTCHA_SITE_KEY_STATUS.NOT_LOADED);
  const [captchaState, setCaptchaState] = useState(null)
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const loadReCaptchaSiteKey = async () => {
      try {
        const response = await axios.post("/user/rest/settings/recaptcha/", { clientId: process.env.REACT_APP_CLIENT_ID });
        log.log(response.data, response.data.recaptcha_site_key)
        if (response?.data?.present) {
          setRecaptchaSiteKey(response.data.recaptcha_site_key);
          setRecaptchaSiteKeyStatus(RECAPTCHA_SITE_KEY_STATUS.PRESENT);
        } else {
          setRecaptchaSiteKey(null);
          setRecaptchaSiteKeyStatus(RECAPTCHA_SITE_KEY_STATUS.ABSENT);
        }
      } catch (err) {
        log.error(err);
      }
    }
    loadReCaptchaSiteKey();
  }, []);

  const handleSendMessge = async (e) => {
    e.preventDefault();

    if (reCaptchaSiteKeyStatus === RECAPTCHA_SITE_KEY_STATUS.NOT_LOADED) {
      return;
    }

    if (reCaptchaSiteKeyStatus === RECAPTCHA_SITE_KEY_STATUS.PRESENT) {
      if (!captchaState?.captcha) {
        notify("Please verify the captcha");
        return;
      }
    }

    setIsSending(true);
    const formData = new FormData(form.current);
    log.log(formData);

    for (const value of formData.values()) {
      if (value === "" || value === null) {
        notify("Please enter all the values");
        setIsSending(false);
        return false;
      }
    }
    formData.append('clientId', process.env.REACT_APP_CLIENT_ID);

    if (captchaState?.present) {
      formData.append('g-recaptcha-response', captchaState['g-recaptcha-response']);
    }

    const response = await authActions.addContact(formData);
    if (response.status === 201) {
      form.current.reset();
      notify(
        "Thank you for contacting us. We'll connect with you soon!",
        "success"
      );
    } else {
      notify("There was some problem submitting the form!", "error");
    }
    setIsSending(false);

    recaptchaRef.current.reset()
  };

  const handleCaptcha = (key) => {
    setCaptchaState({
      captcha: true,
      'g-recaptcha-response': key
    })
  }

  return (
    <div className="bg-dark p-3" style={{ borderRadius: 10 }}>
      <Form ref={form} onSubmit={(e) => handleSendMessge(e)}>
        <Form.Group className="mb-3" controlId="contactForm.name">
          <Form.Label className="text-feather">Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="John Doe"
            className="bg-slate text-lightgrey border-grey"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="contactForm.email">
          <Form.Label className="text-feather">Email address</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="name@example.com"
            className="bg-slate text-lightgrey border-grey"
            onInput={onInputTextShouldBeLowerCase}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="contactForm.mobile">
          <Form.Label className="text-feather">Mobile</Form.Label>
          <Form.Control
            type="mobile"
            name="mobile"
            minLength={10}
            maxLength={10}
            placeholder="9988989898"
            className="bg-slate text-lightgrey border-grey"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="contactForm.message">
          <Form.Label className="text-feather">Message</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            rows={4}
            className="bg-slate text-lightgrey border-grey"
          />
        </Form.Group>
        {
          reCaptchaSiteKey ? <div className="mb-3"><ReCAPTCHA
            onChange={handleCaptcha}
            ref={recaptchaRef}
            //This ref can be used to call captcha related functions in case you need.
            sitekey={reCaptchaSiteKey}
            theme="dark"
          /></div> : <></>
        }
        <Button
          className="bg-primary text-feather border border-darkgrey text-uppercase w-100 py-2 mt-1"
          type="submit"
          disabled={isSending}
        >
          {
            isSending ? "Sending ... " : "Send Message"
          }
        </Button>
      </Form>
    </div>
  );
};

export default ContactForm;
