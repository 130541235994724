import React from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { movie_placeholder } from "assets";

const ShowDetailsVertical = ({
  title,
  image,
  language,
  theatre,
  showDate,
  startTime,
  formatType,
  screen,
}) => {

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <div className={`${large ? "" : "d-flex flex-row justify-content-start"}`}>
      <img
        src={image}
        alt="movie poster"
        className={`${large ? "w-75" : "w-25"} img-fluid mb-4 mb-md-0 mt-3 mt-md-0 rounded`}
        onError={e => e.target.src = movie_placeholder}
      />
      <div className={`${large ? "" : "ms-3 d-flex flex-column justify-content-center"}`}>
        <div className="mb-2">
          <h4 className={`${large ? "mt-2" : "my-0"} text-white`}>{title}</h4>
          <Badge bg="white text-black p-2 me-2 mt-2">
            {formatType}
          </Badge>
          <Badge bg="white text-black p-2 mt-2">
            {language?.title}
          </Badge>
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center text-feather mb-2">
          {theatre} Theatre
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center text-feather mb-2">
          Screen : {screen}
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center text-feather mb-2">
          {moment(showDate).format('ddd')} {moment(showDate).format("DD MMM")}, {moment(startTime, "HH:mm:ss").format('hh:mm a')}
        </div>
      </div>
    </div>
  );
};

export default ShowDetailsVertical;