import React from "react";
import PageTitle from "website/components/Common/PageTitle";
import { Helmet } from "react-helmet";

const BookingPolicy = () => {
  
    return (
    <><Helmet>
      <title>MCine | Booking Policy</title>
    </Helmet>
      <div>
        <div className="bg-slate pb-5" style={{ minHeight: "35vh" }}>
          <div className="wrapper">
            <ul className="breadcrumb subtitle mx-1 pb-4">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Booking Policy</li>
            </ul>
            <h4 className="text-primary mb-4 text-justify">Our Commitment</h4>
          <p className="text-white text-justify" style={{ whiteSpace: "pre-line" }}>
            At MCINE MONTIDA LTD, we are committed to ensuring a smooth and enjoyable experience for our customers. We strive to provide clear and comprehensive information about our booking policies to help you make informed decisions when purchasing tickets and reservations through our website.
          </p>
          <h4 className="text-primary mb-4 text-justify">No Refunds, No Exchanges</h4>
          <p className="text-white text-justify">
            <strong>All Sales Final:</strong> Once a booking is confirmed, it is considered final. No refunds or exchanges will be provided for any reason.
            <br />
            <strong>Non-Transferable:</strong> Tickets and reservations are non-transferable. Changes to the booking name, date, or time are not permitted.
            <br />
            <strong>Customer Responsibility:</strong> It is the responsibility of the customer to ensure the accuracy of their booking details before finalizing the purchase.
            <br />
            <strong>Cancellation & Changes:</strong> In case of changes or cancellations by the event organizer, our no refund, no exchange policy will still apply, unless otherwise stated by the event organizer. However, in the event of a cancellation, we will ensure proper solutions are provided to the affected users.
            <br />
            <strong>Customer Agreement:</strong> By completing a booking, customers acknowledge and agree to this policy.
          </p>
          <h4 className="text-primary mb-4 text-justify">Detailed Booking Terms and Conditions</h4>
          <p className="text-white text-justify">
            <strong>1. Finality of Sales</strong>
            <br />
            All bookings are final upon confirmation. Customers are advised to review their booking details carefully before completing the purchase, as no refunds or exchanges will be granted under any circumstances.
          </p>
          <p className="text-white text-justify">
            <strong>2. Non-Transferability</strong>
            <br />
            Tickets and reservations are strictly non-transferable. This means that the booking cannot be altered, assigned, or transferred to another individual, and no modifications to the booking name, date, or time will be accepted.
          </p>
          <p className="text-white text-justify">
            <strong>3. Accuracy of Booking Information</strong>
            <br />
            Customers are solely responsible for ensuring that all booking details are correct before finalizing their purchase. This includes, but is not limited to, the event date, time, and customer information. MCINE MONTIDA LTD will not be held liable for any errors made by the customer during the booking process.
          </p>
          <p className="text-white text-justify">
            <strong>4. Customer-Initiated Cancellations</strong>
            <br />
            In the event that a customer decides to cancel their booking, no refunds, credits, or exchanges will be provided. This policy applies to all bookings, regardless of the reason for cancellation.
          </p>
          <p className="text-white text-justify">
            <strong>5. Changes and Cancellations by Event Organizer</strong>
            <br />
            In the event that the organizer changes or cancels an event, our no refund, no exchange policy will still apply, unless the event organizer explicitly states otherwise. Any exceptions to this policy will be communicated directly to the customers affected by such changes. However, we will ensure proper solutions are provided to the affected users to the best of our ability.
          </p>
          <p className="text-white text-justify">
            <strong>6. Customer Agreement</strong>
            <br />
            By completing a booking, customers explicitly acknowledge and agree to the terms outlined in this Booking Policy. This agreement is legally binding and enforceable.
          </p>
          <h4 className="text-primary mb-4 text-justify">Contact Information</h4>
          <p className="text-white text-justify">
            For any questions or concerns regarding this Booking Policy, please contact us via email at contact@mcine.mu or phone at +230 464 1900.
          </p>
          <p className="text-white text-justify">
            This Booking Policy ensures clarity and fairness in our transaction process, in compliance with Mauritian law and standard industry practices.
          </p>
          </div>
        </div>
      </div>
      </>
    );

};

export default BookingPolicy;
