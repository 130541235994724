import { Slide, toast } from "react-toastify";

const toastConf = {
  position: "top-center",
  autoClose: 3000,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  className: "my-toast",
  theme: "dark",
  transition: Slide,
};

const ToastComponent = ({ heading, description }) => {

  return (
    <>
      <div className="d-flex flex-column gap-2">
        {heading && <h6>{heading}</h6>}
        {description && <small>{description}</small>}
      </div>
    </>
  );
}

export const notify = (msg, type, toastId) => {

  let heading = null;
  let description = null;

  if (typeof msg === "string") {
    heading = msg;
  }

  if (typeof msg === "object") {
    heading = msg.heading;
    description = msg.description;
  }

  switch (type) {
    case "success":
      toastConf['toastId'] = toastId;
      toast.success(<ToastComponent heading={heading} description={description} />, toastConf);
      break;
    case "error":
      toastConf['toastId'] = toastId;
      toast.error(<ToastComponent heading={heading} description={description} />, toastConf);
      break;
    case "warning":
      toastConf['toastId'] = toastId;
      toast.warning(<ToastComponent heading={heading} description={description} />, toastConf);
      break;
    case "info":
      toastConf['toastId'] = toastId;
      toast.info(<ToastComponent heading={heading} description={description} />, toastConf);
      break;
    default:
      toastConf['toastId'] = toastId;
      toast.error(<ToastComponent heading={heading} description={description} />, toastConf);
  }
  // alert();
};
