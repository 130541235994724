import React from 'react';
import { Helmet } from 'react-helmet';

const MyTMoneyHelp = () => {
    return (
        <>
            <Helmet>
                <title>MCine | my.t money Help</title>
            </Helmet>
            <div>
                <div className="bg-slate pb-5" style={{ minHeight: "35vh" }}>
                    <div className="wrapper text-white py-5 px-4">
                        <ul className="breadcrumb subtitle mx-1 pb-4 d-flex align-items-center">
                            <li className="breadcrumb-item">
                                <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item active text-white d-flex align-items-center">my.t money Help</li>
                        </ul>

                        <h2 className="text-white text-center mb-5">my.t money</h2>

                        <h1 className="mb-4">Booking Instructions</h1>
                        <ol className="pl-3">
                            <li className="mb-4">
                                <strong>Selecting Your Preferences:</strong>
                                <p>Choose your preferred theatre, date, movie, time, and seats on the <a href="https://mcine.mu" rel="noreferrer" target="_blank">mcine.mu</a> website.</p>
                            </li>
                            <li className="mb-4">
                                <strong>Payment Options:</strong>
                                <div className="device-specific pl-3">
                                    <p><strong>For Computer or Large Screen Devices:</strong></p>
                                    <ul>
                                        <li className="mb-2">Select "Pay with my.t money" as your payment method.</li>
                                        <li className="mb-2">Use the my.t money QR code displayed on the screen.</li>
                                        <li className="mb-2">The QR code is compatible with any QR-based payment system in Mauritius, such as my.t money, Juice, Blink, etc.</li>
                                    </ul>
                                    <p className="mt-4"><strong>For Smaller Screen Devices (e.g., Smartphones, Tablets):</strong></p>
                                    <ul>
                                        <li className="mb-2">Ensure the my.t money app is installed on your device.</li>
                                        <li className="mb-2">Select "Pay with my.t money" as your payment method.</li>
                                        <li className="mb-2">Follow the prompts on the my.t money app to complete the payment without needing to scan a QR code.</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="mb-4">
                                <strong>Using the my.t money App Directly:</strong>
                                <ul className="pl-3">
                                    <li className="mb-2">Open the my.t money App.</li>
                                    <li className="mb-2">Select your preferred theatre, date, movie, time, and seats.</li>
                                    <li className="mb-2">Choose "Pay with my.t money" to complete the payment directly through the app.</li>
                                </ul>
                            </li>
                        </ol>
                        <p>By following these instructions, you can seamlessly book your tickets and pay using the my.t money service on <a href="https://mcine.mu" target="_blank" rel='noreferrer'>mcine.mu</a>.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyTMoneyHelp;
