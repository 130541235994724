import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { FaStar } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";

const MovieDetailsShowcase = ({
  title,
  image,
  genres,
  runtime,
  releaseDate,
  rating,
  showRating,
  certification,
  showCertification,
  showBookBtn = false,
  onClickBook,
  setOpenModal,
  nextShow,
  languages
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [genresString, setGenresString] = useState("");
  const { movieId } = useParams();

  useEffect(() => {
    let genres_string = "";
    let end = null
    if (genres) {
      for (let i = 0; i < genres?.slice(0, 4).length; i++) {
        end = genres?.slice(0, 4).length === i + 1 ? "" : ", "
        genres_string = genres_string + genres[i].name + end
      }
    }
    setGenresString(genres_string)
  }, [genres])

  return (
    <div className="wrapper">
      <Card
        className="p-4"
        style={{
          // backgroundColor: "#13151f",
          backgroundColor: "#1C1E29",
          // boxShadow: "0px 0px 20px 5px #000000",
          borderRadius: "0px"
        }}
      >
        {/* <div className=""> */}
        <Row className="p-4">
          <Col md={2} className="px-0">
            <img
              src={image}
              alt=""
              className="img-fluid mb-4 mb-md-0 w-100 mt-3 mt-md-0 rounded"
            />
          </Col>
          <Col md={9} className="px-0 ps-md-4">
            <div>
              <h4 className="text-white">{title}</h4>
              {showRating && <p className="d-flex align-items-center text-white">
                <span><FaStar color="#FFD700" /></span>
                <span className="mt-1 ms-2">{Number.isInteger(rating) ? rating : rating.toFixed(1)}/10</span>
              </p>}
              {genres && (
                <div className="d-flex mb-2 text-white">
                  {/* {genres.map((item, i) => (
                    <p key={item.id} className="text-feather me-2">{`${item.name}${genres.length === i + 1 ? "" : ", "
                      }`}</p>
                  ))} */}
                  {genresString}
                </div>
              )}
              <div className="mb-2">
                {/* {["Hindi", "English"].map((lang, i) => (
                  <Badge key={i} bg="white text-black me-2 mb-2 p-2">
                    {lang}
                  </Badge>
                ))} */}
                {
                  Array.isArray(languages) && languages?.map?.((lang, i) => (
                    <Badge key={i} bg="white text-black me-2 mb-2 p-2">
                      {lang}
                    </Badge>
                  ))
                }
              </div>
              <div className="d-flex flex-row align-items-start mb-2">
                {releaseDate && (
                  <p className="text-feather me-2">{moment(releaseDate).format("DD MMM, YYYY")}</p>
                )}
                {runtime && (
                  <p className="text-feather">• {runtime} m</p>
                )}
                {showCertification && (
                  <p className="text-feather ms-2">• {certification}</p>
                )}
              </div>
              {showBookBtn && (
                <Row className="px-3">
                  <Button
                    className="bg-primary primary-button border border-primary px-4 me-3 mb-3"
                    style={{ width: medium ? 160 : "100%" }}
                    onClick={onClickBook}
                  >
                    Book Now
                  </Button>
                  <Button
                    className="bg-slate primary-button border border-lightgrey px-4 mb-3"
                    style={{ width: medium ? 160 : "100%" }}
                    onClick={() => setOpenModal(true)}
                  >
                    Watch Trailer
                  </Button>
                </Row>
              )}
              {(nextShow && Object.keys(nextShow).length > 0) ? (
                <Row className="">
                  <ShowLink movieId={movieId} nextShow={nextShow} />
                </Row>
              ) : <></>
              }
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </Card>
    </div>
  );
};

const ShowLink = ({ movieId, nextShow }) => {
  // Create an array of details, excluding undefined or empty values
  const details = [
    nextShow?.date ? moment(nextShow.date).format("MMM DD, YYYY") : null,
    nextShow?.date && nextShow?.start_time ? moment(`${nextShow.date} ${nextShow.start_time}`).format("hh:mm A") : null,
    nextShow?.location || null,
    nextShow?.screen || null,
    nextShow?.language || null,
    nextShow?.type || null,
  ];

  // Filter out null or empty values
  const filteredDetails = details.filter(detail => detail);

  return (
    <div className="text-white d-flex flex-column flex-lg-row gap-2">
      <p className="mb-0" style={{ minWidth: 125 }}>Earliest Show:</p>
      <p className="mb-0">
        <Link
          //target="_blank"
          to={`/show/${movieId}/seats/${nextShow?.id}/`}
          style={{ textDecoration: "none", color: "white" }}
        >
          {filteredDetails.join(" | ")}
        </Link>
      </p>
    </div>
  );
};

export default MovieDetailsShowcase;
