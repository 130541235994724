import React, { useEffect, useState } from 'react'
import { displayTime } from './utils';
import ShowDetailsVertical from "website/components/Movie/ShowDetailsVertical";
import {
    Badge,
    Button,
    Card,
    Col,
    Form,
    InputGroup,
    Row,
    Spinner,
} from "react-bootstrap";
import {
    IoIosArrowBack,
    IoIosCloseCircleOutline,
} from "react-icons/io";
import FoodCard from "../FoodCard";
import SeatSvg20 from "./SeatSvg20";
import MyTPaymentModal from "components/Payment/MyTPaymentModal";
import PaymentModal from "components/Payment/PaymentModal";
import LoginModal from "components/Auth/LoginModal";
import { FaTimes } from "react-icons/fa";

const FoodAndCheckoutMode = ({
    showPaymentTimeLeft,
    timeLeft,
    movieData,
    showData,
    thisTheatre,
    large,
    medium,
    small,
    unlockSeats,
    currentCategory,
    setCurrentCategory,
    categories,
    errorMessage,
    foods,
    orderedFood,
    setOrderedFood,
    maxAllowedFoodQuantity,
    totalFoodQuantity,
    setErrorMessage,
    setTotalFoodQuantity,
    ticketVariety,
    bookedSeats,
    totalPrice,
    ticketCoupon,
    taxes,
    seatCouponTitle,
    setSeatCouponTitle,
    setTicketCoupon,
    setTicketCouponId,
    checkSeatCoupon,
    orderedFoodPrice,
    foodCoupon,
    foodCouponTitle,
    setFoodCouponTitle,
    setFoodCoupon,
    setFoodCouponId,
    checkFoodCoupon,
    totalTicketTaxes,
    totalFoodTaxes,
    isSubmitting,
    setAllowCheckout,
    showLoginModal,
    setShowLoginModal,
    showForm,
    setShowForm,
    email,
    setEmail,
    phone,
    setPhone,
    phoneCode,
    setPhoneCode,
    setNoLoginUserId,
    showPaymentModal,
    myTHeaders,
    showId,
    order,
    setShowPaymentModal,
    paymentTimeLeft,
    setPaymentTimeLeft,
    setShowPaymentTimeLeft,
    paymentId,
    paymentInfoLoading,
    mytPaymentInfo,
    paymentMethod,
    setPaymentMethod,
    fetchMipsPaymentDetails,
    fetchMytPaymentDetails,
    iframeContent,
    convenienceFee
}) => {

    const total_tickets = bookedSeats?.length;
    
    let convenience_fee = 0.0;
    let convenience_fee_text = "0.00";
    if (convenienceFee?.value) {
        console.log({convenienceFee})
        if (convenienceFee?.value && convenienceFee?.type) {
            const type = convenienceFee?.type;
            const value = convenienceFee?.value;
            
            if (type.includes?.("flat")) {
                if (type.includes?.("per_ticket")) {
                    convenience_fee = value * total_tickets;
                } else if (type.includes?.("per_order")) {
                    convenience_fee = value;
                }
                convenience_fee_text = `${convenience_fee?.toFixed?.(2)}`;
            } else if (type?.includes?.("percentage")) {
                convenience_fee = totalPrice * (value/100);
                convenience_fee_text = `${convenience_fee?.toFixed?.(2)}`;
            }
        }
    }
    
    let total_value = totalPrice - ticketCoupon + orderedFoodPrice - foodCoupon + totalTicketTaxes + totalFoodTaxes;
    let total_value_with_convenience_fee = total_value + convenience_fee;
    let checkout_value = Math.ceil(total_value_with_convenience_fee);

    let round_value = 0.00;
    if (checkout_value - total_value_with_convenience_fee >= 0) {
        round_value = checkout_value - total_value_with_convenience_fee
    }

    return (
        <div className="booking-wrapper">
            <h4 className="text-white me-4 d-flex justify-content-end">
                <b className="text-primary">Time Left :&nbsp;</b>
                <b>{showPaymentTimeLeft ? displayTime(timeLeft) : "00:00"}</b>
            </h4>
            <Row className="px-4 mx-0">
                <Col sm={2}>
                    <ShowDetailsVertical
                        title={movieData?.title}
                        image={movieData?.poster_image}
                        language={showData?.language_data}
                        theatre={thisTheatre}
                        showDate={showData?.date}
                        startTime={showData?.start_time}
                        formatType={showData?.format_type_data?.title}
                        screen={showData?.hall_data?.hall_no}
                    />
                </Col>
                <Col sm={7} className={large ? "" : "p-0 m-0"}>
                    {/* <Card
                        className="p-4 mb-4"
                        style={{
                        backgroundColor: "#13151f",
                        boxShadow: "0px 0px 20px 5px #000000",
                        borderRadius: "0px"
                        }}
                    >
                        <div className="text-white d-flex justify-content-center">
                        Advertisements!
                        </div>
                    </Card> */}
                    <Card
                        className="px-lg-4 px-3 py-4 text-white mb-4"
                        style={{
                            backgroundColor: "#1c1e29",
                            borderRadius: "0px",
                        }}
                    >
                        <IoIosArrowBack
                            size={25}
                            className="me-2 cursor-pointer"
                            onClick={() => unlockSeats()}
                        />
                        <div className="d-flex justify-content-center align-item-center">
                            <div className="text-white">Select some quick bites!</div>
                        </div>
                        <div className="text-center my-4">
                            <Badge
                                bg={`${currentCategory === ""
                                    ? "#1c1e29 text-red"
                                    : "danger text-white"
                                    } p-2 m-2 cursor-pointer`}
                                style={{ border: "1px solid #dc3545" }}
                                onClick={() => setCurrentCategory("")}
                            >
                                All
                            </Badge>
                            {categories.map((category, index) => {
                                return (
                                    <Badge
                                        key={index}
                                        bg={`${currentCategory === category.id
                                            ? "#1c1e29 text-red"
                                            : "danger text-white"
                                            } p-2 me-2 mb-2 cursor-pointer`}
                                        style={{ border: "1px solid #dc3545" }}
                                        onClick={() => setCurrentCategory(category.id)}
                                    >
                                        {category.title}
                                    </Badge>
                                );
                            })}
                        </div>
                        <div
                            className={`text-center ${errorMessage ? "mb-4" : ""}`}
                            style={{ color: "red" }}
                        >
                            {errorMessage}
                        </div>
                        <div>
                            <Row className="row-cols-2">
                                {foods.map((food, index) => {
                                    return (
                                        <FoodCard
                                            key={index}
                                            food={food}
                                            orderedFood={orderedFood}
                                            setOrderedFood={setOrderedFood}
                                            maxAllowedFoodQuantity={maxAllowedFoodQuantity}
                                            totalFoodQuantity={totalFoodQuantity}
                                            setTotalFoodQuantity={setTotalFoodQuantity}
                                            errorMessage={errorMessage}
                                            setErrorMessage={setErrorMessage}
                                        />
                                    );
                                })}
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col sm={3} className={large ? "" : "p-0 m-0"}>
                    <Card
                        className="p-4"
                        style={{
                            backgroundColor: "#1c1e29",
                            borderRadius: "0px",
                        }}
                    >
                        <div className="text-white mb-1">
                            <span>Booking Summary</span>
                            <hr />
                        </div>
                        <div className="mb-3 text-white">
                            {ticketVariety?.map((variety, ind) => {
                                return (
                                    <div key={ind}>
                                        <SeatSvg20 color={variety.color} />
                                        <span className="ms-2">&nbsp;{variety.type} </span>
                                        <span className="">
                                            {" "}
                                            -&nbsp;
                                            {bookedSeats
                                                ?.filter(
                                                    (bookedSeat) =>
                                                        bookedSeat.seatType_data.title === variety.type
                                                )
                                                .map((item, index) => {
                                                    return (
                                                        <span key={index}>
                                                            {item?.display_label}
                                                            {index ===
                                                                bookedSeats?.filter(
                                                                    (bookedSeat) =>
                                                                        bookedSeat.seatType_data.title ===
                                                                        variety.type
                                                                ).length -
                                                                1
                                                                ? ""
                                                                : ", "}
                                                        </span>
                                                    );
                                                })}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="text-white d-flex justify-content-between">
                            <small className="text-start">
                                {bookedSeats.length} {bookedSeats.length === 1 ? "Ticket" : "Tickets"}
                            </small>
                            <small className="text-end">
                                MUR &nbsp;{totalPrice.toFixed(2)}
                            </small>
                        </div>
                        {ticketCoupon > 0 && (
                            <div className="text-white d-flex justify-content-between">
                                <small className="text-start">Coupon</small>
                                <small className="text-end">
                                    - MUR &nbsp;{ticketCoupon.toFixed(2)}
                                </small>
                            </div>
                        )}
                        {taxes.filter((t) => t.type === "TICKET").length > 0 &&
                            taxes
                                .filter((t) => t.type === "TICKET")
                                .map((tax, ind) => {
                                    return (
                                        <div key={ind} className="text-white d-flex justify-content-between">
                                            <small className="text-start">
                                                {tax.title}({tax.value}%)
                                            </small>
                                            <small className="text-end">
                                                MUR &nbsp;
                                                {(
                                                    ((totalPrice.toFixed(2) -
                                                        ticketCoupon.toFixed(2)) *
                                                        tax.value) /
                                                    100
                                                ).toFixed(2)}
                                            </small>
                                        </div>
                                    );
                                })}
                        <Form className="d-flex my-3">
                            <InputGroup className="me-3">
                                <Form.Control
                                    type="text"
                                    name="coupon"
                                    placeholder="COUPON"
                                    className="border-grey text-grey"
                                    value={seatCouponTitle}
                                    style={{
                                        backgroundColor: "#1c1e29",
                                        borderRadius: "5px 0px 0px 5px",
                                        borderRight: "none",
                                        borderColor: "#dee2e6",
                                    }}
                                    onChange={(e) => {
                                        setSeatCouponTitle(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="outline-secondary cursor-pointer"
                                    disabled={ticketCoupon <= 0}
                                    onClick={() => {
                                        setSeatCouponTitle("");
                                        setTicketCoupon(0);
                                        setTicketCouponId(null);
                                    }}
                                    style={{
                                        borderColor: "#dee2e6",
                                        borderLeft: "none",
                                    }}
                                >
                                    <FaTimes color="white" />
                                </Button>
                            </InputGroup>
                            <Button
                                className={`${ticketCoupon > 0
                                    ? "bg-success border-success"
                                    : "bg-primary border-primary"
                                    } px-3 py-1`}
                                disabled={ticketCoupon > 0}
                                type="submit"
                                onClick={(e) => checkSeatCoupon(e)}
                            >
                                {ticketCoupon > 0 ? "APPLIED!" : "APPLY"}
                            </Button>
                        </Form>
                        <hr className="text-white" />
                        <div className="text-white d-flex justify-content-between">
                            <small className="text-start">Food & Beverage</small>
                            <small className="text-end">
                                MUR &nbsp;{orderedFoodPrice.toFixed(2)}
                            </small>
                        </div>
                        {orderedFood.map((ordFood, ind) => {
                            return (
                                <div key={ind} className="text-white d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setTotalFoodQuantity(
                                                    totalFoodQuantity -
                                                    orderedFood.filter(
                                                        (item) => item.food === ordFood.food
                                                    )[0].quantity
                                                );
                                                setOrderedFood(
                                                    orderedFood.filter(
                                                        (item) => item.food !== ordFood.food
                                                    )
                                                );
                                            }}
                                        >
                                            <IoIosCloseCircleOutline size={20} />
                                        </div>
                                        <span className="ms-1">
                                            {ordFood.title}(Qt. {ordFood.quantity})
                                        </span>
                                    </div>
                                    <div>
                                        MUR &nbsp;
                                        {(ordFood.quantity * ordFood.price).toFixed(2)}
                                    </div>
                                </div>
                            );
                        })}
                        {foodCoupon > 0 && (
                            <div className="text-white d-flex justify-content-between">
                                <small className="text-start">Food Coupon</small>
                                <small className="text-end">
                                    - MUR &nbsp;{foodCoupon.toFixed(2)}
                                </small>
                            </div>
                        )}
                        {taxes.filter((t) => t.type === "FOOD").length > 0 &&
                            orderedFoodPrice > 0 &&
                            taxes
                                .filter((t) => t.type === "FOOD")
                                .map((tax, ind) => {
                                    return (
                                        <div key={ind} className="text-white d-flex justify-content-between">
                                            <small className="text-start">
                                                {tax.title}({tax.value}%)
                                            </small>
                                            <small className="text-end">
                                                MUR &nbsp;
                                                {(
                                                    ((orderedFoodPrice.toFixed(2) -
                                                        foodCoupon.toFixed(2)) *
                                                        tax.value) /
                                                    100
                                                ).toFixed(2)}
                                            </small>
                                        </div>
                                    );
                                })}
                        <Form className="d-flex my-3">
                            <InputGroup className="me-3">
                                <Form.Control
                                    type="text"
                                    name="coupon"
                                    placeholder="COUPON"
                                    className="border-grey text-grey"
                                    value={foodCouponTitle}
                                    style={{
                                        backgroundColor: "#1c1e29",
                                        borderColor: "#dee2e6",
                                        borderRadius: "5px 0px 0px 5px",
                                        borderRight: "none",
                                    }}
                                    onChange={(e) => {
                                        setFoodCouponTitle(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="outline-secondary cursor-pointer"
                                    style={{
                                        borderLeft: "none",
                                        borderColor: "#dee2e6",
                                    }}
                                    disabled={foodCoupon <= 0}
                                    onClick={() => {
                                        setFoodCouponTitle("");
                                        setFoodCoupon(0);
                                        setFoodCouponId(null);
                                    }}
                                >
                                    <FaTimes color="white" />
                                </Button>
                            </InputGroup>
                            <Button
                                className={`${foodCoupon > 0
                                    ? "bg-success border-success"
                                    : "bg-primary border-primary"
                                    } px-3 py-1`}
                                disabled={foodCoupon > 0}
                                type="submit"
                                onClick={(e) => checkFoodCoupon(e)}
                            >
                                {foodCoupon > 0 ? "APPLIED!" : "APPLY"}
                            </Button>
                        </Form>
                        <div className="text-white d-flex justify-content-between mt-2">
                            <small className="text-start">Total</small>
                            <small className="text-end">
                                MUR{" "}
                                {(total_value).toFixed(2)}
                            </small>
                        </div>
                        <div className="text-white d-flex justify-content-between">
                            <small className="text-start">Convenience Fee</small>
                            <small className="text-end">
                                MUR{" "}
                                {convenience_fee_text}
                            </small>
                        </div>
                        <div className="text-white d-flex justify-content-between">
                            <small className="text-start">Rounding</small>
                            <small className="text-end">MUR {round_value?.toFixed?.(2)}</small>
                        </div>
                        <div className="text-white d-flex justify-content-between my-5">
                            <h5 className="text-start">Amount Payable</h5>
                            <h5 className="text-end">
                                MUR{" "}
                                {checkout_value.toFixed(2)}
                            </h5>
                        </div>
                        {/* <div className="text-white">{myTHeaders != null ? JSON.stringify(myTHeaders) : ""}</div> */}

                        <div className="d-flex justify-content-center align-items-center">
                            {/* <button id="payBtn">Pay Rs1</button>
                  <button id="reloadPage">Reload Page</button> */}
                            <Button
                                className="w-100 bg-primary primary-button border border-primary px-3"
                                disabled={isSubmitting}
                                onClick={() => {
                                    console.log(`Booked ${bookedSeats.length} seats(s).`)
                                    if (bookedSeats.length > 0) {
                                        setAllowCheckout(true);
                                    }
                                }}
                            >
                                {isSubmitting ? (
                                    <span>
                                        <Spinner size="sm" className="me-2" />
                                        Processing ...
                                    </span>
                                ) : (
                                    <div className="d-flex justify-content-between">
                                        <span>Checkout</span>
                                        <span>
                                            Total :{" "}
                                            {checkout_value.toFixed(2)}
                                        </span>
                                    </div>
                                )}
                            </Button>
                        </div>
                    </Card>
                </Col>
            </Row>
            <LoginModal
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
                showForm={showForm}
                setShowForm={setShowForm}
                showNoLogin={true}
                email={email}
                setEmail={setEmail}
                phone={phone}
                phoneCode={phoneCode}
                setPhone={setPhone}
                setPhoneCode={setPhoneCode}
                checkoutMode={true}
                setAllow={setAllowCheckout}
                setNoLoginUserId={setNoLoginUserId}
            />
            {(myTHeaders?.hasOwnProperty("M-App-Id") && showPaymentModal) ?
                <MyTPaymentModal
                    showId={showId}
                    order={order}
                    showPaymentModal={showPaymentModal}
                    setShowPaymentModal={setShowPaymentModal}
                    paymentTimeLeft={paymentTimeLeft}
                    setPaymentTimeLeft={setPaymentTimeLeft}
                    showPaymentTimeLeft={showPaymentTimeLeft}
                    setShowPaymentTimeLeft={setShowPaymentTimeLeft}
                    paymentId={paymentId}
                    paymentInfoLoading={paymentInfoLoading}
                    mytPaymentInfo={mytPaymentInfo}
                    unlockSeats={unlockSeats}
                    myTHeaders={myTHeaders} />
                : <PaymentModal
                    showPaymentModal={showPaymentModal}
                    setShowPaymentModal={setShowPaymentModal}
                    paymentTimeLeft={paymentTimeLeft}
                    setPaymentTimeLeft={setPaymentTimeLeft}

                    showPaymentTimeLeft={showPaymentTimeLeft}
                    setShowPaymentTimeLeft={setShowPaymentTimeLeft}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    paymentId={paymentId}
                    fetchMipsPaymentDetails={fetchMipsPaymentDetails}
                    fetchMytPaymentDetails={fetchMytPaymentDetails}
                    paymentInfoLoading={paymentInfoLoading}
                    iframeContent={iframeContent}
                    mytPaymentInfo={mytPaymentInfo}
                    unlockSeats={unlockSeats}
                />}
            {/* {true ?
            <MyTPaymentModal showPaymentModal={showPaymentModal}
            setShowPaymentModal={setShowPaymentModal}
            paymentTimeLeft={paymentTimeLeft}
            setPaymentTimeLeft={setPaymentTimeLeft}
            paymentId={paymentId}
            paymentInfoLoading={paymentInfoLoading}
            mytPaymentInfo={mytPaymentInfo}
            unlockSeats={unlockSeats}
            myTHeaders={myTHeaders}/>
            : <PaymentModal
              showPaymentModal={showPaymentModal}
              setShowPaymentModal={setShowPaymentModal}
              paymentTimeLeft={paymentTimeLeft}
              setPaymentTimeLeft={setPaymentTimeLeft}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              paymentId={paymentId}
              fetchMipsPaymentDetails={fetchMipsPaymentDetails}
              fetchMytPaymentDetails={fetchMytPaymentDetails}
              paymentInfoLoading={paymentInfoLoading}
              iframeContent={iframeContent}
              mytPaymentInfo={mytPaymentInfo}
              unlockSeats={unlockSeats}
            />} */}
        </div>
    )
}

export default FoodAndCheckoutMode